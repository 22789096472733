// @flow

const CONFIG = {
	SENDGRIDURL: 'https://api.sendgrid.com/v3/mail/send',
	KEY: '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'
};

export const sendGridEmail = (email, language) => {
	const isSuccess = sendEmail(email, language);
	return isSuccess;
};

export const sendGridPremiumEmail = (email, language) => {
	const isSuccess = sendPremiumEmail(email, language);
	return isSuccess;
};

export const sendGridMarketingEmail = (email, language) => {
	const isSuccess = sendMarketingPremiumEmail(email, language);
	return isSuccess;
};

//Send to new user signup
export const sendEmail = (email, language) => {
	return fetch(CONFIG.SENDGRIDURL, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + CONFIG.KEY
		},
		body: JSON.stringify({
			from: {
				email: 'noreply@mydashtracker.com'
			},
			personalizations: [
				{
					to: [
						{
							email: email
						}
					],
					dynamic_template_data: {
						[`${language}`]: true
					}
				}
			],
			template_id: 'd-0a0e055a8e82429f8cf1e20570824d14'
		})
	})
		.then((response) => {
			return true;
		})
		.catch((error) => {
			return false;
		});
};

//Send to users who purchases the premium
export const sendPremiumEmail = (email, language) => {
	return fetch(CONFIG.SENDGRIDURL, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + CONFIG.KEY
		},
		body: JSON.stringify({
			from: {
				email: 'noreply@mydashtracker.com'
			},
			personalizations: [
				{
					to: [
						{
							email: email
						}
					],
					dynamic_template_data: {
						[`${language}`]: true
					}
				}
			],
			template_id: 'd-2f96f0b65c99435e8906b8d0fb2c199d'
		})
	})
		.then((response) => {
			return true;
		})
		.catch((error) => {
			return false;
		});
};

//Send to users who have not bought but have used the app for a week
export const sendMarketingPremiumEmail = (email, language) => {
	return fetch(CONFIG.SENDGRIDURL, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + CONFIG.KEY
		},
		body: JSON.stringify({
			from: {
				email: 'noreply@mydashtracker.com'
			},
			personalizations: [
				{
					to: [
						{
							email: email
						}
					],
					dynamic_template_data: {
						[`${language}`]: true
					}
				}
			],
			template_id: 'd-295329dd1e694778aad521862600cfc2 '
		})
	})
		.then((response) => {
			return true;
		})
		.catch((error) => {
			return false;
		});
};
