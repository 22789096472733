//@flow
import React from 'react';
import { ScrollView, Linking, View } from 'react-native';
import { Paragraph, Heading, BlockQuote, Divider, Text } from '../common';
import styles from './Styles/Styles';
import { i18n } from '../../constants';

export const Benefits = ({ theme }) => {
	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<View>
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh1')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp1')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh2')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp2')}</Paragraph>
				<Divider />
				<Paragraph>{i18n.t('benefitsp3')}</Paragraph>
				<Divider />
				<Paragraph>{i18n.t('benefitsp4')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh3')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp5')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh4')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp6')}</Paragraph>
				<Divider />
				<Paragraph>{i18n.t('benefitsp7')}</Paragraph>
				<Divider />
				<Paragraph>{i18n.t('benefitsp8')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh5')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp9')}</Paragraph>
				<Divider />
				<Paragraph>{i18n.t('benefitsp10')}</Paragraph>
				<Divider />
				<Paragraph>{i18n.t('benefitsp11')}</Paragraph>
				<BlockQuote
					ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}
					style={{ paddingTop: 0, alignItems: 'center' }}>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/dash-diet/art-20048456'
							)
						}>
						{'\n'}Source
					</Text>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'https://www.nhlbi.nih.gov/health-topics/dash-eating-plan'
							)
						}>
						{'\n'}Source 2
					</Text>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'https://www.webmd.com/hypertension-high-blood-pressure/guide/dash-diet#1'
							)
						}>
						{'\n'}Source 3
					</Text>
				</BlockQuote>
			</View>
		</ScrollView>
	);
};
