//@flow
import React, { useState } from 'react';
import {
	View,
	FlatList,
	StyleSheet,
	TouchableOpacity,
	Platform
} from 'react-native';
import { Basics, Benefits, SideEffects, Faq, GetStarted, Tips } from './';
import { Fonts, Colors, i18n } from '../../constants';
import { SimpleLineIcons, Ionicons } from '@expo/vector-icons';
import { Text } from '../common';
import { useSelector } from 'react-redux';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

const TOC = [
	{ key: 0, label: i18n.t('close'), iconName: 'ios-close' },
	{ key: 1, label: i18n.t('basics'), iconName: 'ios-pie' },
	{ key: 2, label: i18n.t('benefits'), iconName: 'ios-happy' },
	{ key: 3, label: i18n.t('sideeffects'), iconName: 'ios-warning' },
	{ key: 4, label: i18n.t('faq'), iconName: 'ios-help-circle' },
	{ key: 5, label: i18n.t('getstarted'), iconName: 'ios-walk' },
	{ key: 6, label: i18n.t('tips'), iconName: 'ios-thumbs-up' }
];

export const LearnScreen = (props) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(1);
	const { theme } = useSelector((state) => state.startup);

	const onItemSelect = (index) => {
		if (index === 0) {
			return setMenuVisible(false);
		}
		setSelectedIndex(index);
		setMenuVisible(false);
	};

	const toggleMenu = () => {
		setMenuVisible(!menuVisible);
	};

	const renderCorrectPage = (activePage) => {
		switch (activePage) {
			case 1:
				return <Basics theme={theme} />;
			case 2:
				return <Benefits theme={theme} />;
			case 3:
				return <SideEffects theme={theme} />;
			case 4:
				return <Faq theme={theme} />;
			case 5:
				return <GetStarted theme={theme} />;
			case 6:
				return <Tips theme={theme} />;
			default:
				return <Basics theme={theme} />;
		}
	};

	const createListItem = (item, index) => {
		if (item.key === 0) {
			return (
				<TouchableOpacity
					style={styles.rowClose}
					onPress={() => onItemSelect(item.key)}>
					<Ionicons color={theme.red} name={item.iconName} size={40} />
				</TouchableOpacity>
			);
		}
		return (
			<TouchableOpacity
				style={styles.row}
				onPress={() => onItemSelect(item.key)}>
				<Ionicons
					style={
						selectedIndex !== item.key
							? [styles.iconStyle, { color: theme.darkFont }]
							: [styles.activeIconStyle, { color: theme.themeAccent }]
					}
					name={item.iconName}
					size={18}
				/>
				<Text
					style={
						selectedIndex !== item.key
							? [styles.titleStyle, { color: theme.darkFont }]
							: [styles.activeTitleStyle, { color: theme.themeAccent }]
					}>
					{item.label}
				</Text>
			</TouchableOpacity>
		);
	};

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<TouchableOpacity
				style={[styles.pageSelect, { backgroundColor: theme.base }]}
				onPress={() => toggleMenu()}>
				<Ionicons
					style={[styles.iconStyle, { color: theme.darkFont }]}
					name={TOC[selectedIndex].iconName}
					size={18}
				/>
				<Text style={styles.pageSelectTitle}>{TOC[selectedIndex].label}</Text>
				<SimpleLineIcons
					style={styles.pageSelectIcon}
					name={'arrow-down'}
					size={12}
					color={theme.darkFont}
				/>
			</TouchableOpacity>
			<View style={styles.container}>{renderCorrectPage(selectedIndex)}</View>
			<Modal.ReactNativeModal
				isVisible={menuVisible}
				hasBackdrop={true}
				backdropOpacity={1}
				backdropColor={theme.baseBG}
				coverScreen={false}
				animationInTiming={600}
				animationOutTiming={600}
				animationIn={'slideInDown'}
				animationOut={'slideOutUp'}>
				<FlatList
					data={TOC}
					renderItem={({ item, index }) => createListItem(item, index)}
					keyExtractor={(item) => item.key}
				/>
			</Modal.ReactNativeModal>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	pageSelect: {
		height: 60,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row'
	},
	pageSelectTitle: {
		...Fonts.style.description
	},
	pageSelectIcon: {
		fontSize: 12,
		paddingLeft: 10,
		alignSelf: 'center'
	},
	row: {
		paddingTop: 10,
		paddingBottom: 10,
		borderBottomWidth: 0.25,
		flexDirection: 'row',
		alignItems: 'center'
	},
	rowClose: {
		alignItems: 'center',
		justifyContent: 'center'
	},
	titleStyle: {
		fontSize: 12
	},
	activeTitleStyle: {
		fontWeight: 'bold',
		fontSize: 12
	},
	iconStyle: {
		width: 30
	},
	activeIconStyle: {
		width: 30
	}
});
