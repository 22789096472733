//@flow
export const enStrings = {
	//Sidebar
	appName: 'MyDashDiet Tracker',
	log: 'Log',
	login: 'Login',
	learn: 'Learn',
	foodlist: 'Food List',
	recipes: 'Recipes',
	mealplan: 'Meal Plan',
	otherdiet: 'Other Diets',
	moreapps: 'More Apps',
	macrocalculator: 'Macro Calculator',
	purchase: 'Purchase',
	tracker: 'Tracker',
	progress: 'Progress',
	review: 'Review',
	manage: 'Manage',
	settings: 'Settings',
	preferences: 'Preferences',
	customgoals: 'Custom Goals',
	othertracking: 'Other Tracking',
	social: 'Social',
	fitnessTracker: 'Fitness Trackers',
	shoppingList: 'Shopping List',
	feedback: 'Feedback / Support',

	//Navigation Stuff
	addFood: 'Add Food',
	addIngredient: 'Add Ingredient',
	mealsRecipes: 'Meals / Recipes',
	editLogEntry: 'Edit Log Entry',
	editMeal: 'Edit Meal',
	editFood: 'Edit Food',
	deleteFood: 'Delete Food',
	deleteMeal: 'Delete Meal',
	friends: 'Friends',
	copyMeal: 'Copy Meal',
	mealCopied: 'Meal Copied',
	copyFood: 'Copy Food',
	copyItems: 'Copy Items',
	copyPlus: 'Copy +',
	copyAll: 'Copy All',
	copied: 'Copied',
	pending: 'Pending',
	manageFoods: 'Manage Foods',
	manageMeals: 'Manage Meals',

	//Generic
	macros: 'Macros',
	ingredients: 'Ingredients',
	directions: 'Directions',
	nutrition: 'Nutrition',
	nutrients: 'Nutrients',

	//Actions
	save: 'Save',
	cancel: 'Cancel',
	goback: 'Go Back',
	close: 'Close',
	delete: 'Delete',
	confirmDelete: 'Are you sure you want to delete?',
	saveas: 'Save As',
	add: 'Add',
	confirm: 'Confirm',
	addFavorite: 'Add Favorite',
	removeFavorite: 'Remove Favorite',
	remove: 'Remove',
	removed: 'Removed',
	added: 'Added',
	clear: 'Clear',
	send: 'Send',
	required: 'Required',
	macrosAddedToMeal: 'Macros Added To Meal',
	ingredientUpdated: 'Ingredient Updated',
	addToMeal: 'Add To Meal',
	saveMeal: 'Save Meal',
	addedToMeal: 'Added To Meal',
	mealSaved: 'Meal Saved',
	mealsAdded: 'Meals Added',
	addTo: 'Add To',
	addedToLog: 'Added To Log',
	saveAsFood: 'Save As Food',
	updateEntry: 'Update Entry',
	updateIngredient: 'Update Ingredient',
	otherTrackingUpdated: 'Other Tracking Updated',
	nameEmpty: 'Name Empty',
	pleaseEnterName: 'Please Enter A Name',
	selectDate: 'Select Date',
	date: 'Date',
	foodNotFound: 'Food Not Found',
	deleted: 'Deleted',
	deleteConfirm: 'Are you sure you want to delete?',
	addedToFavorites: 'added to Favorites',
	favoritesCleared: 'Favorites Cleared',
	removedFromFavorites: 'Removed from Favorites',
	savedToFoods: 'Saved To Foods',
	updated: 'Updated',
	update: 'Update',
	multiAdd: 'Multi-Add',

	//Rate Modal
	noThanks: 'No Thanks',
	giveFeedback: 'Give Feedback',
	rateAppName: 'Rate MyDashDiet',
	never: 'Never',
	later: 'Later',
	rate: 'Rate',
	rateDesc:
		"If you enjoy using MyDashDiet, would you mind taking a second to rate it? It won't take more than a minute. Thank you for your support!",
	rateFeed:
		'Would you mind providing us feedback on what you would like to see in our app? Thank you!',

	//Learn
	basics: 'Basics',
	benefits: 'Benefits',
	getstarted: 'Get Started',
	sideeffects: 'Side Effects',
	tips: 'Tips',
	faq: 'FAQ',
	foodList: 'Food List',

	//Learn - Basics
	basicsh1: 'What Is The DASH Diet?',
	basicsp1:
		"DASH stands for Dietary Approaches to Stop Hypertension. The main goal of the diet is to prevent and lower blood pressure, although there are some other beneficial 'side-effects' such as weight loss.",
	basicsp2:
		'According to the US Department of Health and Human Services: The DASH diet is rich in fruits, vegetables, fat-free or low-fat milk and milk products, whole grains, fish, poultry, beans, seeds, and nuts. It also contains less salt and sodium; sweets, added sugars, and sugar-containing beverages; fats; and red meats than the typical American diet. This heart healthy way of eating is also lower in saturated fat, trans fat, and cholesterol and rich in nutrients that are associated with lowering blood pressure—mainly potassium, magnesium, and calcium, protein, and fiber.',
	basicsp3:
		"The DASH eating plan doesn't require any special foods or supplements like a lot of homeopathic diets. It simply calls for a certain number of daily servings from various food groups. The number of servings depends on the number of calories you’re allowed each day. Your calorie level depends on your age and, especially, how active you are. You will also limit your sodium levels to either 2300mg or 1500mg each day. The less salt you eat, the more you may be able to lower your blood pressure.",
	basicsp4:
		'Basically, its eating a diet full of natural healthy foods, and eliminating the processed ones. This has been proven to make you healthier, who would have thought!',

	//Learn - Benefits
	benefitsh1: 'What are the benefits?',
	benefitsp1:
		'The main benefit behind the DASH diet is that it can prevent and control high blood pressure (Hypertension).',
	benefitsh2: 'Lower blood pressure',
	benefitsp2:
		'Two different studies were performed at four academic medical centers in the U.S.; DASH and DASH-Sodium',
	benefitsp3:
		'DASH - Results indicated that elevated blood pressures were reduced by an eating plan that emphasizes fruits, vegetables, and low-fat dairy foods as well as foods high in fiber and low in saturated fat, total fat and cholesterol. ',
	benefitsp4:
		'DASH-Sodium - Participants were divided into 2 diet groups, Dash and a typical American diet. Each diet was also sub-divided into 3 different sodium intakes levels (3300mg, 2400mg, and 1500mg). The results showed that for both diets, blood pressure was lower in the groups that had a lower daily salt intake. However, for each of the sodium levels, blood pressure was significantly lower among those following the DASH diet compared with those following the American diet. The greatest reduction in blood pressure was seen among those who followed the DASH diet with the low salt intake.',
	benefitsh3: 'Cardiovascular Health',
	benefitsp5:
		'The diet has been shown to increase “good” HDL cholesterol and decrease “bad” LDL cholesterol and triglycerides. Basically, DASH is the medical community’s collective accepted definition of a heart-healthy diet.',
	benefitsh4: 'Weight Loss',
	benefitsp6:
		'Even though the diet was not designed for losing weight, it is a beneficial side-effect. A couple studies looked at the effectiveness of the diet for losing extra pounds:',
	benefitsp7:
		'The Archives of Internal Medicine performed a study in 2010, in which 144 overweight or obese adults with high blood pressure were assigned to one of three groups: DASH, DASH + exercise + classes on weight loss, and lastly a control diet where participants maintained their regular eating habits. After four months, those in the DASH + exercise/classes group lost on average 19 pounds – while the other groups either lost a little or gained weight.',
	benefitsp8:
		'The Annals of Internal Medicine performed another study in 2006 where researchers randomly assigned 810 adults with borderline or mild high blood pressure to three groups. The first got generic advice on lifestyle changes to control blood pressure. The second had requirements of staying under 2,300 mg of sodium a day, losing weight, exercising and limiting alcohol. The third copied the second, except participants were also required to follow DASH’s dietary guidelines. After 18 months, the second group lost an average of about 8 pounds while the DASH group lost about 9½ - both significantly more than the first group’s 3 pounds.',
	benefitsh5: 'Diabetes Prevention & Control',
	benefitsp9:
		'A few studies show good results, and the approach is generally viewed as an ideal eating pattern for both. The DASH diet matches dietary advice backed by the American Diabetes Association.',
	benefitsp10:
		"Prevention - Being overweight is one of the biggest risk factors for Type 2 diabetes. Even though the DASH diet wasn't designed for losing weight, it will most likely help you shed some extra pounds and keep it off as long as you stay the course. Metabolic syndrome also increases the chances of developing diabetes and cardiovascular problems. The DASH Diet combined with calorie reduction has  been shown to reduce risk of Metabolic Syndrome.",
	benefitsp11:
		'Control - A study published in 2011 in Diabetes Care found Type 2 diabetics on DASH reduced their levels of A1C (a measure of blood sugar over time) and their fasting blood sugar after eight weeks.',
	sideeffectsh1: 'Surprise! None',
	sideeffectsp1:
		"Unlike some other popular diets that might eliminate necessary foods or have nasty side effects, the DASH diet doesn't have any known negative side effects. The DASH diet is safe for most people. Lowered blood pressure and weight-loss will be your only side-effects, not too bad.",
	sideeffectsh2: 'Special Precautions & Warnings',
	sideeffectsp2:
		'Pregnancy and breast-feeding:More research is needed about the safety of using the DASH diet during pregnancy and breast-feeding. However, the DASH diet is a balanced diet that would not be expected to be harmful during pregnancy or breast-feeding. It is essentially a natural and healthy diet, so no dangers should exist, although consult with your medical doctor first.',
	faqh1: 'Frequently Asked Questions',
	faqh2: 'Is it hard to follow?',
	faqp1:
		'Giving up your favorite processed foods (fatty,sugary,salty) may be hard at first for you, but your taste buds will adapt and you will not crave it after a few weeks. Besides that, DASH does not limit entire food groups, so your chances of maintaining the diet are higher than other diets.',
	faqh3: 'Is it expensive?',
	faqp2:
		'Contrary to what most people think, buying fresh fruits, veggies and whole-grain products are generally cheaper than the processed foods most Americans consume. You don\'t necessarily need to buy "organic", and some studies have shown that is not any better for you than regular produce. Make a habit of checking your local grocery store ads and buy whats on sale, because that is usually the produce that is "in-season".',
	faqh4: 'Do I still need to exercise?',
	faqp3:
		'It is recommended that you always exercise, especially if one of your goals is to lose weight. Start off slowly with something like a 15 minute walk around the neighborhood if you are out of shape. Try to find exercise activities that you enjoy doing, as it will make it less of a chore and more fun.',
	faqh5: "I can't tolerate [specific food], can I still follow DASH?",
	faqp4:
		"Yes! The DASH diet is very flexible for people who can't tolerate certain foods or have restrictions (i.e gluten, dairy, vegetarian). Just make sure to substitute those foods with appropriate alternatives",
	faqh6: 'Is it going to taste bad?',
	faqp5:
		'At first you will miss processed foods, but your taste buds will eventually adjust and those foods will actually taste bad when you go back and try them. Avoid blandness by getting friendly with herbs and spices.',
	faqh7: 'Can I drink alcohol and caffeine',
	faqp6:
		'Drinking too much alcohol can increase blood pressure. The DASH diet recommends that men limit alcohol to two or fewer drinks a day and women one or less.',
	faqp7:
		"The DASH diet doesn't address caffeine consumption. The influence of caffeine on blood pressure remains unclear. But caffeine can cause your blood pressure to rise at least temporarily. If you already have high blood pressure or if you think caffeine is affecting your blood pressure, talk to your doctor about your caffeine consumption.",
	getstartedh1:
		'1) Restock your kitchen & pantry so that there are no  temptations for you.',
	getstartedh2: '2) Calculate out your daily calorie needs.',
	getstartedh3:
		'3) Familiarize yourself with the eating plan. Check food labels for serving sizes. You will also limit your sodium levels to either 2300mg or 1500mg each day.  The less salt you eat, the more you may be able to lower your blood pressure.',
	getstartedh4:
		'4) Plan out what you are going to eat weekly and buy groceries ',
	getstartedh5: '5) Simply eat and exercise!',
	tipsh1: 'Load up on lean poultry and fish in moderation',
	tipsp1: 'They are low-fat (plus good fat), and heart-healthy choices.',
	tipsh2: 'Ditch the saltshaker',
	tipsp2:
		'Season your dishes with herbs, spices or lemon zest instead. Your heart will be happier, but your cooking skills will be "on point".',
	tipsh3: 'Load up on vegetables and whole grains',
	tipsp3:
		'These foods are rich in fiber, calcium, protein and potassium, which have been shown to help fend off or lower high blood pressure.',
	tipsh4: "Fruit = Nature's Sweets",
	tipsp4:
		'Forgo the sugary, high fat processed desserts and instead opt for fruit when you have a sweet tooth craving.',
	tipsh5: 'Frozen Veggies',
	tipsp5:
		"These make it easy to add 2 or more servings of veggies to your meal even if you don't have time to hit the store before dinner.",
	tipsh6: 'Grocery Shopping',
	tipsp6:
		" 1) Make a shopping list. Choose which meals you're going to make during the coming week, and put all these ingredients on your list. Make sure you get breakfast and snacks. Not only will you be less likely to stray from the diet with unhealthy foods, but you will save time and money.",
	tipsp7:
		"2) Eat beforehand or at least don't shop when hungry. This is smart regardless if you follow the DASH diet or not. Everything looks amazing when you are hungry, which makes it hard to resist those high-fat, high-sodium items.",
	tipsp8:
		" 3) Stick to the perimeter of the store. While there are many DASH diet-friendly items in the center aisles, focus on spending most of your shopping time in the areas of the grocery store where there's fresh produce, low-fat dairy products and lean meats.",
	tipsh7: 'Eating Out',
	tipsp9:
		'1) Reduce the salt. Ask that your food be prepared without added salt, MSG or salt-containing ingredient(foods that are pickled, cured or smoked, soy sauce or broth.) Also limit condiments that are high in salt, such as mustard, ketchup, pickles and sauces.',
	tipsp10:
		'2) Watch the fats. Ask that your dish be made with olive oil, instead of butter or vegetable oil. Instead of salad dressing, just get Oil and Vinegar (still tasty), or at the very least get the dressing on the side. Lastly, choose dishes with healthier cooking techniques (i.e. steaming, grilling, broiling, baking, roasting, poaching or stir-frying.)',
	tipsp11:
		"3) Awareness. It's not just the entree that can kill your efforts. Beware of drinks, appetizers, and even soups and salads.",

	//foodlist
	bread: 'Whole Wheat Bread',
	rolls: 'Whole Wheat Rolls',
	pasta: 'Whole Wheat Pasta',
	emuffin: 'English Muffin',
	pita: 'Pita Bread',
	bagel: 'Bagel',
	cereal: 'Whole Grain Cereals',
	grits: 'Grits',
	oatmeal: 'Oatmeal',
	brownrice: 'Brown Rice',
	popcorn: 'Unsalted Popcorn',
	pretzel: 'Unsalted Pretzels',
	corntortilla: 'Corn Tortillas',
	wheattorilla: 'Whole Wheat Tortillas',
	barley: 'Barley',
	bulgur: 'Bulgur',
	buckwheat: 'Buckwheat',
	quinoa: 'Quinoa,',
	spelt: 'Spelt, Triticale, Kamut',
	wildrice: 'Wild Rice',
	asparagus: 'Asparagus',
	artichoke: 'Artichoke',
	beetgreens: 'Beet Greens',
	bellpepper: 'Bell Peppers',
	broccoli: 'Broccoli',
	brusselsprouts: 'Brussel Sprouts',
	cabbage: 'Cabbage',
	carrot: 'Carrots',
	cauliflower: 'Cauliflower',
	celery: 'Celery',
	chard: 'Chard',
	corn: 'Corn',
	cucumber: 'Cucumbers',
	eggplant: 'Eggplant',
	greenbean: 'Green Beans',
	jicama: 'Jicama',
	kale: 'Kale',
	leeks: 'Leeks',
	salad: 'Lettuce & Salad Greens',
	limabeans: 'Lima Beans',
	mushrooms: 'Mushrooms',
	onion: 'Onions',
	peas: 'Peas',
	potatoe: 'Potatoe',
	radish: 'Radishes',
	spinach: 'Spinach',
	squash: 'Squash',
	sweetpotatoe: 'Sweet Potatoe',
	tomatoe: 'Tomatoes',
	apple: 'Apples',
	apricot: 'Apricot',
	banana: 'Banana',
	berries: 'Berries',
	cherry: 'Cherries',
	dates: 'Dates',
	fig: 'Figs',
	grapes: 'Grapes',
	grapefruit: 'Grapefruit',
	kiwi: 'Kiwi',
	lemon: 'Lemons',
	mango: 'Mango',
	melon: 'Melons',
	orange: 'Oranges',
	papaya: 'Papaya',
	peach: 'Peach',
	pear: 'Pears',
	pineapple: 'Pineapples',
	plum: 'Plums',
	prune: 'Prunes',
	raisin: 'Raisins',
	strawberry: 'Strawberries',
	tangerine: 'Tangerines',
	buttermilk: 'Buttermilk (Lowfat)',
	hardcheese: 'Cheese (Hard): reduced-fat cheddar, monterey jack, parmesan',
	softcheese: 'Cheese (Soft): blue, Chevre (goat cheese), feta',
	cottagecheese: 'Cottage Cheese',
	milk: 'Milk (Fat Free / Lowfat)',
	kefir: 'Kefir',
	mozzarella: 'Mozzarella',
	sourcream: 'Sour Cream',
	yogurt: 'Yogurt (Fat Free/ Lowfat)',
	sirloin: 'Beef: flank, round or tip sirloin',
	chickenb: 'Chicken or Turkey (Skinless)',
	delimeat: 'Deli Meat',
	eggs: 'Eggs',
	groundb: 'Lean Ground Beef/Turkey/Chicken',
	wfish: 'Fish fillets',
	pork: 'Pork Tenderloin',
	salmon: 'Salmon',
	shrimp: 'Shrimp',
	almonds: 'Almonds',
	beans: 'Beans (dry)',
	cashews: 'Cashews',
	hazelnuts: 'Hazelnuts',
	lentils: 'Lentils',
	nutbutter: 'Nut Butter',
	peanuts: 'Peanuts',
	pecans: 'Pecans',
	seeds: 'Seeds',
	soynuts: 'Soy Nuts',
	splitpeas: 'Split Peas',
	walnuts: 'Walnuts',
	dressing: 'Light Salad Dressing',
	mayo: 'Mayonnaise (Lowfat)',
	oil: 'Vegetable Oil (Canola, Olive, Safflower)',
	gelatin: 'Gelatin',
	fruitice: 'Fruit Ices',
	candy: 'Hard Candy',
	jelly: 'Jelly',
	syrup: 'Maple Syrup',
	sorbet: 'Sorbet',
	basil: 'Basil',
	cayenne: 'Cayenne',
	cilantro: 'Chili powder',
	coriander: 'Coriander',
	cumin: 'Cumin',
	garlic: 'Fresh garlic',
	garlicpowder: 'Garlic powder',
	onionpowder: 'Onion powder',
	parsley: 'Parsley',
	pepper: 'Pepper',
	rosemary: 'Rosemary',
	salt: 'Sea salt',
	stevia: 'Stevia',
	thyme: 'Thyme',
	vinegar: 'Vinegar',

	//Recipes
	makes: 'Makes',
	servings: 'Servings',
	fat: 'Fat',
	carbs: 'Carbs',
	totalcarb: 'Total Carbs',
	netcarb: 'Net Carbs',
	protein: 'Protein',
	calories: 'Calories',
	fiber: 'Fiber',
	zeroResults: '0 results',
	loadMore: 'Load More',
	likes: 'Likes',
	comments: 'Comments',
	all: 'All',
	dishType: 'Dish Type',
	mealType: 'Meal Time',
	cuisineType: 'Cuisine Type',
	american: 'American',
	asian: 'Asian',
	british: 'British',
	caribbean: 'Caribbean',
	centralEurope: 'Central Europe',
	chinese: 'Chinese',
	easternEurope: 'Eastern Europe',
	french: 'French',
	indian: 'Indian',
	italian: 'Italian',
	japanese: 'Japanese',
	kosher: 'Kosher',
	mediterranean: 'Mediterranean',
	mexican: 'Mexican',
	middleEastern: 'Middle Eastern',
	nordic: 'Nordic',
	southAmerican: 'South American',
	southEastAsian: 'South East Asian',

	//Food List & Fat Seret
	netcarbFinder: 'Food List',
	search: 'Search',
	searchFilter: 'Search Filter',
	barcode: 'Barcode',
	offline: 'Offline',
	results: 'Results',
	barcodeNotRecognized: 'Barcode Not Recognized',
	barcodeNotRecognizedDetail:
		'Try searching the database or adding it as a custom food.',
	clearAllRecentlyScanned: 'Clear All Recently Scanned?',
	clearAllConfirm:
		'This will clear your recently scanned barcodes on all synced devices',
	requestCamera: 'Requesting for camera permission',
	noAccessCamera: 'No access to camera',
	scanBarcode: 'Scan Barcode',
	itemFound: 'Item Found',
	itemNotFoundFS: 'Item Not Found In Fat Secret',
	ItemNotFoundFSdesc:
		'Try searching the database or adding it as a custom food.',
	generic: 'Generic',
	basedDiet: '% based on a 2000 calorie diet',

	//Macros Calculator
	calculatorDetails:
		'We need a few details to form your personalized dash diet plan',
	height: 'Height',
	weight: 'Weight',
	age: 'Age',
	gender: 'Gender',
	male: 'Male',
	female: 'Female',
	exerciseLevel: 'Exercise Level',
	littleToNone: 'Little to None',
	exerciseOne: '1-3 times / week',
	exerciseTwo: '3-5 times / week',
	exerciseThree: '6-7 times / week',
	exerciseFour: '2 times / day',
	goalWeight: 'Goal Weight',
	faster: 'Faster',
	slower: 'Slower',
	weightPlan: 'Your Weight Plan',
	maintain: 'Maintain',
	easier: 'Easier',
	mild: 'Mild',
	moderate: 'Moderate',
	difficult: 'Difficult',
	customize: 'Customize',
	dailyCalories: 'Daily Calories',
	week: 'Week',
	month: 'Month',
	lose: 'Lose',
	by: 'by',
	bodyFat: 'Body Fat',
	bodyFatDetails:
		'Your body fat % will be used to calculate your ideal protein amount based off your total muscle mass.',
	proteinDetail:
		'Too much protein can break ketosis, so you need just enough to maintain muscle and a bit more to build it.',
	dailyProtein: 'Daily Protein',
	idealRange: 'Your ideal range is',
	maintainLower: 'maintain',
	build: 'build',
	maintainMuscle: 'Maintain Muscle',
	buildMuscle: 'Build Muscle',
	netcarbDescription:
		'Eating over 50g of net carbs a day is enough to break ketosis. Most keto dieters eat around 20g-30g. The fewer carbs you eat the less chance you have of breaking ketosis.',

	//Settings and Language
	language: 'Language',
	languageUpdated: 'Language Updated',
	english: 'English',
	french: 'French',
	spanish: 'Spanish',
	portuguese: 'Portuguese',
	german: 'German',
	logout: 'Logout',
	loggedOut: 'Logged Out',
	unitsUpdated: 'Units Updated',
	featureRequest: 'Feature Request',
	deleteAccount: 'Delete Account',
	verifyDeleteMessage:
		'This will delete your account and all data, are you sure?',
	accountDeleted: 'Account Deleted',
	net: 'Net',
	total: 'Total',
	preferredCarb: 'Preferred Carb',
	preferredCarbDesc:
		'Total carbs include fiber and sugar alcohols, while Net carbs only include countable carbs that affect ketosis. Most keto dieters only track Net carbs.',
	updateMacroGoals: 'Update Macro Goals',
	defaultMacroGoalsUpdated: 'Default Macro Goals Updated',
	customGoalsApplied: 'Custom Goals Applied To Dates',
	tracking: 'Tracking',
	recalculateMacroGoals: 'Re-Calculate Macro Goals',
	manuallyChangeMacroGoals: 'Manually Change Macro Goals',
	changePreferences: 'Change Preferences',
	restoreAdFree: 'Restore Ad Free Purchase',
	darkMode: 'Dark Mode',

	//Preferences
	otherTracking: 'Other Tracking',
	other: 'Other',
	notes: 'Notes',

	//Purchase
	invalidCode: 'Invalid Code',
	purchaseRestored: 'Purchase Restored',
	userNotFound: 'User Not Found',
	error: 'Error',
	errorProducts:
		'There was an error trying to load products. Make sure you have a valid internet connection.',
	trackerEnabledAdsRemoved: 'Purchase Restored, Ads Removed',
	purchaseTracking: 'Purchase Tracking',
	gettingProductDetails: 'Getting Product Details ...',
	noPurchaseFound: 'No Purchase Found',
	noPurchaseFoundDesc:
		'No purchase was found on this account. Please email us if you think that is incorrect.',
	premium: 'Premium',
	premiumEnabled: 'Premium Enabled',

	//login
	account: 'Account',
	signIn: 'Sign In',
	logIn: 'Log In',
	signedIn: 'Signed In',
	accountCreated: 'Account Created',
	signUp: 'Sign Up',
	email: 'Email',
	password: 'Password',
	forgotPassword: 'Forgot Password?',
	resetEmailSent: 'Reset Email Sent',
	newUser: 'New User?',
	emailError: 'Enter a valid email',
	passwordError: 'Enter a password',
	emailSubscribe: 'Get updates and special offers from MyDashDiet',
	enterYourEmail: 'Enter Your Email',
	medicalDisclaimer: 'Medical Disclaimer',
	disclaim:
		'Medical Disclaimer: MyDashDiet is offered for educational and entertainment purposes only, and in no way intends to diagnose, cure, or treat any medical or other condition. Always seek the advice of your physician or other qualified health provider prior to changing your diet or exercise plan and ask your physician any questions you may have regarding a medical condition. Although we do our best to verify the accuracy of information contained herein, we cannot guarantee its accuracy.',
	skipAccount: 'Skip Account',
	registerAccount: 'Register Account',
	convertAccount: 'Convert Account to Login',
	createAccountLater:
		'You can create an account later with your data by going to the Settings menu',
	whyLogin: 'Why Login?',
	usingAccount:
		"Using an account will back up and sync your data across all your other devices. It only takes a second to register and we don't share or store any personal details.",

	//Editing Item Modal
	totalcarbError: "Total can't be less than Fiber",
	auto: 'Auto',
	manual: 'Manual',
	name: 'Name',
	editingItem: 'Editing Item',

	//Food Tabs
	foods: 'Foods',
	meals: 'Meals',
	meal: 'Meal',
	favorites: 'Favorites',
	recent: 'Recents',
	recentlyused: 'Recently Used',
	saveToFoods: 'Save To Foods',
	searched: 'Searched',
	myfoods: 'My Foods',
	mymeals: 'My Meals',
	quickmacro: 'Quick Macros',
	create: 'Create',
	created: 'Created',
	createFood: 'Create Food',
	createMeal: 'Create Meal',
	ingredientNotFound: 'Ingredient not found, try to add it again',
	totalServings: 'Total Servings',
	totalServingsDesc: 'Please enter a number for total servings',
	clearAllFavorites: 'Clear All Favorites?',
	clearAllFavoritesDesc:
		'This will clear all of your favorites on all synced devices',
	clearAllRecentlyUsed: 'Clear All Recently Used Items?',
	clearAllRecentlyUsedConfirm:
		'This will clear your recently logged items on all synced devices',
	removedFromRecentlyUsed: 'Removed From Recently Used',
	itemsAdded: 'Items Added',
	mealName: 'Meal Name',
	servingName: 'Serving Name',
	wholeRecipe: 'Whole Recipe',

	//Onboard
	clickStart: 'You are all set, click the button below to get started',
	privacyPolicy: 'Privacy Policy',
	termsConditions: 'Terms and Conditions',
	policyAgree: 'By clicking below, you are agreeing to our',
	unitsOnboard:
		'Choose your preferred units for Body Measurements (Weight, Chest Size, etc) and Water',
	clockOnboard:
		'Choose how you want your start and end timer display formatted',
	scheduleOnboard:
		'Choose if you want to receive notifications every time you change windows and if you want a reminder before the period finishes.',
	manualOnboardSettings:
		'Choose your default fast goal (you can quickly change this on the homepage) and if you want to choose your goal in total hours or if you want to select a date and time for your goal.',
	manualOnboardNotifications:
		'Choose if you want to receive notifications such as a specific reminder to fast every day at a certain time, and reminders when the fast has ended or is about to end soon.',
	weightLoss: 'Weight Loss',
	diseasePrevention: 'Disease Prevention',
	lowerBloodPressure: 'Lower Blood Pressure',
	improvedCholesterolProfile: 'Improved Cholesterol Profile',
	antiAging: 'Anti-Aging & Longevity',
	insulinManagement: 'Insulin and Diabetes Management',
	antiCancer: 'Anti-Cancer',
	heartHealth: 'Heart Health',
	improvedSkin: 'Improved Skin',
	dentalHealth: 'Dental Health',
	whyKeto: 'Why are you interested in the dash diet?',
	betterTailor: 'We will better tailor MyDashDiet to help you with your goals',
	goals: 'Goals',
	goalsExplanation:
		'We will use this information to help you predict your progress',
	finished: 'Finished',
	startTracking: 'Start Tracking',

	//Body Measurements
	weightUnits: 'Weight and Body Measurements',
	units: 'Units',
	metric: 'Metric',
	imperial: 'Imperial',
	ftcm: 'ft, lbs',
	bodyMeasurements: 'Measurements',
	Weight: 'Weight',
	BMI: 'BMI',
	BodyFat: 'Body Fat',
	LeanBodyMass: 'Lean Body Mass',
	WaistSize: 'Waist Size',
	HipSize: 'Hip Size',
	ChestSize: 'Chest Size',
	NeckSize: 'Neck Size',
	ArmSize: 'Arm Size',
	ForearmSize: 'Forearm Size',
	CalfSize: 'Calf Size',
	ThighSize: 'Thigh Size',
	enterNumber: 'Please enter a number above zero.',

	//Vitals
	vitals: 'Vitals',
	water: 'Water',
	bloodGlucose: 'Blood Glucose',
	BloodGlucose: 'Blood Glucose',
	Exercise: 'Exercise',
	BloodPressure: 'Blood Pressure',
	HeartRate: 'Heart Rate',
	Ketones: 'Ketones',
	ketones: 'Ketones',
	Potassium: 'Potassium',
	Sodium: 'Sodium',
	Magnesium: 'Magnesium',
	Systolic: 'Systolic',
	Diastolic: 'Diastolic',

	//Log
	breakfast: 'Breakfast',
	lunch: 'Lunch',
	dinner: 'Dinner',
	snacks: 'Snacks',
	quickAdd: 'Quick Add',
	quickAddMacros: 'Quick Add Macros',
	quickAddToMeal: 'Quick Add To Meal',
	copyTo: 'Copy To',
	saveAsMeal: 'Save As Meal',
	addEntry: 'Add Entry',
	caloriesFromMacros: 'Calories From Macros',
	eaten: 'Eaten',
	remaining: 'Remaining',
	macroGoalsEaten: 'Macro Goals Eaten',
	per: 'Per',

	//Other Tracking
	syncExercise: 'Sync Exercise',
	estimateCalories: 'Estimate Calories',
	selectTime: 'Select Time',
	duration: 'Duration',
	caloriesBurned: 'Calories Burned',
	addExerciseCal: 'Add Exercise Cal',
	Notes: 'Notes',
	notes: 'Notes',
	exercise: 'Exercise',
	Water: 'Water',
	Mood: 'Mood',
	mood: 'Mood',

	//Progress
	custom: 'Custom',
	oneWeek: '1 Week',
	oneMonth: '1 Month',
	threeMonths: '3 Months',
	sixMonths: '6 Months',
	oneYear: '1 Year',
	allTime: 'All Time',
	to: 'To',
	addTwoEntries: 'Add 2 or more entries',
	addMoreEntriesToSeeLine:
		'Add more entries to see line-chart of macros over time',
	netChange: 'Net Change',
	noEntriesFound: 'No Entries Found',
	addFoodEntries: 'Add food entries to your log to see data',
	viewAllData: 'View All Data',

	//export
	exportWeight: 'Export Weight',
	exportBodyMeasurements: 'Export Body Measurements',
	exportVitals: 'Export Vitals',
	exportOthers: 'Export Others',
	permissionTitle: 'File Permission',
	permissionMessage:
		'We need permission to save the export .csv file to your device storage.',
	pickOne: 'Pick at least one option',
	emailSent: 'Email Successfully Sent',
	emailNotSent: 'Email Not Sent',
	export: 'Export',
	type: 'Type',
	dietLog: 'Diet Log',
	data: 'Data',
	allData: 'All Data',
	noData: 'No Data',
	noDataDesc: 'There is no data to export',

	//Fitness Trackers
	fitbitDesc:
		'Send your MyDashDiet weight, and macro data into Fitbit. Send your Fitbit exercise activity into MyDashDiet',
	fitbitConnected: 'Fitbit Syncing Connected',
	fitbitRemove: 'Do you want to remove Fitbit access from this device?',
	fitbitDisconnected: 'Fitbit disconnected',
	healthkit1:
		'To update your permissions for Apple Healthkit, open the health kit app on your phone, tap on sources, and select MyDashDiet from the list',
	healthkit2:
		'Send your MyDashDiet weight, water, and macros data into Apple HealthKit',

	//Social
	friendRequest: 'Friend request',
	tryAddFriend:
		"Try adding a friend by email with the (+) button. Once accepted, you can copy each other's saved foods and meals.",
	alreadyCopied: 'Already Copied',
	sent: 'Sent',
	received: 'Received',
	alreadyInvited: 'Already Invited',
	alreadyPendingInvite: 'This user already has a pending invite',
	alreadyFriended: 'Already Friended',
	alreadyYourFriend: 'This user is already your friend',
	pendingInvite: 'Pending Invite',
	pendingAlready: 'This user already sent you an invite',
	friendRequestSent: 'Friend Request Sent',
	invalidEmail: 'Invalid Email',
	cantFriendYourself: "You can't friend yourself",
	friendRequestCanceled: 'Friend Request Canceled',
	friendRequestAccepted: 'Friend Request Accepted',
	friendRequestDeclined: 'Friend Request Declined',
	unfriended: 'Unfriended',
	allFoodsCopied: 'All Foods Copied',
	friendNotAnon:
		'Please convert your account in the settings page to enable the social features',

	//Exercise Calculator
	ex1: 'Aerobic Dance (Casual)',
	ex2: 'Aerobic Dance (Moderate)',
	ex3: 'Aerobic Dance (Intense)',
	ex4: 'Basketball (Full court)',
	ex5: 'Basketball (Half court)',
	ex6: 'Calisthenics',
	ex7: 'Cycling (5.5 mph, 9 kmph)',
	ex8: 'Cycling (10 mph, 16 kmph)',
	ex9: 'Cycling (13 mph, 21 kmph)',
	ex10: 'Dancing (Casual)',
	ex11: 'Dancing (Moderate)',
	ex12: 'Dancing (Intense)',
	ex13: 'Field Sports',
	ex14: 'Frisbie',
	ex15: 'Golf (walking)',
	ex16: 'Golf (with Cart)',
	ex17: 'Gymnastics',
	ex18: 'Housework',
	ex19: 'Jump Rope',
	ex20: 'Martial Arts',
	ex21: 'Racquetball',
	ex22: 'Rollerblading',
	ex23: 'Rowing Machine (Intense)',
	ex24: 'Rowing Machine (Moderate)',
	ex25: 'Running (4 mph, 6.5 kmph)',
	ex26: 'Running (5 mph, 8 kmph)',
	ex27: 'Running (6 mph, 9.7 kmph)',
	ex28: 'Running (7 mph, 11.3 kmph)',
	ex29: 'Running (8 mph, 13 kmph)',
	ex30: 'Running (10 mph, 16 kmph)',
	ex31: 'Running (12 mph, 19.3 kmph)',
	ex32: 'Softball',
	ex33: 'Stair Step (6", 15.2 cm)',
	ex34: 'Stair Step (8", 20.3 cm)',
	ex35: 'Stair Step (10", 25.4 cm)',
	ex36: 'Stair Step (12", 30.5 cm)',
	ex37: 'Swimming (Casual)',
	ex38: 'Swimming (intense)',
	ex39: 'Tennis',
	ex40: 'Volleyball',
	ex41: 'Walking (2 mph, 3.2 kmph)',
	ex42: 'Walking (3 mph, 4.8 kmph)',
	ex43: 'Walking (4 mph, 6.5 kmph)',
	ex44: 'Weight Training (Intense)',
	ex45: 'Weight Training (Normal)',
	ex46: 'Yardwork',
	ex47: 'Yoga (Hatha)',
	ex48: 'Yoga (Ashtanga / Power)',
	ex49: 'Yoga (Hot / Bikram)',
	ex50: 'Yoga (Vinyasa / Flow)',
	exerciseCalculatorDescrition:
		'Enter your weight, exercise duration, and the activity to estimate your energy expenditure',

	//Shopping List
	addItem: 'Add Item',
	deleteItem: 'Delete Item'
};
