//@flow
import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Text, Gram } from 'Components/common';
import { i18n } from 'Theme';
import Elevations from 'react-native-elevation';

type Props = {
	settings: {
		goals: Object,
		carbgrams: Object,
		fatgrams: number,
		proteingrams: number
	},
	theme: Object
};

export const ProfileHeader = (props: Props) => {
	const {
		settings: { goals, carbgrams, fatgrams, proteingrams },
		theme
	} = props;
	return (
		<View style={[styles.profileContainer, { backgroundColor: theme.grey }]}>
			<View style={styles.macrosContainer}>
				<View
					style={[
						styles.singleMacroContainer,
						{ borderRightColor: theme.border }
					]}>
					<Text style={styles.macroText}>
						{fatgrams} <Gram />
					</Text>
					<Text style={[styles.macroLabel, { color: theme.graphFat }]}>
						{i18n.t('fat')} <Gram customText={`(${goals.fat}%)`} />
					</Text>
				</View>
				<View
					style={[
						styles.singleMacroContainer,
						{ borderRightColor: theme.border }
					]}>
					<Text style={styles.macroText}>
						{proteingrams} <Gram />
					</Text>
					<Text style={[styles.macroLabel, { color: theme.graphCarb }]}>
						{i18n.t('protein')} <Gram customText={`(${goals.protein}%)`} />
					</Text>
				</View>
				<View
					style={[
						styles.singleMacroContainer,
						{ borderRightColor: theme.border }
					]}>
					<Text style={styles.macroText}>
						{carbgrams} <Gram />
					</Text>
					<Text style={[styles.macroLabel, { color: theme.graphProtein }]}>
						{i18n.t('carbs')} <Gram customText={`(${goals.carb}%)`} />
					</Text>
				</View>
				<View
					style={[
						styles.singleMacroContainer,
						{ borderRightColor: theme.border }
					]}>
					<Text style={styles.macroText}>
						{goals.cal} <Gram customText={'kCal'} />
					</Text>
					<Text style={styles.macroLabel}>{i18n.t('calories')}</Text>
				</View>
			</View>
			<View style={styles.caloriesContainer}>
				<View style={[styles.singleMacroContainer, {borderRightWidth: 0}]}>
					<Text style={styles.macroText}>
						{goals.sodium} <Gram customText={'mg'} />
					</Text>
					<Text style={[styles.macroLabel]}>{i18n.t('Sodium')}</Text>
				</View>
			</View>
		</View>
	);
};

ProfileHeader.propTypes = {
	settings: PropTypes.object,
	goals: PropTypes.object,
	carbgrams: PropTypes.number,
	fatgrams: PropTypes.number,
	proteingrams: PropTypes.number
};

const styles = StyleSheet.create({
	profileContainer: {
		flex: 1,
		justifyContent: 'flex-start',
		...Elevations[2],
		marginBottom: 1
	},
	macrosContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		paddingVertical: 20
	},
	singleMacroContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		borderRightWidth: 1
	},
	macroText: {
		fontSize: 14,
		lineHeight: 16
	},
	macroLabel: {
		fontSize: 11,
		lineHeight: 20
	},
	caloriesContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: 10
	}
});
