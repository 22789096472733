//@flow
import Toast from 'react-native-root-toast';
import { randomString } from 'Utilities/FirebaseHelper';
import * as NavigationService from 'Navigation/NavigationService';
import { incrementTrackerInterAdCount } from 'Redux/actions';
import { ADDED_MACROS_TO_LOG } from 'Components/tracker/log/actions/types';
import { i18n } from 'Theme';
//========================CustomFoodTab.js=====================

//Create Button on Custom Food Tab: Saves a new Custom Food Preset from the form Values. Accepts callback to close modal or navigate somewhere
export const createCustomFood = (values) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const uniqid = randomString(14);

	firebase
		.update(`userdata/${uid}/customfoodsv3/${uniqid}`, {
			name: values.name,
			calories: values.calories,
			fats: values.fats,
			proteins: values.proteins,
			carbs: values.carbs,
			totalcarbs: values.totalcarbs,
			fiber: values.fiber,
			sodium: values.sodium,
			manualCalories: values.manualCalories,
			nutrientsV2: values.nutrientsV2,
			favorite: false,
			id: uniqid,
			type: 'food'
		})
		.then(() => {
			return Toast.show(`${values.name} ${i18n.t('savedToFoods')}`, {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

//Adds all MultiSelected Items to Log, Loops through selected items array and makes a Firebase Set Call for each, then Navigates back to log.
export const multiAddFoods = (selected, logDate, mealTime, callBack) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { premium } = state.firebase.profile;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let counter = selected.length;
	const finalCall = () => {
		callBack();
		Toast.show(i18n.t('itemsAdded'), {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
		NavigationService.addFoodReturn();
	};
	let updates = {};
	selected.forEach((element) => {
		const uniqid = randomString(14);
		const postData = {
			name: element.customServingName
				? `${element.name} x 1 ${element.customServingName}`
				: `${element.name} x 1 ${i18n.t('servings')}`,
			calories: element.calories,
			fats: element.fats,
			carbs: element.carbs,
			proteins: element.proteins,
			sodium: element.sodium,
			totalcarbs: element.totalcarbs,
			fiber: element.fiber,
			random: uniqid,
			manualCalories: element.manualCalories,
			nutrientsV2: element.nutrientsV2,
			id: element.id,
			type: element.type
		};

		updates[`trackerdate/${logDate}/${mealTime}/${uniqid}`] = postData;
		updates[`recentlyused/${element.id}`] = {
			name: element.name,
			id: element.id,
			type: element.type
		};
		counter -= 1;
		if (counter === 0) {
			ref.update(updates).then(() => {
				dispatch({ type: ADDED_MACROS_TO_LOG, payload: mealTime });
				if (!premium) dispatch(incrementTrackerInterAdCount());
				return finalCall();
			});
		}
	});
};

//------------------------------------------CustomFoodTabSingle.js -------------------------------------------------

//Updates a Single Custom Food Editing State to Firebase. If the name was changed && the food was in the /favorites or /recentlyused nodes, update their names too
export const saveEditSingleFood = (values, originalItem) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { favorites = {}, recentlyused = {} } = state.firebase.profile;
	let nameChange = values.name !== originalItem.name ? true : false;

	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};
	updates[`customfoodsv3/${originalItem.id}`] = {
		name: values.name,
		calories: values.calories,
		fats: values.fats,
		carbs: values.carbs,
		proteins: values.proteins,
		manualCalories: values.manualCalories,
		totalcarbs: values.totalcarbs,
		fiber: values.fiber,
		sodium: values.sodium,
		nutrientsV2: values.nutrientsV2,
		id: originalItem.id,
		type: originalItem.type,
		favorite:
			typeof originalItem.favorite !== 'undefined'
				? originalItem.favorite
				: false
	};

	if (nameChange === true) {
		//Name changed so need to also update /favorites node if its a favorited item, and also /recentlyused node if it was present there too.
		if (favorites.hasOwnProperty(originalItem.id)) {
			updates[`favorites/${originalItem.id}`] = {
				name: values.name,
				id: originalItem.id,
				type: originalItem.type
			};
		}

		if (recentlyused.hasOwnProperty(originalItem.id)) {
			updates[`recentlyused/${originalItem.id}`] = {
				name: values.name,
				id: originalItem.id,
				type: originalItem.type
			};
		}
	}

	ref.update(updates).then(() => {
		Toast.show(`${values.name} ${i18n.t('updated')}`, {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});

	return Promise.resolve();
};
