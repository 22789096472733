//@flow
import { CHANGE_VITAL_REVIEW_NAME } from 'Redux/actions/types';
import moment from 'moment';
import { i18n } from 'Theme';

const INITIAL_STATE = {
	date: moment().format('YYYY-MM-DD'),
	activeReviewValue: 'Ketones', //Used for actual bodymeasurements/date/${NAMEOFMEASUREMENT} node. The local language name is converted to the English name for this.
	activeReviewLabel: i18n.t('ketones'), // used for showing the local language name of the measurement
	activeReviewIndex: 0 // used for IOS picker of changing measurement on review tab
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CHANGE_VITAL_REVIEW_NAME:
			return {
				...state,
				activeReviewValue: action.value,
				activeReviewLabel: action.label,
				activeReviewIndex: action.index
			};
		default:
			return state;
	}
};
