//@flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet, TouchableOpacity, FlatList } from 'react-native';
import { Text, Gram, WebCompatAlert } from 'Components/common';
import { DateHeader } from 'Components/tracker/log/components';
import { Colors, i18n } from 'Theme';
import { saveBodyMeasurement } from 'Redux/actions';
import { getDatesMeasurements, defaultMeasurementUnits } from 'Redux/selectors';
import {
	returnCorrectUnitsString,
	moderateScale,
	returnCorrectUnitsValue
} from 'Utilities';
import { EditModal } from './EditModal';

//Body Measurements Screen for logging data such as Weight, BMI, Body Fat, Waist Size etc
export const BodyMeasurementsScreen = ({ navigation, route, unitsArray }) => {
	const dispatch = useDispatch();
	const { premium } = useSelector((state) => state.firebase.profile);
	const [isModalVisible, toggleModal] = useState(false);
	const [editingName, setEditingName] = useState('Weight');
	const [editingText, setEditingText] = useState('0');

	const { bodymeasurementsUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);
	const { date } = useSelector((state) => state.log);
	const { theme } = useSelector((state) => state.startup);
	const activeDateMeasurements = useSelector((state) =>
		getDatesMeasurements(state)
	);

	const editItem = (name, unitType, value, index) => {
		//Preset the editing modal to have the correct items name, text (value)
		setEditingText(value);
		setEditingName(name);
		toggleModal(true);
	};

	//Saves measurement to Firebase
	const saveEdit = (name, unitType) => {
		//Remove , which non-usa countries use as a decimal because ","" does not work in firebase saving
		const formattedValue = editingText.replace(',', '.');
		//Make sure value is a real number
		if (isNaN(formattedValue) || +formattedValue <= 0) {
			return WebCompatAlert(i18n.t('error'), i18n.t('enterNumber'));
		}
		//Save value to Firebase node and close modal
		dispatch(saveBodyMeasurement(date, name, formattedValue, unitType));
		return toggleModal(false);
	};

	const renderItem = ({ item, index }) => {
		const name = Object.keys(item)[0]; // get string name of item
		const firebaseValue = item[name]; // get number value of item
		const unitType = unitsArray[name]; //use name to get unitType (ex: weight, length, percent)

		//Return correct value based on users unit preference vs what is saved into Firebase
		const value = returnCorrectUnitsValue(
			unitType,
			bodymeasurementsUnits,
			firebaseValue
		).toFixed(1);

		if (!premium && name !== 'Weight' && name !== 'Body Fat') {
			return;
		}
		return (
			<TouchableOpacity
				style={[styles.listItemContainer, { borderBottomColor: theme.border }]}
				onPress={() => editItem(name, unitType, value, index)}>
				<View style={styles.labelCtn}>
					<Text style={[styles.listLabel, { color: theme.themeAccent }]}>
						{i18n.t(name.replace(/\s+/g, ''))}
					</Text>
				</View>
				<View style={styles.valueCtn}>
					<Text style={[styles.listLabel, { color: theme.themeAccent }]}>
						{value}
					</Text>
					<Gram
						style={{ fontSize: 9, color: theme.darkFont }}
						customText={returnCorrectUnitsString(
							unitType,
							bodymeasurementsUnits
						)}
					/>
				</View>
			</TouchableOpacity>
		);
	};

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<DateHeader theme={theme} />
			<FlatList
				data={activeDateMeasurements}
				renderItem={renderItem}
				style={styles.flatlistCtn}
				keyExtractor={(item) => Object.keys(item)[0]}
			/>
			<EditModal
				isModalVisible={isModalVisible}
				onClose={() => toggleModal(false)}
				onChangeValue={(value) => setEditingText(value)}
				saveEdit={(name, unitType) => saveEdit(name, unitType)}
				value={editingText}
				name={editingName}
				bodymeasurementsUnits={bodymeasurementsUnits}
				unitsArray={unitsArray}
				theme={theme}
			/>
		</View>
	);
};

//Default unit types to use for formatting correct value based on user's unit preference (lbs vs kg, in vs cm, etc)
BodyMeasurementsScreen.defaultProps = { unitsArray: defaultMeasurementUnits };

const styles = StyleSheet.create({
	flatlistCtn: {
		paddingHorizontal: 25
	},
	listItemContainer: {
		flex: 1,
		flexDirection: 'row',
		borderBottomWidth: 1,
		paddingVertical: 10,
		paddingLeft: 5,
		alignItems: 'center'
	},
	labelCtn: {
		flex: 4,
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row'
	},
	listLabel: {
		alignSelf: 'center',
		paddingRight: 5,
		fontSize: 12
	},
	valueCtn: {
		alignItems: 'center',
		flexDirection: 'row'
	}
});
