//@flow
import React, { useEffect, useState } from 'react';
import { View, ScrollView, Text, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { FsImage } from './components/FsImage';
import {
	FsActionButtons,
	FsSaveAsModal,
	FsServingSelector
} from './components/single';
import {
	Loader,
	Button,
	ClickableHeader,
	Collapsible
} from 'Components/common';
import {
	fetchFatSecretFood,
	receiveFatSecretFood,
	pushRecentlyScanned,
	logSingleSearched
} from './actions';
import {
	favoriteAddApiItem,
	quickRemoveFavorite
} from 'Components/tracker/foodtabs/actions';
import { i18n } from 'Theme';
import { Header, MacroGrid, Nutrients } from 'Components/common/singleitem';
import { Feature } from 'Components/purchase/components';

export const FsSingleScreen = ({ navigation, route }) => {
	const dispatch = useDispatch();
	const { theme } = useSelector((state) => state.startup);
	const [viewNutrients, toggleNutrients] = useState(false);
	const [modalVisible, toggleModal] = useState(false);
	const [isLoadingComplete, setLoadingComplete] = useState(false);
	const { premium } = useSelector((state) => state.firebase.profile);
	const { CurrentServingData, placeholder, isFetching, foodData } = useSelector(
		(state) => state.fatSecretSingleFood
	);
	const cachedFoods = useSelector(
		(state) => state.fatSecretApiCache.cachedFoods
	);
	const { mealTime, date, editingLogEntry } = useSelector((state) => state.log);
	const {
		favorites = {},
		settings: { units }
	} = useSelector((state) => state.firebase.profile);

	useEffect(() => {
		const { itemName } = route.params;
		let obj = cachedFoods.find((o) => o.food_id === route.params.foodID);
		if (typeof obj !== 'undefined') {
			//Food is cached, load from reducer value
			dispatch(
				receiveFatSecretFood({ food: obj }, itemName ? itemName : false)
			);
			setLoadingComplete(true);
		} else {
			//Not in cache, fetch the food from API (and thus adding it to 24hr cache reducer)
			dispatch(
				fetchFatSecretFood(route.params.foodID, itemName ? itemName : false)
			);
			setLoadingComplete(true);
		}
	}, []);

	useEffect(() => {
		if (route.params.recentBarcode) {
			dispatch(pushRecentlyScanned(foodData));
		}
	}, [foodData]);

	useEffect(() => {
		const isFav = typeof favorites[foodData.food_id] !== 'undefined';
		const onFavorite = () =>
			isFav
				? dispatch(quickRemoveFavorite(favorites[foodData.food_id]))
				: dispatch(favoriteAddApiItem(foodData));
		const favText = isFav ? i18n.t('removeFavorite') : i18n.t('addFavorite');
		const iconColor = isFav ? '#f1c40f' : theme.darkFont;
		const addTo =
			mealTime && mealTime.charAt(0).toUpperCase() + mealTime.slice(1);

		const addToLog = () => {
			dispatch(
				logSingleSearched(
					CurrentServingData,
					`${foodData.food_name} - ${placeholder}`,
					date,
					mealTime,
					foodData.food_id,
					foodData.food_name
				)
			);
		};

		navigation.setParams({
			menuArray: [
				/*{
					iconText: `${i18n.t('addTo')} ${addTo}`,
					onPress: () => addToLog(),
					iconName: 'plus',
					iconColor: theme.darkFont
				},*/
				{
					iconText: favText,
					onPress: onFavorite,
					iconName: 'star',
					iconColor
				},
				{
					iconText: i18n.t('saveToFoods'),
					onPress: () => toggleModal(true),
					iconName: 'spoon',
					iconColor: theme.darkFont
				}
			]
		});

		if (editingLogEntry) {
			navigation.setParams({
				favArray: [
					{
						iconText: favText,
						onPress: onFavorite,
						iconName: 'star',
						iconColor
					}
				]
			});
		}
	}, [favorites[foodData.food_id], foodData]);

	if (isFetching || !isLoadingComplete) {
		return (
			<Loader
				full={true}
				loaderBG={theme.loaderBG}
				loaderColor={theme.darkFont}
			/>
		);
	}

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<ScrollView style={{ flex: 1 }}>
				{foodData.food_id && (
					<View style={{ flex: 1 }}>
						<Header bgColor={theme.base} title={foodData.food_name}>
							{foodData.brand_name ? (
								<View>
									<Text style={[styles.metaText, { color: theme.darkFont }]}>
										{foodData.brand_name}
										{foodData.servings.serving.metric_serving_amount
											? ` - (${CurrentServingData.totalWeightG}${CurrentServingData.totalUnits})`
											: ''}
									</Text>
								</View>
							) : (
								<Text
									style={[
										styles.metaText,
										{ color: theme.darkFont }
									]}>{`${i18n.t('generic')} - (${
									CurrentServingData.totalWeightG
								}${CurrentServingData.totalUnits})`}</Text>
							)}
						</Header>
						<FsServingSelector theme={theme} />
						<MacroGrid
							carbUnits={units.carbUnits}
							{...CurrentServingData}
							fatSecretData={foodData}
							theme={theme}
						/>
						<ClickableHeader
							leftText="Nutrients:"
							noMargin
							onPress={() => toggleNutrients(!viewNutrients)}
							rightIconName={!viewNutrients ? 'arrow-right' : 'arrow-down'}
							style={{ backgroundColor: theme.base }}
							iconColor={theme.darkFont}
						/>
						<Collapsible collapsed={!viewNutrients}>
							{!premium ? (
								<View>
									<Feature
										icon="check"
										title="Track Nutrients"
										body="With Premium, follow more than just your macros. Keep track of: Cholesterol, Sodium, Calcium, Magnesium, Potassium, Iron, Zinc, Phosphorus, Folate, Thiamin (B1), Riboflavin (B2), Niacin (B3), Vitamin B6, Vitamin B12, Vitamin A,C,D,E,K, "
									/>
									<Button
										onPress={() => navigation.navigate('Purchase')}
										style={[{ backgroundColor: theme.teal }]}>
										{i18n.t('premium')}
									</Button>
								</View>
							) : (
								<Nutrients
									nutrients={CurrentServingData.nutrientsV2}
									theme={theme}
								/>
							)}
						</Collapsible>
						<FsActionButtons route={route} theme={theme} />
						<FsImage />
					</View>
				)}
			</ScrollView>
			<FsSaveAsModal
				isVisible={modalVisible}
				data={{ CurrentServingData, placeholder, foodData }}
				closeModal={() => toggleModal(false)}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	metaText: {
		fontSize: 12,
		lineHeight: 18
	}
});
