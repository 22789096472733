//@flow
import Toast from 'react-native-root-toast';
import { LOG_OUT_USER, DB_CHECKED } from 'Redux/actions/types';
import { getDates } from 'Utilities/ProgressFormat';
import { i18n } from 'Theme';
import { convertToMetricForFirebase } from 'Utilities';

export const logOut = () => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();

	firebase.logout();
	dispatch({ type: LOG_OUT_USER });
	dispatch({
		type: DB_CHECKED,
		payload: false
	});

	return Toast.show(i18n.t('loggedOut'), {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
};

export const updateGoalsDates = (start, end, gramGoals) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	const dateArray = getDates(start, end !== null ? end : start);
	let counter = dateArray.length;
	dateArray.forEach((element) => {
		const postData = {
			cal: +gramGoals.Calories,
			fatgrams: +gramGoals.Fat,
			carbgrams: +gramGoals.Carb,
			proteingrams: +gramGoals.Protein
		};
		let updates = {};
		updates[`trackerdate/${element}/dailygoals`] = postData;
		ref.update(updates).then(() => {
			counter -= 1;
			if (counter === 0) {
				return Toast.show(i18n.t('customGoalsApplied'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			}
		});
	});
};
export const updateSodium = (sodium: number) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();

	firebase
		.update(`userdata/${state.firebase.auth.uid}/settings/goals`, {
			sodium: +sodium
		})
		.then(() => {
			return Toast.show(i18n.t('updated'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

/*export const updatePreferences = (prefName: string, value: boolean) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/settings/preferences/`);
	let updates = {};
	const postData = value;
	updates[`${prefName}`] = postData;
	return ref.update(updates);
};*/

//Units Updater Action Creator
export const updateUnitsFormat = (name, boolean) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	firebase
		.update(`userdata/${uid}/settings/units`, {
			[`${name}`]: boolean
		})
		.then(() => {
			return Toast.show(i18n.t('unitsUpdated'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

export const updateManualGoals = (goals: Object, callback = false) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const FatPercent = +(((goals.fats * 9) / goals.calories) * 100).toFixed(0);
	const CarbPercent = +(((goals.carbs * 4) / goals.calories) * 100).toFixed(0);
	const ProteinPercent = +(
		((goals.proteins * 4) / goals.calories) *
		100
	).toFixed(0);
	
	const sodium = (+goals.sodium).toFixed(0);
	firebase
		.update(`userdata/${state.firebase.auth.uid}/settings/`, {
			carbgrams: +goals.carbs,
			fatgrams: +goals.fats,
			proteingrams: +goals.proteins,
			goals: {
				cal: +goals.calories,
				fat: +FatPercent,
				carb: +CarbPercent,
				protein: +ProteinPercent,
				sodium: +sodium
			}
		})
		.then(() => {
			if (callback) {
				callback();
			}
			return Toast.show(i18n.t('defaultMacroGoalsUpdated'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

//Units Updater Action Creator
export const updateGoalWeight = (value: number) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { bodymeasurementsUnits } = state.firebase.profile.settings.units;
	const ref = firebase.database().ref(`userdata/${uid}/profilePage/`);
	const formattedMetricValue = convertToMetricForFirebase(
		'weight',
		bodymeasurementsUnits,
		value
	);

	let updates = {};
	updates['goalWeight'] = formattedMetricValue.toFixed(3);
	ref.update(updates).then(() => {
		return Toast.show(i18n.t('updated'), {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
};
