//@flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	View,
	TouchableOpacity,
	FlatList,
	StyleSheet,
	Dimensions,
	ScrollView
} from 'react-native';
import { Text, Gram, ClickableHeader } from 'Components/common';
import {
	setBMProgressFilter,
	loadBodyMeasurementProgress,
	setBMProgressFromDate,
	setBMProgressToDate
} from './actions/ProgressActions';
import { i18n } from 'Theme';
import { FontAwesome } from '@expo/vector-icons';
import {
	returnCorrectUnitsString,
	returnScatterRange,
	moderateScale
} from 'Utilities';
import {
	getActiveVital,
	defaultVitalsUnits,
	getVitalChartData
} from 'Redux/selectors';
import { deleteVital, changeVitalName, createDemoData } from 'Redux/actions';
import {
	ActiveItemFilter,
	ProgressDatePicker,
	ProgressFilter,
	ProgressScatter,
	ProgressBar
} from './components';
import { VitalsEditModal } from 'Components/tracker/vitals/VitalsEditModal';
import moment from 'moment';
import * as Animatable from 'react-native-animatable';

const { width } = Dimensions.get('window');

export const VitalsProgressScreen = (props) => {
	const dispatch = useDispatch();
	const { premium } = useSelector((state) => state.firebase.profile);
	const [isModalVisible, toggleModal] = useState(false);
	const [viewMore, toggleViewMore] = useState(false);

	const [editingArr, setEditingArr] = useState([]);
	const [editingDate, setEditingDate] = useState(moment().format('YYYY-MM-DD'));

	const { units } = useSelector((state) => state.firebase.profile.settings);
	const { theme } = useSelector((state) => state.startup);
	const {
		progressBMFilter,
		progressBMFromDate,
		progressBMToDate
	} = useSelector((state) => state.progress);

	const {
		activeReviewIndex,
		activeReviewLabel,
		activeReviewValue
	} = useSelector((state) => state.vitals);

	const activeData = useSelector((state) => getActiveVital(state));
	const bodyChartsData = useSelector((state) => getVitalChartData(state));

	let filterItems = [];
	filterItems = premium
		? [
				`${i18n.t('Ketones')}`,
				`${i18n.t('BloodGlucose')}`,
				`${i18n.t('HeartRate')}`,
				`${i18n.t('BloodPressure')}`
		  ]
		: [`${i18n.t('BloodPressure')}`, `${i18n.t('BloodGlucose')}`];

	useEffect(() => {
		//dispatch(createDemoData())
		dispatch(
			loadBodyMeasurementProgress(
				progressBMFilter,
				progressBMFromDate,
				progressBMToDate
			)
		);
	}, [progressBMFilter, progressBMFromDate, progressBMToDate]);

	//Preset the editing modal to have the correct items name, text (value)
	const editItem = (item) => {
		setEditingArr([item]);
		setEditingDate(item.time);
		toggleModal(true);
	};

	const addVital = () => {
		setEditingArr([]);
		setEditingDate(moment().toISOString());
		toggleModal(true);
	};

	//Deletes vital entry from Firebase
	const _deleteVital = (item) => {
		//Delete vital entry using entry ID from firebase, update local view with callback
		dispatch(deleteVital(item, activeReviewValue));
	};

	const renderItem = ({ item, index }) => {
		return (
			<View
				style={
					index % 2 === 0
						? styles.itemContainer
						: [styles.itemContainerOdd, { backgroundColor: theme.grey }]
				}>
				<TouchableOpacity
					style={styles.itemTitle}
					onPress={() => editItem(item)}>
					<View style={styles.valueCtn}>
						{item.units !== 'mmHg' ? (
							<Text style={[styles.valueText, { color: theme.blue }]}>
								{item.formattedValue}
							</Text>
						) : (
							<Text
								style={[
									styles.valueText,
									{ color: theme.blue }
								]}>{`${item.formattedValue} / ${item.formattedValueTwo}`}</Text>
						)}
						<Gram customText={item.units} />
					</View>
					<Text style={styles.timeText}>{item.formattedDate}</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={[
						styles.quickDelete,
						{ backgroundColor: theme.grey, borderLeftColor: theme.border }
					]}
					onPress={() => _deleteVital(item)}>
					<FontAwesome
						name="close"
						size={14}
						color={theme.red}
						style={styles.quickDeleteIcon}
					/>
				</TouchableOpacity>
			</View>
		);
	};

	const renderChart = (itemName, data, _units) => {
		if (
			itemName === 'Blood Glucose' ||
			itemName === 'Blood Pressure' ||
			itemName === 'Heart Rate' ||
			itemName === 'Ketones'
		) {
			return (
				<ProgressScatter
					data={data}
					width={width}
					xLabel={i18n.t('date')}
					yLabel={_units}
					range={returnScatterRange(
						defaultVitalsUnits[activeReviewValue],
						units[`${defaultVitalsUnits[activeReviewValue]}Units`]
					)}
					theme={theme}
					legendData={
						itemName === 'Blood Pressure'
							? [
									{
										name: 'Systolic',
										symbol: { fill: theme.red, type: 'triangleDown' }
									},
									{
										name: 'Diastolic',
										symbol: { fill: theme.blue, type: 'triangleUp' }
									}
							  ]
							: []
					}
				/>
			);
		} else {
			return (
				<ProgressBar
					data={data}
					width={width}
					xLabel={i18n.t('date')}
					yLabel={_units}
					theme={theme}
				/>
			);
		}
	};

	const unitType = props.unitsArray[activeReviewValue];
	const unitPrefString = units[`${unitType}Units`];
	const unitString = returnCorrectUnitsString(unitType, unitPrefString);

	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<View style={styles.filterBlock}>
				<ProgressFilter
					progressFilter={progressBMFilter}
					setProgressFilter={setBMProgressFilter}
					style={{
						borderBottomWidth: 1,
						borderBottomColor: theme.border,
						height: 50
					}}
					theme={theme}
				/>
				{progressBMFilter === 6 && (
					<ProgressDatePicker
						progressFromDate={progressBMFromDate}
						setFromDate={setBMProgressFromDate}
						progressToDate={progressBMToDate}
						setToDate={setBMProgressToDate}
						theme={theme}
					/>
				)}
				<ActiveItemFilter
					activeReviewIndex={activeReviewIndex}
					activeReviewLabel={activeReviewLabel}
					changeReviewName={changeVitalName}
					addEntry={() => addVital()}
					filterItems={filterItems}
					theme={theme}
				/>
			</View>
			{renderChart(activeReviewValue, bodyChartsData, unitString)}
			<View style={[styles.divider, { borderColor: theme.teal }]} />
			<ClickableHeader
				leftText={i18n.t('viewAllData')}
				noMargin
				onPress={() => toggleViewMore(!viewMore)}
				rightIconName={!viewMore ? 'arrow-right' : 'arrow-down'}
				style={{ backgroundColor: theme.base }}
				iconColor={theme.darkFont}
			/>
			<Animatable.View animation={viewMore ? 'fadeInLeft' : 'fadeOutRight'}>
				<FlatList
					data={viewMore ? activeData : []}
					keyExtractor={(item) => item.id}
					renderItem={renderItem}
					style={{ flex: 1 }}
				/>
			</Animatable.View>
			<VitalsEditModal
				isModalVisible={isModalVisible}
				onClose={() => toggleModal(false)}
				onDelete={(item) => _deleteVital(item)}
				valueArr={editingArr}
				name={activeReviewValue}
				date={editingDate}
				unitsArray={props.unitsArray}
				dateMode={'datetime'}
				fromProgressPage={true}
				theme={theme}
			/>
		</ScrollView>
	);
};

//Default unit types to use for formatting correct value based on user's unit preference (lbs vs kg, in vs cm, etc)
VitalsProgressScreen.defaultProps = { unitsArray: defaultVitalsUnits };

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	filterBlock: {
		marginTop: 0,
		marginHorizontal: 0,
		zIndex: 9999
	},
	itemContainer: {
		flex: 1,
		flexDirection: 'row'
	},
	itemContainerOdd: {
		flexDirection: 'row'
	},
	itemTitle: {
		flex: 4,
		paddingVertical: 10,
		paddingLeft: 20,
		paddingRight: 8,
		alignItems: 'flex-start'
	},
	valueCtn: {
		alignItems: 'center',
		flexDirection: 'row'
	},
	valueText: {
		fontSize: 16,
		textAlign: 'left',
		lineHeight: 20,
		paddingRight: moderateScale(5)
	},
	timeText: {
		fontSize: 13,
		textAlign: 'left',
		lineHeight: 13,
		paddingVertical: 5
	},
	quickDelete: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'stretch',
		flex: 0.75,
		paddingVertical: 15,
		borderLeftWidth: 1
	},
	quickDeleteIcon: {
		alignSelf: 'center'
	},
	center: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1
	},
	chartTitle: {
		fontSize: 14,
		textAlign: 'center'
	},
	divider: {
		width: width * 0.75,
		borderWidth: 1,
		height: 1,
		alignSelf: 'center',
		marginVertical: 30
	}
});
