//@flow
import React from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonDouble } from 'Components/common';
import { logSingleSearched } from '../../actions';
import { updateLogEntry } from 'Components/tracker/log/actions';
import {
	addSearchIngredient,
	updateIngredientEditingMeal
} from 'Redux/actions';
import { i18n } from 'Theme';

//The API Searched Single View FsActionButtons show up on 5 different views: Regular Logging an Item, Editing an already logged item, Adding an item to a Meal, Editing an item already in a meal, and blank when it is used in the Net Carb Finder section.

export const FsActionButtons = (props) => {
	const dispatch = useDispatch();
	const { route, theme } = props;
	const {
		random,
		editingIngredient,
		ingredientRandom,
		editingMeal,
		editingLogEntry
	} = route.params;
	const { mealTime, date, editMealTime } = useSelector((state) => state.log);
	const { CurrentServingData, placeholder, foodData } = useSelector(
		(state) => state.fatSecretSingleFood
	);

	//Log -> Click a Log Entry API Item -- updates log entry @ trackerdate/date/mealtime/item
	if (editingLogEntry) {
		const item = {
			servingAmount: `${foodData.food_name} - ${placeholder}`,
			calories: CurrentServingData.calories,
			fats: CurrentServingData.fats,
			carbs: CurrentServingData.carbs,
			proteins: CurrentServingData.proteins,
			id: foodData.food_id,
			type: 'api',
			totalcarbs: CurrentServingData.totalcarbs,
			fiber: CurrentServingData.fiber,
			sodium: CurrentServingData.sodium,
			nutrientsV2: CurrentServingData.nutrientsV2
		};
		return (
			<ButtonDouble
				bgColor={theme.themeAccent}
				leftText={i18n.t('updateEntry')}
				leftPress={() =>
					dispatch(updateLogEntry(item, date, editMealTime, random))
				}
			/>
		);
	}

	//Editing SavedMeal: AddFood/Manage -> Meals -> MealSingle -> Editing -> Action Add Button -> Search -> Search Single
	if (editingMeal && !editingIngredient) {
		return (
			<ButtonDouble
				bgColor={theme.themeAccent}
				leftText={i18n.t('addToMeal')}
				leftPress={() =>
					dispatch(
						addSearchIngredient(
							CurrentServingData,
							`${foodData.food_name} - ${placeholder}`,
							foodData.food_id
						)
					)
				}
			/>
		);
	}

	//Editing Saved Meal: AddFood/Manage -> Meals -> MealSingle -> Editing -> Ingredient List API Ingredient Clicked
	if (editingIngredient) {
		return (
			<ButtonDouble
				bgColor={theme.themeAccent}
				leftText={i18n.t('updateIngredient')}
				leftPress={() =>
					dispatch(
						updateIngredientEditingMeal(
							CurrentServingData,
							`${foodData.food_name} - ${placeholder}`,
							foodData.food_id,
							ingredientRandom
						)
					)
				}
			/>
		);
	}

	if (route.name === 'NetCarbSearchSingle') {
		return <View />;
	}

	//Regular: Log -> AddFood -> Search -> SearchSingle
	const addTo = mealTime.charAt(0).toUpperCase() + mealTime.slice(1);
	return (
		<ButtonDouble
			bgColor={theme.themeAccent}
			leftText={`${i18n.t('addTo')} ${addTo}`}
			leftPress={() =>
				dispatch(
					logSingleSearched(
						CurrentServingData,
						`${foodData.food_name} - ${placeholder}`,
						date,
						mealTime,
						foodData.food_id,
						foodData.food_name
					)
				)
			}
		/>
	);
};
