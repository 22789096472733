//@flow
import {
	NEVER_RATE_APP,
	START_UP_COUNTER,
	CHANGE_LANG,
	TOGGLE_DARK_MODE
} from './types';
import { i18n, Colors, Dark } from 'Theme';
import moment from 'moment';
import { randomString } from 'Utilities/FirebaseHelper';

export const neverAskRateAgain = () => {
	return {
		type: NEVER_RATE_APP,
		payload: false
	};
};

export const addStartUpCounter = (count: number) => {
	return {
		type: START_UP_COUNTER,
		payload: count + 1
	};
};

export const toggleDarkMode = (isDark: boolean) => {
	let newTheme;
	if (isDark) {
		newTheme = Dark;
	} else {
		newTheme = Colors;
	}
	return {
		type: TOGGLE_DARK_MODE,
		payload: newTheme
	};
};

//Updates all the language and data strings on start-up and when the language is changed in the settings menu
export const updateLocalLanguage = (lang: string) => {
	i18n.locale = lang;
	return {
		type: CHANGE_LANG,
		payload: lang
	};
};

//Checks to make sure the user's firebase profile has all the necessary nodes and values. Some values are added in later updates, after the user already created their accounts. Thus, these new values need to be added immediately upon start-up
export const anonProfileCreate = (uid: string) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const ref = firebase.database().ref(`userdata/${uid}`);
	let updates = {};

	//updates['email'] = authEmail;
	updates['id'] = uid;
	updates['settings'] = {
		goals: { cal: 2000, carb: 5, fat: 75, protein: 20, sodium: 2300 },
		carbgrams: 25,
		fatgrams: 167,
		proteingrams: 100,
		units: {
			bodymeasurementsUnits: 'imperial', //default imperial units
			glucoseUnits: 'mmol/L', // default mmol/L,
			ketonesUnits: 'mmol/L', // default mmol/L,
			exerciseUnits: 'kCal', //default kCal, kJ
			waterUnits: 'oz', // default  oz, ml
			carbUnits: 'total' //defaults to net, total
		}
	};
	updates['onBoard'] = {
		shown: false,
		subscribedEmail: false,
		checkedInterests: [{ name: 'WeightLoss', status: 'inactive' }]
	};
	updates['profilePage'] = {
		height: '175.3', // in centimetrs
		weight: '79.8323', // in kg
		gender: 'female', //false === female, true === male
		age: 30, // years
		goalWeight: '77.11', //in kg
		exercise: 1.55
	};

	updates['vitalsConverted'] = true;
	updates['bodymeasurementsConverted'] = true;
	updates['othertrackingConverted'] = true;
	updates['premium'] = false;

	return ref
		.update(updates)
		.then(() => console.log('AnonNodesUpdated'))
		.catch((error) => {
			firebase.crashlytics().recordCustomError('DbCheck', error);
		});
};

//Checks to make sure the user's firebase profile has all the necessary nodes and values. Some values are added in later updates, after the user already created their accounts. Thus, these new values need to be added immediately upon start-up
export const updateDatabaseNodes = (callback: Function) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { purchase } = state.purchase;
	const {
		settings,
		onBoard,
		profilePage,
		trackerdate,
		bodymeasurementsConverted,
		vitalsConverted,
		othertrackingConverted,
		premium
	} = state.firebase.profile;
	const ref = firebase.database().ref(`userdata/${uid}`);
	let updates = {};

	//If profile exists, but the additional nodes added in later updates aren't added to the users profile yet, add them.
	if (typeof settings.units === 'undefined') {
		updates['settings/units'] = {
			bodymeasurementsUnits: 'imperial', //default imperial units
			glucoseUnits: 'mmol/L', // default mmol/L,
			ketonesUnits: 'mmol/L', // default mmol/L,
			exerciseUnits: 'kCal', //default kCal, kJ
			waterUnits: 'oz', // default  oz, ml
			carbUnits: 'total' //defaults to net, total
		};
	}

	if (typeof bodymeasurementsConverted === 'undefined') {
		updates['bodymeasurements'] = convertWeightsToBodyMeasurements(trackerdate);
		updates['bodymeasurementsConverted'] = true;
	}

	if (typeof vitalsConverted === 'undefined') {
		updates['vitals'] = convertVitals(trackerdate);
		updates['vitalsConverted'] = true;
	}

	if (typeof othertrackingConverted === 'undefined') {
		updates['othertracking'] = convertOtherTracking(trackerdate);
		updates['othertrackingConverted'] = true;
	}

	if (typeof onBoard === 'undefined') {
		updates['onBoard'] = {
			shown: false,
			subscribedEmail: false,
			checkedInterests: [{ name: 'WeightLoss', status: 'inactive' }]
		};
	}

	if (typeof profilePage === 'undefined') {
		updates['profilePage'] = {
			height: '175.3', // in centimetrs
			weight: '79.8323', // in kg
			gender: 'female', //false === female, true === male
			age: 30, // years
			goalWeight: '77.11', //in kg
			exercise: 1.55
		};
	}

	if (typeof premium === 'undefined') {
		updates['premium'] = purchase;
	}

	if (Object.entries(updates).length === 0 && updates.constructor === Object) {
		return callback();
	}

	return ref
		.update(updates)
		.then(() => callback())
		.catch((error) => {
			firebase.crashlytics().recordCustomError('DbCheck', error);
		});
};

/*--------------------------------DATABASE UPGRADE FUNCTION AND HELPERS (RUNS ONCE)--------------------------------------*/

//Pre-8.0.0 Weight entries were stored in the user/weight entry, post 2.0.0 we now store them in user/bodymeasurements/{YYYY-MM-DD}/Weight
const convertWeightsToBodyMeasurements = (trackerdate) => {
	let bodymeasurementsObj = {};
	for (var key in trackerdate) {
		if (trackerdate.hasOwnProperty(key)) {
			let obj = {};
			if (typeof trackerdate[key].Weight !== 'undefined') {
				if (
					trackerdate[key].Weight.weight === 0 ||
					trackerdate[key].Weight.weight === ''
				) {
					return;
				}
				obj.Weight = trackerdate[key].Weight.weight;
			}
			bodymeasurementsObj[key] = obj;
		}
	}

	return bodymeasurementsObj;
};

//Pre-8.0.0 ketones and bloodglucose were saved under trackerdate node with all the daily macro logs. Moves it to user/vitals/{YYYY-MM-DD}/${vitalname}
const convertVitals = (trackerdate) => {
	let vitalsObj = {};
	for (var key in trackerdate) {
		if (trackerdate.hasOwnProperty(key)) {
			let obj = {};

			if (typeof trackerdate[key].Ketones !== 'undefined') {
				if (
					trackerdate[key].Ketones.ketones === 0 ||
					trackerdate[key].Ketones === ''
				) {
					return;
				}
				const uniqid = randomString(14);
				obj.Ketones = {};
				obj.Ketones[uniqid] = {
					value: trackerdate[key].Ketones.ketones,
					time: moment(key, 'YYYY-MM-DD').toISOString(),
					id: uniqid
				};
			}
			if (typeof trackerdate[key].BloodGlucose !== 'undefined') {
				obj.BloodGlucose = {};
				Object.entries(trackerdate[key].BloodGlucose).forEach(
					([key, entry]) => {
						const { id, glucose, time } = entry;
						obj.BloodGlucose[id] = {
							value: glucose,
							time: time,
							id: id
						};
					}
				);
			}
			vitalsObj[key] = obj;
		}
	}

	return vitalsObj;
};

//Pre-8.0.0 exercise, notes, water were saved under trackerdate node with all the daily macro logs. Moves it to user/othertracking/{YYYY-MM-DD}/${othertrackingname}
const convertOtherTracking = (trackerdate) => {
	let otherObj = {};
	for (var key in trackerdate) {
		if (trackerdate.hasOwnProperty(key)) {
			let obj = {};
			if (typeof trackerdate[key].Exercise !== 'undefined') {
				if (
					trackerdate[key].Exercise.exercise === 0 ||
					trackerdate[key].Exercise === ''
				) {
					return;
				}
				const uniqid = randomString(14);
				obj.Exercise = {};
				obj.Exercise[uniqid] = {
					value: trackerdate[key].Exercise.exercise,
					time: moment(key, 'YYYY-MM-DD').toISOString(),
					id: uniqid
				};
			}
			if (typeof trackerdate[key].Water !== 'undefined') {
				if (
					trackerdate[key].Water.water === 0 ||
					trackerdate[key].Water === ''
				) {
					return;
				}
				const uniqid = randomString(14);
				obj.Water = {};
				obj.Water[uniqid] = {
					value: trackerdate[key].Water.water,
					time: moment(key, 'YYYY-MM-DD').toISOString(),
					id: uniqid
				};
			}
			if (typeof trackerdate[key].Notes !== 'undefined') {
				if (trackerdate[key].Notes === '') {
					return;
				}
				const uniqid = randomString(14);
				obj.Notes = {};
				obj.Notes[uniqid] = {
					value: trackerdate[key].Notes.notes,
					time: moment(key, 'YYYY-MM-DD').toISOString(),
					id: uniqid
				};
			}
			otherObj[key] = obj;
		}
	}
	return otherObj;
};
