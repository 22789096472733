//@flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal } from './actions';
import { View, ScrollView } from 'react-native';
import { Loader } from 'Components/common';
import {
	DateHeader,
	ViewTabBar,
	MealBoxContainer,
	ModalsContainer,
	OtherContainer
} from './components';
import { updateDatabaseNodes } from 'Redux/actions';

export const LogScreen = ({ navigation, route }) => {
	const dispatch = useDispatch();
	const [dbChecked, toggleDbCheck] = useState(false);
	const { isLoaded } = useSelector((state) => state.firebase.profile);
	const onBoard = useSelector((state) => state.firebase.profile.onBoard);
	const { theme } = useSelector((state) => state.startup);

	useEffect(() => {
		dispatch(updateDatabaseNodes(() => toggleDbCheck(true)));
	}, []);

	useEffect(() => {
		if (typeof onBoard === 'undefined' || !onBoard.shown) {
			dispatch(toggleModal('onboard', true));
		}
	}, [onBoard]);

	useEffect(() => {
		navigation.setParams({
			headerBtns: [
				{
					size: 24,
					name: 'scale-bathroom',
					color: theme.navButton,
					onPress: () => navigation.navigate('BodyMeasurements')
				},
				{
					size: 24,
					name: 'stethoscope',
					color: theme.navButton,
					onPress: () => navigation.navigate('Vitals')
				}
			]
		});
	}, []);

	//Not loaded yet
	if (!isLoaded || !dbChecked) {
		return <Loader />;
	}
	return (
		<View style={{ flex: 1 }}>
			<DateHeader theme={theme} />
			<ScrollView
				style={{ backgroundColor: theme.backdropGray }}
				keyboardShouldPersistTaps={'handled'}>
				<ViewTabBar theme={theme} />
				<MealBoxContainer />
				<OtherContainer theme={theme} />
			</ScrollView>
			<ModalsContainer theme={theme} />
		</View>
	);
};
