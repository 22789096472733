//@flow

//Strings used throughout the app translated into English, French, Spanish, Portuguese, German

export const esStrings = {
	appName: 'MyKeto',
	log: 'Registro',
	login: 'Iniciar sesión',
	learn: 'Aprender',
	foodlist: 'Lista de alimentos',
	recipes: 'Recetas',
	mealplan: 'Régimen de comidas',
	otherdiet: 'Otras dietas',
	moreapps: 'Más aplicaciones',
	macrocalculator: 'Calculadora de macros',
	purchase: 'Compra',
	tracker: 'Rastreador',
	progress: 'Progreso',
	manage: 'Gestionar',
	settings: 'Configuración',
	preferences: 'Preferencias',
	customgoals: 'Objetivos personalizados',
	othertracking: 'Otro seguimiento',
	social: 'Social',
	fitnessTracker: 'Fitness Trackers',
	feedback: 'Comentarios / Soporte',
	addFood: 'Agregar comida',
	addIngredient: 'Agregar ingrediente',
	mealsRecipes: 'Comidas / Recetas',
	editLogEntry: 'Editar entrada de registro',
	editMeal: 'Editar comida',
	editFood: 'Editar comida',
	deleteFood: 'Eliminar comida',
	deleteMeal: 'Eliminar comida',
	friends: 'Amigos',
	copyMeal: 'Copiar comida',
	mealCopied: 'Comida copiada',
	copyFood: 'Copiar comida',
	copyItems: 'Copiar elementos',
	copyPlus: 'Copiar +',
	copyAll: 'Cópialo todo',
	copied: 'Copiado',
	pending: 'Pendiente',
	manageFoods: 'Administrar alimentos',
	manageMeals: 'Administrar comidas',
	macros: 'Macros',
	ingredients: 'Ingredientes',
	directions: 'Direcciones',
	nutrition: 'Nutrición',
	nutrients: 'Nutrientes',
	save: 'Salvar',
	cancel: 'Cancelar',
	goback: 'Regresa',
	close: 'Cerrar',
	delete: 'Eliminar',
	saveas: 'Guardar como',
	add: 'Añadir',
	confirm: 'Confirmar',
	addfavorite: 'Añadir favorito',
	removeFavorite: 'Eliminar favorito',
	remove: 'Eliminar',
	removed: 'Remoto',
	added: 'Adicional',
	clear: 'Claro',
	send: 'Enviar',
	required: 'Necesario',
	macrosAddedToMeal: 'Macros añadidas a la comida',
	ingredientUpdated: 'Ingrediente actualizado',
	addtoMeal: 'Agregar a la comida',
	saveMeal: 'Guardar comida',
	addedToMeal: 'Agregado a la comida',
	mealSaved: 'Comida guardada',
	mealsAdded: 'Comidas agregadas',
	addTo: 'Añadir',
	addedToLog: 'Agregado al registro',
	saveAsFood: 'Guardar como alimento',
	updateEntry: 'Actualizar entrada',
	updateIngredient: 'Actualizar ingrediente',
	otherTrackingUpdated: 'Otro seguimiento actualizado',
	nameEmpty: 'Nombre vacío',
	pleaseEnterName: 'Introduzca un nombre',
	selectDate: 'Seleccione fecha',
	foodNotFound: 'Comida no encontrada',
	deleted: 'Eliminado',
	deleteConfirm: '¿Estas seguro que quieres borrarlo?',
	addedToFavorites: 'agregado a Favoritos',
	favoritesCleared: 'Favoritos borrados',
	removedFromFavorites: 'Eliminado de Favoritos',
	savedToFoods: 'Guardado en los alimentos',
	updated: 'Actualizado',
	multiAdd: 'Multi-Add',
	noThanks: 'No, gracias',
	giveFeedback: 'Dar opinion',
	rateAppName: 'Califica MyKeto',
	never: 'Nunca',
	later: 'Luego',
	rate: 'Velocidad',
	rateDesc:
		'Si disfrutas usando MyKeto, ¿te importaría tomarte un segundo para calificarlo? No tomará más de un minuto. ¡Gracias por tu apoyo!',
	rateFeed:
		'¿Le importaría enviarnos sus comentarios sobre lo que le gustaría ver en nuestra aplicación? ¡Gracias!',
	basics: 'Lo esencial',
	benefits: 'Beneficios',
	sideeffects: 'Efectos secundarios',
	myths: 'Mitos',
	faq: 'PREGUNTAS MÁS FRECUENTES',
	faq1: 'Preguntas frecuentes',
	getstarted: 'Empezar',
	fiber: 'Fibra',
	tips: 'Consejos',
	products: 'Productos Recomendados',
	basicsh1: '¿Qué es la Dieta Keto?',
	basicsh2: '¿Como funciona?',
	basicsh3: '¿Por qué Keto?',
	basicsp1:
		'La dieta cetogénica o cetogénica es muy similar a una dieta baja en carbohidratos como la Atkins. La principal diferencia entre un plan de dieta baja en carbohidratos y un plan de dieta cetogénica es la cantidad de carbohidratos y proteínas permitida a diario.\n\nEn general, las dietas bajas en carbohidratos reducen la ingesta de carbohidratos a menos de 100 gramos; Las dietas cetogénicas estrictas son un subgrupo de dietas bajas en carbohidratos que generalmente solo permiten hasta 50 g de carbohidratos por día.',
	basicsp2:
		'Además, la dieta cetogénica es una dieta alta en grasas, adecuada en proteínas y baja en carbohidratos. Estás cambiando los carbohidratos en la dieta por una mayor porción de grasa y una ingesta moderada de proteínas.',
	basicsp3:
		'Esta dieta alta en grasas pone a su cuerpo en un estado llamado "cetosis", donde el cuerpo produce cetonas en el hígado para usarlas como energía.\n\nNormalmente, los carbohidratos se descomponen en glucosa, que su cuerpo transporta en la sangre para administrar la energía de sus células. Por el contrario, la cetosis produce cetonas a partir de las altas cantidades de grasa que se consumen, y la presencia de carbohidratos grandes en la dieta realmente detendrá la cetosis.',
	basicsp4:
		'En comparación con la dieta occidental, que consiste principalmente en carbohidratos altamente refinados, la cetogénica puede ofrecer muchas ventajas para la salud. La mayoría de las personas comienzan una dieta cetogénica porque quieren perder peso. Sin embargo, la investigación científica sugiere que va más allá, como reducir el riesgo de enfermedades cardíacas, diabetes, cáncer, accidente cerebrovascular y más.\n\nLa dieta cetogénica es mucho más que simplemente eliminar los carbohidratos, es una estilo de vida sobre la salud en general. Promueve episodios de energía prolongados e intensos, un aumento de alimentos saludables y deliciosos y una mejor perspectiva general de su vida. Es fácil de mantener con un exceso de opciones y, a menudo, es una respuesta para mejorar el bienestar que muchas personas luchan por comprender al principio.\n\nKeto es difícil y pondrá a prueba su fuerza de voluntad, pero cambia la forma en que ve y piensa usted mismo, la alimentación y la salud en general.',
	basicsbq:
		'La ingesta de nutrientes en una dieta cetogénica generalmente equivale a aproximadamente el 70-75% de las calorías de la grasa, el 20-25% de las proteínas y el 5-10% de los carbohidratos a diario',
	benefitsh0: 'Ventajas',
	benefitsp0:
		'Las dietas cetogénicas tienen una larga lista de beneficios y pueden verse en tan solo una semana, mientras se desarrollan gradualmente durante un período de 3 semanas.',
	benefitsh1: '1. Pérdida de peso',
	benefitsp1:
		'Cuando el cuerpo quema grasa como fuente principal de energía, esencialmente la tomará de sus reservas de grasa, ya que se encuentra en un estado de ayuno. Los estudios muestran que las personas con dietas bajas en carbohidratos pierden más peso y más rápido que las personas con dietas bajas en grasas. Esto es cierto incluso cuando las personas que hacen dieta baja en grasas están restringiendo activamente las calorías.',
	benefitsbq1:
		'Los sujetos severamente obesos con una alta prevalencia de diabetes o síndrome metabólico perdieron más peso durante seis meses con una dieta restringida en carbohidratos que con una dieta restringida en calorías y grasas, con una mejora relativa en la sensibilidad a la insulina y los niveles de triglicéridos, incluso después ajuste por la cantidad de peso perdido.',
	benefitsbq2:
		'Una dieta baja en carbohidratos parece ser un método eficaz para la pérdida de peso a corto plazo en adolescentes con sobrepeso y no daña el perfil de lípidos.',
	benefitsbq3:
		'Un ensayo controlado multicéntrico de un año en el que participaron 63 hombres y mujeres obesos que fueron asignados al azar a una dieta baja en carbohidratos, alta en proteínas y alta en grasas o una dieta baja en calorías, alta en carbohidratos y baja en grasas (convencional ) dieta. La dieta baja en carbohidratos produjo una mayor pérdida de peso (diferencia absoluta, aproximadamente 4 por ciento) que la dieta convencional durante los primeros seis meses, pero las diferencias no fueron significativas al año.',
	benefitsbq4:
		'Un gran porcentaje de la grasa que se pierde con las dietas bajas en carbohidratos tiende a provenir de la grasa dañina en la cavidad abdominal que se sabe que causa serios problemas metabólicos.',
	benefitsbq5:
		'Un estudio reciente de la Facultad de Medicina de la Universidad Johns Hopkins confirma que una dieta alta en grasas y baja en carbohidratos no es perjudicial para la salud vascular Y da como resultado una pérdida de peso más rápida. Los autores confirmaron que las personas que hicieron dieta en el grupo bajo en carbohidratos bajaron más de peso durante un período de tiempo más corto que el grupo con alto contenido de carbohidratos, y el grupo bajo en carbohidratos no tuvo cambios dañinos en la salud vascular.',
	benefitsh2: '2. Presión sanguínea baja',
	benefitsp2:
		'Las dietas bajas en carbohidratos son muy efectivas para reducir la presión arterial. Hable con su médico si está tomando medicamentos para la presión arterial antes de comenzar.',
	benefitsbq6:
		'En una muestra de pacientes ambulatorios médicos, una dieta cetogénica baja en carbohidratos condujo a mejoras similares a la terapia con medicamentos con orlistat combinada con una dieta baja en grasas para controlar el peso, los lípidos séricos y los parámetros glucémicos y fue más eficaz para reducir la presión arterial.',
	benefitsh3: '3. Perfil de colesterol mejorado',
	benefitsp3:
		'Se ha demostrado que mejora los niveles de triglicéridos y los niveles de colesterol más asociados con la acumulación arterial.',
	benefitsbq7:
		'Las dietas cetogénicas aumentan las concentraciones de colesterol HDL saludable para el corazón más que las dietas bajas en grasas y altas en carbohidratos.',
	benefitsbq8:
		'Reducir el consumo de carbohidratos en individuos sanos también conduce a niveles más altos de colesterol HDL.',
	benefitsbq9:
		'Las dietas bajas en carbohidratos y altas en grasas disminuyen la concentración de partículas LDL (LDL-P) y aumentan el tamaño del colesterol LDL.',
	benefitsbq10:
		'Las dietas cetogénicas disminuyen la cantidad de colesterol VLDL dañino en la sangre.',
	benefitsh4: '4. Tratamiento estabilizado de azúcar en sangre y diabetes',
	benefitsp4:
		'La mejor forma de reducir los niveles de insulina y azúcar en sangre es reducir el consumo de carbohidratos. Esta también es una forma muy eficaz de tratar y posiblemente incluso revertir la diabetes tipo II.',
	benefitsbq11:
		'La LCKD mejoró el control glucémico en pacientes con diabetes tipo 2, de modo que en la mayoría de los participantes se interrumpieron o redujeron los medicamentos para la diabetes. Debido a que la LCKD puede ser muy eficaz para reducir la glucosa en sangre, los pacientes que toman medicamentos para la diabetes que usan esta dieta deben estar bajo una estrecha supervisión médica o ser capaces de ajustar su medicación.',
	benefitsbq12:
		'Alto contenido de grasas saturadas y muy bajo contenido de carbohidratos (VLCARB) da como resultado una pérdida de grasa similar a la de las dietas bajas en grasas saturadas, pero son más efectivas para mejorar las concentraciones de glucosa e insulina en ayunas y posprandiales, de triacilgliceroles, HDL-C. VLCARB puede ser útil en el tratamiento a corto plazo de sujetos con resistencia a la insulina e hipertriacilglicerolemia.',
	benefitsbq13:
		'La modificación de la dieta condujo a mejoras en el control glucémico y la reducción / eliminación de medicamentos en voluntarios motivados con diabetes tipo 2. La dieta baja en carbohidratos condujo a mayores mejoras en el control glucémico y una reducción / eliminación de medicamentos más frecuente que la dieta de bajo índice glucémico. La modificación del estilo de vida mediante intervenciones bajas en carbohidratos es eficaz para mejorar y revertir la diabetes tipo 2.',
	benefitsh5: '5. Falta de hambre',
	benefitsp5:
		'Los cuerpos cetónicos amortiguan el apetito, además, la grasa es naturalmente más satisfactoria y termina dejándonos en un estado lleno y saciado por más tiempo.',
	benefitsbq14:
		'Los síntomas de afecto negativo y hambre mejoraron en mayor grado en los pacientes que siguieron una dieta cetogénica en comparación con los que siguieron una dieta baja en grasas.',
	benefitsh6: '6. Anti-Cáncer',
	benefitsp6:
		'Keto puede ayudar a combatir ciertos tipos de cáncer y varios tumores.',
	benefitsbq15:
		'Aunque el mecanismo por el cual las dietas cetogénicas demuestran efectos anticancerígenos cuando se combinan con radioterapias estándar no se ha dilucidado por completo, los resultados preclínicos han demostrado la seguridad y la eficacia potencial del uso de dietas cetogénicas en combinación con radioterapia para mejorar las respuestas. en modelos de cáncer murino. Estos estudios preclínicos han proporcionado el ímpetu para extender el uso de dietas cetogénicas a ensayos clínicos de fase I que están actualmente en curso.',
	benefitsh7: '7. Mejor piel',
	benefitsp7:
		'Los investigadores dicen que los alimentos que aumentan el azúcar en sangre también pueden aumentar las hormonas. Las hormonas pueden estimular la producción de aceite, que a su vez, puede desencadenar el acné. La dieta Keto previene los picos de azúcar en la sangre al usar la grasa como principal fuente de energía.',
	benefitsbq16:
		'En un artículo publicado en el Journal of the Academy of Nutrition and Dietetics, Burris y sus colegas revisan 27 estudios sobre nutrición y acné. Algunas de las pruebas más convincentes provienen de algunos estudios recientes de adolescentes y hombres jóvenes (de 15 a 25 años) que tenían acné. Después de que los participantes siguieron una dieta de baja carga glucémica, los investigadores documentaron disminuciones en las lesiones inflamatorias del acné. Los estudios fueron pequeños, pero los hallazgos fueron significativos.',
	benefitsh8: '8. Salud dental mejorada',
	benefitsp8:
		'El azúcar (carbohidratos) es el principal contribuyente a las enfermedades y caries dentales.',
	benefitsbq17:
		'Los residuos de alimentos, específicamente los carbohidratos, tienen características que promueven la caries que permiten que ocurra la fermentación y la acumulación de ácido en la placa bacteriana. Mejora las cuatro actividades del biofilm mencionadas anteriormente: implantación, colonización, actividad metabólica y espesor. Cuando se restringen los carbohidratos en una dieta, se limita un factor esencial para el desarrollo de caries.',
	benefitsh9: '9. Estabilización del estado de ánimo',
	benefitsp9:
		'Se ha demostrado que los cuerpos cetónicos son beneficiosos para estabilizar neurotransmisores como la serotonina y la dopamina, lo que resulta en un mejor control del estado de ánimo.',
	benefitsbq18:
		'La dieta cetogénica, introducida originalmente en la década de 1920, ha experimentado un resurgimiento reciente como tratamiento complementario para la epilepsia refractaria, particularmente en niños. En esta población difícil de tratar, la dieta exhibe una eficacia notable: dos tercios muestran una reducción significativa en la frecuencia de las convulsiones y una tercera parte casi libre de convulsiones. Hay varias razones para sospechar que la dieta cetogénica también puede tener utilidad como estabilizador del estado de ánimo en la enfermedad bipolar. Estos incluyen la observación de que varias intervenciones anticonvulsivas pueden mejorar el resultado de los trastornos del estado de ánimo. Además, se observan cambios beneficiosos en el perfil de energía cerebral en sujetos con dieta cetogénica. Esto es importante ya que el hipometabolismo cerebral global es una característica del cerebro de los individuos deprimidos o maníacos. Finalmente, se esperaría que los cambios extracelulares que ocurren en la cetosis disminuyan las concentraciones de sodio intracelular, una propiedad común de todos los estabilizadores del estado de ánimo efectivos. Se justifican los ensayos de la dieta cetogénica en la prevención de recaídas de los episodios bipolares del estado de ánimo.',
	benefitsh10: '10. Posible aumento de energía',
	benefitsp10:
		'Se ha demostrado que las grasas son la molécula más eficaz para quemar como combustible.',
	benefitsbq19:
		'Durante el estrés metabólico, las cetonas sirven como una fuente de energía alternativa para mantener el metabolismo normal de las células cerebrales. De hecho, la BHB (una cetona importante) puede ser un combustible incluso más eficiente que la glucosa, ya que proporciona más energía por unidad de oxígeno utilizada. Una dieta cetogénica también aumenta la cantidad de mitocondrias, las llamadas "fábricas de energía" en las células cerebrales. Un estudio reciente encontró una mayor expresión de genes que codifican las enzimas mitocondriales y el metabolismo energético en el hipocampo, una parte del cerebro importante para el aprendizaje y la memoria. Las células del hipocampo a menudo se degeneran en enfermedades cerebrales relacionadas con la edad, lo que lleva a una disfunción cognitiva y pérdida de memoria. Con una mayor reserva de energía, las neuronas pueden protegerse de los factores estresantes de enfermedades que normalmente agotarían y matarían a la célula.',
	sideeffectsh0: 'Peligros',
	sideeffectsp0:
		'Como precaución, siempre debe consultar con su médico de cabecera antes de comenzar una dieta cetogénica. Debería estar especialmente cansado si usted o algún miembro de su familia tiene alguna condición renal, cardíaca o diabética preexistente.',
	sideeffectsh1: 'Osteoporosis y cálculos renales',
	sideeffectsp1:
		'También se ha demostrado que las dietas altas en proteínas hacen que las personas excreten una gran cantidad de calcio en la orina. Durante un período prolongado, esto puede aumentar el riesgo de una persona de padecer osteoporosis y cálculos renales. Una dieta que aumenta las proteínas a expensas de una ingesta muy restrictiva de carbohidratos vegetales puede ser perjudicial para los huesos, pero no necesariamente una ingesta alta en proteínas sola.',
	sideeffectsh2: 'Insuficiencia renal',
	sideeffectsp2:
		'Consumir demasiada proteína ejerce presión sobre los riñones, lo que puede hacer que una persona sea susceptible a una enfermedad renal',
	sideeffectsh3: 'Tiroides, Corazón, Presión Arterial',
	sideeffectsp3:
		'Existe evidencia limitada basada en la investigación que puede sugerir que la cetosis puede causar un aumento de corta duración en los niveles de adrenalina y cortisol en el cuerpo para un pequeño subgrupo de personas. A su vez, estos niveles elevados de hormonas pueden causar problemas de tiroides, irregularidades cardíacas y presión arterial elevada para algunos.',
	sideeffectsh4: 'Efectos secundarios',
	sideeffectsp4:
		'A medida que su cuerpo se induce a un estado cetogénico, su cuerpo utilizará naturalmente lo que queda de su glucosa. Por lo tanto, el cuerpo agotará todo el almacenamiento de glucosa en los músculos, lo que puede causar falta de energía y letargo general. Durante las primeras dos semanas, las personas informan:',
	sideeffectsbq1:
		'"Durante los primeros días o algunas semanas de adaptación a una dieta baja en carbohidratos, su cuerpo está eliminando las enzimas trabajadoras de los carbohidratos y construyendo nuevas enzimas trabajadoras de la grasa. Una vez que la fuerza laboral de su cuerpo ha cambiado, comienza a funcionar correctamente en su nuevo nivel bajo -carbohidratos, dieta alta en grasas. Los carbohidratos que solía quemar para obtener energía ahora son reemplazados en gran medida por cetonas ... "- Mike Eades MD',
	sideeffectsh5: 'Keto Flu',
	sideeffectsp5: 'Síntomas parecidos a la gripe',
	sideeffectsh6: 'Dolores de cabeza',
	sideeffectsp6:
		'La cetosis puede causar dolores de cabeza a algunas personas y es posible que se sienta un poco mareado.',
	sideeffectsh7: 'Fatiga y mareos',
	sideeffectsp7:
		'A medida que empiece a tirar agua, perderá minerales como la sal, el potasio y el magnesio. Tener niveles más bajos de estos minerales lo hará muy, muy cansado, aturdido o mareado, le dará calambres musculares y dolores de cabeza. Este es uno de los es el más común de los efectos secundarios de la dieta baja en carbohidratos, y puede evitarse en su mayor parte asegurándose de obtener suficientes minerales de reemplazo',
	sideeffectsh8: 'Episodios de hipoglucemia',
	sideeffectsp8:
		'El cuerpo está acostumbrado a liberar una cierta cantidad de insulina para cuidar la glucosa que se crea a partir de todos los carbohidratos que consume. Cuando reduce su ingesta de carbohidratos, inicialmente puede haber episodios ocasionales de niveles bajos de azúcar en sangre',
	sideeffectsh9: 'Niebla mental',
	sideeffectsp9:
		'No es raro tener una falta de claridad mental al principio. En la mayoría de los casos, la niebla mental solo durará unos días.',
	sideeffectsh10: 'Micción frecuente',
	sideeffectsp10:
		'Después de comenzar, notará que va al baño con más frecuencia. El cuerpo está quemando la glucosa sobrante (del almacenamiento del glucógeno) en el hígado y los músculos. Al descomponer el glucógeno se libera mucha agua y los riñones comenzará a deshacerse de él. Además, a medida que disminuyen los niveles de insulina circulante, los riñones comienzan a excretar el exceso de sodio, lo que también provocará una micción más frecuente.',
	sideeffectsh11: 'Estreñimiento',
	sideeffectsp11:
		'Este es uno de los efectos secundarios más comunes y generalmente es el resultado de la deshidratación, la pérdida de sal y la falta de magnesio. Asegúrate de mantenerte hidratado y beber mucha agua durante todo el día. También recuerde comer suficiente fibra de vegetales e incluso puede tomar una cucharadita o dos de fibra de cáscara de psyllium al día.',
	sideeffectsh12: 'Antojos de carbohidratos',
	sideeffectsp12:
		'El cuerpo todavía se está adaptando a la vida sin carbohidratos como el azúcar. Los antojos intensos de alimentos con almidón como el pan estarán presentes durante una semana más o menos.',
	sideeffectsh13: 'Diarrea',
	sideeffectsp13:
		'Esto suele sucederles a las personas que deciden imprudentemente limitar también su ingesta de grasas con una dieta baja en carbohidratos, lo que resulta en comer demasiadas proteínas.',
	sideeffectsh14: 'Calambres musculares',
	sideeffectsp14:
		'La pérdida de minerales, específicamente magnesio, es el contribuyente más probable a los calambres.',
	sideeffectsh15: 'Gimnasio y niveles de ejercicio',
	sideeffectsp15:
		'Al principio, es normal perder algo de fuerza y ​​resistencia. No se preocupe, tan pronto como su cuerpo se adapte a la cetosis, podrá utilizar completamente las grasas como su principal fuente de energía. Numerosos estudios demuestran que existen no hay una caída a largo plazo en el rendimiento de los atletas.',
	sideeffectsh16: 'Electrolitos',
	sideeffectsp16:
		'Dado que la cetosis tiene un efecto diurético en el cuerpo, la mayoría de las veces los efectos secundarios son el resultado de la eliminación de electrolitos. Asegúrese de beber mucha agua y mantenga alta su ingesta de sal / sodio. Debería volverse loco por la sal con todos sus alimentos. Esto ayuda con la retención de agua y ayuda a reponer los electrolitos.',
	sideeffectsh17: 'Conclusión',
	sideeffectsp17:
		'Estos efectos secundarios cetogénicos son en realidad solo problemas menores y ocurren solo durante un período de las primeras semanas. Mejorarán y finalmente desaparecerán, y luego experimentarás todos los beneficios de la dieta cetogénica.',
	mythsh0: 'El mito más gordo',
	mythsp0:
		'La mayoría de las personas que abandonan la dieta cetogénica fracasan porque no se sienten cómodas aumentando masivamente su ingesta de grasas, especialmente grasas saturadas.',
	mythsh1: 'Alto en carbohidratos + alto en grasas = malo',
	mythsp1:
		'Las dietas altas en carbohidratos aumentan los niveles de azúcar en sangre e insulina. Los altos niveles de azúcar e insulina se correlacionan con una alta inflamación. La mayoría de las dietas estadounidenses son ricas tanto en azúcar como en grasas saturadas. Cuando se estudiaron, estos dos factores se combinaron y no se estudiaron por separado.\n\nLa grasa saturada recibió la culpa de la inflamación que causa la enfermedad cardíaca porque se estudió en combinación con una dieta alta en carbohidratos. Las dietas cetogénicas, que son ricas en grasas saturadas y muy bajas en carbohidratos, minimizarán la inflamación.',
	mythsbq1:
		'Un estudio de John Hopkins confirmó que las grasas saturadas no son dañinas en el contexto de una dieta baja en carbohidratos y que la causa de la enfermedad cardíaca es un consumo crónicamente alto de carbohidratos, no un consumo alto de grasas saturadas y colesterol. En otras palabras, la grasa engorda cuando se combina con altos niveles de carbohidratos.',
	mythsh2: '¿Qué pasa con las proteínas?',
	mythsp2:
		'Es vital para la cetosis que sus niveles de proteínas no superen los de la grasa en calorías, no en peso. Esto se debe a que las proteínas en niveles excesivos actuarán de manera muy similar a los carbohidratos. Eventualmente se descomponen en glucosa, que luego aumenta el nivel de azúcar en la sangre y provoca un aumento de insulina.',
	mythsh3: '"La cetosis es peligrosa y nociva"',
	mythsp3:
		'La cetoacidosis, que es un estado metabólico beneficioso, a menudo se confunde con la cetoacidosis, que es algo completamente diferente y una condición médica grave. La cetoacidosis es causada por la incapacidad del cuerpo para regular adecuadamente la producción de cetonas. Es más común entre los alcohólicos y el tipo 1 diabéticos.',
	mythsh4: '"No se puede mantener"',
	mythsp4:
		'Seguir la dieta puede ser más fácil de lo que crees. Si comes mucho fuera, especialmente comida rápida, tendrás la tentación de la comodidad y es posible que tengas algunas dificultades. Lo mismo ocurre si mantienes una gran cantidad de almidón y azúcar alimentos en su despensa. Sin embargo, si se mantiene alejado y no se expone a estos alimentos, apenas lo notará. Los antojos desaparecerán después de la primera semana o dos, si no antes',
	mythsh5: '"No puedes obtener todas tus vitaminas"',
	mythsp5:
		'Uno obtendrá muchas más vitaminas y minerales con una dieta cetogénica adecuada que con una dieta típica. La principal excepción es la vitamina C, que solo se requiere en cantidades más que trazas si está siguiendo una dieta alta en carbohidratos. Sin embargo, las verduras de hoja verde serán más que suficiente vitamina C.',
	mythsh6: '"Keto le causará daño renal"',
	mythsp6:
		'Las personas con enfermedad renal muy avanzada tienen problemas para manejar grandes cantidades de proteína en la dieta. Sin embargo, no hay investigaciones en ningún lugar que indiquen que una dieta alta en proteínas cause enfermedad renal. Puede haber un mayor riesgo de cálculos renales debido al aumento de los niveles de ácido úrico en la sangre, pero eso se evita fácilmente bebiendo muchos líquidos y, si desea tener más cuidado, tomando suplementos de citrato de potasio.',
	mythsh7: '"No quiero morirme de hambre"',
	mythsp7:
		'La grasa y la proteína se digieren mucho más lentamente que los carbohidratos, por lo que notarás que pasas períodos de tiempo más largos sin tener hambre',
	mythsh8: '"Keto te da mal aliento"',
	mythsp8:
		'Cuando estás en cetosis, ciertamente es detectable en tu aliento. Lo que estás oliendo son cetonas, moléculas con olor afrutado convertidas de grasa para ser utilizadas como combustible por las células. Si estás en cetosis, en realidad es bastante ¡Es satisfactorio saber que en realidad estás exhalando calorías! Solo mastica un chicle sin azúcar si te molesta.',
	getstartedh0: 'Cómo empezar',
	getstartedp0:
		'En general, comer una gran cantidad de grasas, proteínas moderadas y cantidades limitadas de carbohidratos tendrá un impacto masivo en su salud: reducirá el colesterol, el peso corporal, el azúcar en la sangre y elevará sus niveles de energía y estado de ánimo.\n\nUna vez que esté en la mentalidad de comenzar con un enfoque más realista y saludable para perder grasa corporal, un estilo de vida bajo en carbohidratos no parece tan malo. Espere unas semanas para superar el problema inicial y esos molestos efectos secundarios desaparecerán.\n\nUn punto importante en una dieta cetogénica: prepárate para pasar más tiempo en la cocina. Un menú ceto implica cocinar y comer alimentos reales. Si aún no sabe cocinar, esta será una buena experiencia de aprendizaje para usted.\n\nDebe usar la sección de calculadora de esta aplicación para calcular sus macros específicas mencionadas a continuación',
	getstartedh1: '1. Reabastece tu cocina para que no haya tentaciones para ti.',
	getstartedh2:
		'2. Reduzca el consumo diario de carbohidratos a unos 20 g, sin embargo, cualquier valor inferior a 50 g es aceptable.',
	getstartedh3:
		'3. Aumenta considerablemente la proporción de grasas en tu dieta.',
	getstartedh4:
		'4. Lleve la ingesta de proteínas a 0,6 a 1 g por libra de masa corporal magra. (1,3 a 2,2 g por kg).',
	getstartedh5: '5. Aumente en gran medida la cantidad de agua que consume.',
	getstartedh6:
		'6. Aumentar la ingesta de electrolitos como sal, potasio y magnesio.',
	fiberh0: 'Fibra - ¡Consiga un poco!',
	fiberh1: 'Las fuentes cetogénicas de fibra incluyen:',
	fiberh2: 'Semilla de lino',
	fiberh3: 'Semillas de chia',
	fiberh4: 'Verduras cercanas a toda fibra',
	fiberh5: 'Suplementos de fibra',
	fiberh6: 'Más fibra que carbohidratos utilizables:',
	fiberh7: 'Salvado de trigo',
	fiberh8: 'Coco sin azúcar y harina de coco',
	fiberh9: 'Cereales ricos en fibra',
	fiberh10: 'Coles verdes',
	fiberh11: 'Aguacate, Hass',
	fiberh12: 'Espinacas y acelgas',
	fiberh13: 'Brócoli',
	fiberh14: 'Coliflor',
	fiberh15: 'Moras',
	fiberh16: 'Aproximadamente tantos carbohidratos utilizables como fibra:',
	fiberh17: 'Espárragos',
	fiberh18: 'Apio',
	fiberh19: 'Berenjena',
	fiberh20: 'Lechuga romana',
	fiberh21: 'Hongos',
	fiberh22: 'Rábanos',
	fiberh23: 'Frambuesas rojas',
	fiberh24:
		'Alto contenido de fibra, pero no tanta fibra como carbohidratos utilizables:',
	fiberh25: 'Salvado de arroz',
	fiberh26: 'Repollo',
	fiberh27: 'Pimientos',
	fiberh28: 'Snow Peas (vaina comestible)',
	fiberh29: 'Calabacín calabaza',
	fiberh30: 'Fresas',
	fiberp0:
		'La fibra es una forma de azúcar llamada celulosa. No tenemos las enzimas necesarias para descomponer la celulosa, por lo que no activa la glucólisis y no afectará los niveles de insulina. Los alimentos con alto contenido de fibra son seguros para comer con ceto y deben consumirse durante todo el día. Es importante tener en cuenta que la fibra NO cuenta para la ingesta de carbohidratos.\n\nPor ejemplo, si su comida tiene 10 g de carbohidratos en total y 5 g de fibra, la ingesta real de carbohidratos para la comida es de 5 g (10 g-5 g) . Aunque la fibra es un carbohidrato, no se digiere como un carbohidrato simple y, por lo tanto, no se incluye en su recuento diario de carbohidratos.',
	fiberp1:
		'Es importante enfatizar que la fibra no NEGA los carbohidratos, simplemente no se cuenta; ¡así que mezclar un puñado de harina de lino en un tazón de helado no funcionará!',
	fiberp2:
		'La mayoría de las personas obtienen su fibra de los granos y la eliminación de este grupo de alimentos para adoptar su dieta cetogénica puede resultar en una disminución considerable en su ingesta diaria de fibra.',
	fiberp3:
		'Las Guías Alimentarias para los Estadounidenses de 2010 recomiendan consumir entre 25 y 38 g de fibra al día, & nbsp; pero alguien con una dieta cetogénica puede necesitar más',
	fiberp4:
		'Casi no hay carbohidratos utilizables en las semillas de lino. Es muy rico en fibra soluble e insoluble (alrededor de un tercio de la fibra es soluble) y tiene una gran cantidad de nutrientes para empezar. La linaza es una de las mejores fuentes de fibra para implementar en su dieta.',
	fiberp5:
		'Tienen un perfil de fibra y carbohidratos similar a las semillas de lino.',
	fiberp6: 'Mostaza, Achicoria, Escarola',
	fiberp7: 'Como polvo de cáscara de psyllium',
	fiberp8:
		'½ taza cruda, 3 gramos de carbohidratos utilizables, 6 gramos de fibra',
	fiberp9: '1 onza, 2 gramos de carbohidratos utilizables, 5 gramos de fibra',
	fiberp10:
		'Revise las etiquetas cuidadosamente, pero algunos cereales ricos en fibra también son bajos o bastante bajos en carbohidratos. Ejemplos: todo el salvado con fibra extra; Fibra uno',
	fiberp11:
		'1 taza picada, cocida, 4 gramos de carbohidratos utilizables, 5 gramos de fibra',
	fiberp12:
		'1 aguacate mediano, 3 gramos de carbohidratos utilizables, 12 gramos de fibra',
	fiberp13:
		'Congelado 1 paquete de 10 oz, 10 g de carbohidratos totales, 6 g de fibra. 6 tazas de espinaca cruda o acelga = aproximadamente 1 taza cocida',
	fiberp14:
		'1/2 taza picado, cocido, 1 gramo de carbohidratos utilizables, 3 gramos de fibra. 1 taza picada, cruda, 4 gramos de carbohidratos utilizables, 2 gramos de fibra',
	fiberp15:
		'1/2 taza de trozos, cocidos, 1 gramo de carbohidratos utilizables, 2 gramos de fibra. 1 taza cruda, 2 gramos de carbohidratos utilizables, 2,5 gramos de fibra',
	fiberp16:
		'1 taza, cruda, 6 gramos de carbohidratos utilizables, 8 gramos de fibra',
	fiberp17:
		'1/2 taza de piezas, 2 gramos de carbohidratos utilizables, 2 gramos de fibra',
	fiberp18:
		'1 taza picada, 1,5 gramos de carbohidratos utilizables, 1,5 gramos de fibra',
	fiberp19:
		'1 taza cruda, en cubos, 2 gramos de fibra utilizable, 3 gramos de fibra. 1 taza en cubos, cocida, 5 gramos de carbohidratos utilizables, 3 gramos de fibra',
	fiberp20:
		'1 taza rallada, 0,5 gramos de carbohidratos utilizables, 1 g de fibra',
	fiberp21:
		'1 taza, rebanada, cruda, 1 gramo de carbohidratos utilizables, 1 gramo de fibra',
	fiberp22:
		'1 taza cruda, en rodajas, 2 gramos de carbohidratos utilizables, 2 gramos de fibra',
	fiberp23:
		'1 taza, cruda, 7 gramos de carbohidratos utilizables, 8 gramos de fibra',
	fiberp24: '1/4 taza 8 gramos de carbohidratos utilizables, 6 gramos de fibra',
	fiberp25:
		'1 taza cruda, picada, 3 gramos de carbohidratos utilizables, 2 gramos de fibra. 1/2 taza cocida, picada, 2 gramos de carbohidratos utilizables 1 gramo de fibra',
	fiberp26:
		'1 taza picada, cruda, 4 gramos de carbohidratos utilizables, 3 gramos de fibra',
	fiberp27:
		'1 taza entera, cruda, 3 gramos de carbohidratos utilizables, 2 gramos de fibra',
	fiberp28:
		'1 taza cocida, en rodajas, 4 gramos de carbohidratos utilizables, 3 gramos de fibra',
	fiberp29:
		'1/2 taza en rodajas, 5 gramos de carbohidratos utilizables, 2 gramos de fibra',
	tipsh1: 'Haz una barrida de carbohidratos',
	tipsp1:
		'Inspeccione los armarios de la cocina y el refrigerador, y elimine todos los alimentos ricos en carbohidratos. Esto evitará tentaciones más adelante en el camino.',
	tipsh2:
		'Hágase un chequeo médico y un análisis de sangre antes de comenzar la dieta.',
	tipsp2:
		'Planee tener otras cuatro a ocho semanas después de comenzar. De esta manera, puede realizar un seguimiento del efecto del cambio en sus hábitos alimenticios sobre el colesterol, la presión arterial y otros marcadores de salud.',
	tipsh3: 'Comprar Ketostix.',
	tipsp3:
		'Para comprobar si está dentro o fuera de cetosis. Ketostix está diseñado para medir la cantidad de cetonas en la orina. Recientemente, se ha puesto a disposición un medidor de cetonas en sangre para rastrear la cetosis en casa. Esta es una forma mucho más precisa de realizar un seguimiento de sus niveles de cetonas individuales.',
	tipsh4: 'Piense en sus comidas y cómo las planificará.',
	tipsp4:
		'Esto ayudará a comprar los alimentos correctos en el supermercado y brindará un marco para seguir cuando llegue la hora de la comida. Si sabe que se supone que debe comer salmón y brócoli para cenar esta noche, es mucho más fácil evitar elegir los viejos alimentos ricos en carbohidratos que solías comer.',
	tipsh5: 'Mantente hidratado.',
	tipsp5:
		'A medida que se reduce la ingesta de carbohidratos, los riñones comenzarán a eliminar el exceso de agua que el cuerpo ha estado reteniendo debido a una alta ingesta de carbohidratos. Asegúrese de beber suficiente agua para reemplazar lo que se pierde.',
	tipsh6: 'Piense en cualquier situación social que se encontrará.',
	tipsp6:
		'Idee maneras de manejar esos momentos en los que las tentaciones de comer a la manera "antigua" serán altas. No querrás quedarte ciego cuando alguien en la oficina trae una caja de tus chocolates favoritos y los pone justo debajo de tus narices. Asimismo, una cerveza con tus amigos suele convertirse en una cita con piel de patata y nachos. Piense en ensalada y bistec.',
	tipsh7: 'No te concentres en tu peso',
	tipsp7:
		'No se pese todos los días. Su peso puede variar entre 2 y 4 libras por día debido a los cambios en la ingesta y absorción de agua. No podrá realizar un seguimiento de la pérdida de grasa con precisión a diario, y las fluctuaciones te volverás loco si te concentras en ellos. En su lugar, pésate una vez a la semana o haz un seguimiento de las medidas de tu cuerpo para seguir tu progreso',
	makes: 'Hace',
	servings: 'Porciones',
	fat: 'Grasa',
	carbs: 'Carbohidratos',
	totalcarb: 'Carbohidratos totales',
	netcarb: 'Carbohidratos netos',
	protein: 'Proteína',
	calories: 'Calorías',
	zeroResults: '0 resultados',
	loadMore: 'Carga más',
	likes: 'Gustos',
	comments: 'Comentarios',
	netcarbFinder: 'Buscador neto de carbohidratos',
	search: 'Buscar',
	searchFilter: 'Filtro de búsqueda',
	barcode: 'Código de barras',
	offline: 'Desconectado',
	results: 'Resultados',
	barcodeNotRecognized: 'Código de barras no reconocido',
	barcodeNotRecognizedDetail:
		'Intente buscar en la base de datos o agregarlo como un alimento personalizado.',
	clearAllRecentlyScanned: '¿Borrar todos los escaneados recientemente?',
	clearAllConfirm:
		'Esto borrará sus códigos de barras escaneados recientemente en todos los dispositivos sincronizados',
	requestCamera: 'Solicitar permiso de cámara',
	noAccessCamera: 'Sin acceso a la cámara',
	scanBarcode: 'Escanear código de barras',
	itemFound: 'Elemento encontrado',
	itemNotFoundFS: 'Artículo que no se encuentra en Fat Secret',
	ItemNotFoundFSdesc:
		'Intente buscar en la base de datos o agregarlo como un alimento personalizado.',
	generic: 'Genérico',
	basedDiet: '% basado en una dieta de 2000 calorías',
	all: 'Todas',
	alcohol: 'Alcohol',
	beverages: 'Bebidas',
	cheeses: 'Quesos',
	dairy: 'Lechería',
	dressings: 'Apósitos (verifique las etiquetas)',
	fatsoils: 'Grasas y aceites',
	fish: 'Pez',
	flours: 'Harinas o Comidas',
	fowl: 'Ave',
	fruits: 'Frutas',
	grainsstarch: 'Granos y almidón',
	meats: 'Carnes',
	nutslegumes: 'Frutos secos y legumbres',
	seeds: 'Semillas',
	shellfish: 'Mariscos',
	sugarssweetener: 'Azúcares y edulcorantes',
	vegetables: 'Vegetales',

	calculatorDetails:
		'Necesitamos algunos detalles para formar su plan ceto personalizado',
	height: 'Altura',
	weight: 'Peso',
	age: 'Años',
	gender: 'Género',
	male: 'Masculino',
	female: 'Femenino',
	exerciseLevel: 'Nivel del ejericio',
	littleToNone: 'Poco a ninguno',
	exerciseOne: '1-3 veces / semana',
	exerciseTwo: '3-5 veces / semana',
	exerciseThree: '6-7 veces / semana',
	exerciseFour: '2 veces / día',
	goalWeight: 'Peso ideal',
	faster: 'Más rápido',
	slower: 'Más lento',
	weightPlan: 'Tu plan de peso',
	maintain: 'Mantener',
	easier: 'Más fácil',
	mild: 'Leve',
	moderate: 'Moderar',
	difficult: 'Difícil',
	customize: 'Personalizar',
	dailyCalories: 'Calorías diarias',
	week: 'Semana',
	lose: 'Perder',
	by: 'por',
	bodyFat: 'Grasa corporal',
	bodyFatDetails:
		'Su% de grasa corporal se utilizará para calcular la cantidad ideal de proteínas en función de su masa muscular total.',
	proteinDetail:
		'Demasiada proteína puede romper la cetosis, por lo que solo necesita lo suficiente para mantener el músculo y un poco más para desarrollarlo',
	dailyProtein: 'Proteína diaria',
	idealRange: 'Tu rango ideal es',
	maintainLower: 'mantener',
	build: 'construir',
	maintainMuscle: 'Mantener el músculo',
	buildMuscle: 'Trabajar el musculo',
	netcarbDescription:
		'Comer más de 50 g de carbohidratos netos al día es suficiente para romper la cetosis. La mayoría de las personas que hacen dieta cetogénica comen alrededor de 20 a 30 g. Cuantos menos carbohidratos coma, menos posibilidades tendrá de romper la cetosis.',
	language: 'Idioma',
	languageUpdated: 'Idioma actualizado',
	english: 'Inglés',
	french: 'Francés',
	spanish: 'Español',
	portuguese: 'Portugués',
	german: 'Alemán',
	logout: 'Cerrar sesión',
	loggedOut: 'Desconectado',
	unitsUpdated: 'Unidades actualizadas',
	featureRequest: 'Solicitud de función',
	deleteAccount: 'Borrar cuenta',
	verifyDeleteMessage:
		'Esto borrará su cuenta y todos los datos, ¿está seguro?',
	accountDeleted: 'Cuenta borrada',
	net: 'Red',
	total: 'Total',
	preferredCarb: 'Carbohidratos preferidos',
	preferredCarbDesc:
		'Los carbohidratos totales incluyen fibra y alcoholes de azúcar, mientras que los carbohidratos netos solo incluyen carbohidratos contables que afectan la cetosis. La mayoría de las personas que hacen dieta cetogénica solo controlan los carbohidratos netos.',
	updateMacroGoals: 'Actualizar objetivos macro',
	defaultMacroGoalsUpdated: 'Objetivos macro predeterminados actualizados',
	customGoalsApplied: 'Objetivos personalizados aplicados a fechas',
	tracking: 'Rastreo',
	recalculateMacroGoals: 'Volver a calcular los objetivos macroeconómicos',
	manuallyChangeMacroGoals: 'Cambiar manualmente los objetivos macro',
	changePreferences: 'Cambiar preferencias',
	otherTracking: 'Otro seguimiento',
	other: 'Otro',
	notes: 'Notas',
	invalidCode: 'Codigo invalido',
	purchaseRestored: 'Compra restaurada',
	userNotFound: 'Usuario no encontrado',
	error: 'Error',
	errorProducts:
		'Hubo un error al intentar cargar productos. Asegúrese de tener una conexión a Internet válida.',
	trackerEnabledAdsRemoved: 'Rastreador habilitado, anuncios eliminados',
	purchaseTracking: 'Seguimiento de compras',
	gettingProductDetails: 'Obteniendo detalles del producto ...',
	noPurchaseFound: 'No se ha encontrado ninguna compra',
	noPurchaseFoundMore:
		'No se encontró ninguna compra en esta cuenta. Envíenos un correo electrónico si cree que es incorrecto.',
	account: 'Cuenta',
	signIn: 'Registrarse',
	logIn: 'Iniciar sesión',
	signedIn: 'Registrado',
	accountCreated: 'Cuenta creada',
	signUp: 'Regístrate',
	email: 'Email',
	password: 'Contraseña',
	forgotPassword: '¿Se te olvidó tu contraseña?',
	resetEmailSent: 'Restablecer correo electrónico enviado',
	newUser: '¿Nuevo Usuario?',
	emailError: 'Introduzca un correo electrónico válido',
	passwordError: 'Ingrese una contraseña',
	emailSubscribe: 'Obtenga actualizaciones y ofertas especiales de MyKeto',
	enterYourEmail: 'Introduce tu correo electrónico',
	medicalDisclaimer: 'Descargo de responsabilidad médica',
	disclaim:
		'Exención de responsabilidad médica: MyKeto se ofrece solo con fines educativos y de entretenimiento, y de ninguna manera tiene la intención de diagnosticar, curar o tratar ninguna condición médica o de otro tipo. Siempre busque el consejo de su médico u otro proveedor de salud calificado antes de cambiar su dieta o plan de ejercicios y pregúntele a su médico cualquier pregunta que pueda tener con respecto a una condición médica. Aunque hacemos todo lo posible para verificar la exactitud de la información aquí contenida, no podemos garantizar su exactitud.',
	skipAccount: 'Saltar cuenta',
	registerAccount: 'Registrar Cuenta',
	convertAccount: 'Convertir cuenta en inicio de sesión',
	createAccountLater:
		'Puede crear una cuenta más tarde con sus datos yendo al menú Configuración',
	whyLogin: '¿Por qué iniciar sesión?',
	usingAccount:
		'El uso de una cuenta realizará una copia de seguridad y sincronizará sus datos en todos sus otros dispositivos. Solo toma un segundo registrarse y no compartimos ni almacenamos ningún dato personal',
	totalcarbError: 'El total no puede ser menor que la fibra',
	auto: 'Auto',
	manual: 'Manual',
	name: 'Nombre',
	editingItem: 'Editando elemento',
	foods: 'Alimentos',
	meals: 'Comidas',
	meal: 'Comida',
	favorites: 'Favoritos',
	recent: 'Recientes',
	recentlyused: 'Recientemente usado',
	saveToFoods: 'Guardar en alimentos',
	searched: 'Buscado',
	myfoods: 'Mis Alimentos',
	mymeals: 'Mis comidas',
	quickmacro: 'Macros rápidas',
	create: 'Crear',
	created: 'Creado',
	createFood: 'Crear comida',
	createMeal: 'Crear comida',
	ingredientNotFound: 'Ingrediente no encontrado, intente agregarlo nuevamente',
	totalServings: 'Porciones totales',
	totalServingsDesc: 'Introduzca un número para el total de porciones',
	clearAllFavorites: '¿Borrar todos los favoritos?',
	clearAllFavoritesDesc:
		'Esto borrará todos tus favoritos en todos los dispositivos sincronizados',
	clearAllRecentlyUsed: '¿Borrar todos los elementos usados ​​recientemente?',
	clearAllRecentlyUsedConfirm:
		'Esto borrará los elementos registrados recientemente en todos los dispositivos sincronizados',
	removedFromRecentlyUsed: 'Eliminado de Usado recientemente',
	itemsAdded: 'Artículos agregados',
	mealName: 'Nombre de la comida',
	servingName: 'Nombre de servicio',
	wholeRecipe: 'Receta completa',
	clickStart:
		'Ya está todo listo, haga clic en el botón de abajo para comenzar',
	privacyPolicy: 'Política de privacidad',
	termsConditions: 'Términos y Condiciones',
	policyAgree: 'Al hacer clic a continuación, acepta nuestro',
	unitsOnboard:
		'Elija sus unidades preferidas para las medidas corporales (peso, tamaño del pecho, etc.) y agua',
	clockOnboard:
		'Elija cómo desea que se formatee la visualización del temporizador de inicio y finalización',
	scheduleOnboard:
		'Elija si desea recibir notificaciones cada vez que cambie de ventana y si desea un recordatorio antes de que finalice el período.',
	manualOnboardSettings:
		'Elija su objetivo rápido predeterminado (puede cambiar esto rápidamente en la página de inicio) y si desea elegir su objetivo en horas totales o si desea seleccionar una fecha y hora para su objetivo.',
	manualOnboardNotifications:
		'Elija si desea recibir notificaciones, como un recordatorio específico para ayunar todos los días a una hora determinada, y recordatorios cuando el ayuno ha terminado o está a punto de terminar pronto.',
	weightLoss: 'Pérdida de peso',
	diseasePrevention: 'La prevención de enfermedades',
	lowerBloodPressure: 'Presión sanguínea baja',
	improvedCholesterolProfile: 'Perfil de colesterol mejorado',
	antiAging: 'Antienvejecimiento y longevidad',
	insulinManagement: 'Manejo de la insulina y la diabetes',
	antiCancer: 'Anticancerígeno',
	heartHealth: 'La salud del corazón',
	improvedSkin: 'Piel mejorada',
	dentalHealth: 'Salud dental',
	whyKeto: '¿Por qué te interesa la dieta cetogénica?',
	betterTailor: 'Adaptaremos mejor MyKeto para que lo ayude con sus objetivos',
	goals: 'Metas',
	goalsExplanation:
		'Usaremos esta información para ayudarlo a predecir su progreso',
	finished: 'Terminado',
	startTracking: 'Iniciar seguimiento',
	weightUnits: 'Peso y medidas corporales',
	units: 'Unidades',
	metric: 'Métrico',
	imperial: 'Imperial',
	ftcm: 'pies, libras',
	bodyMeasurements: 'Mediciones',
	bmi: 'IMC',
	leanBodyMass: 'Masa corporal magra',
	waistSize: 'Tamaño de la cintura',
	hipSize: 'Tamaño de la cadera',
	chestSize: 'Tamaño del pecho',
	neckSize: 'Tamaño de cuello',
	armSize: 'Tamaño del brazo',
	forearmSize: 'Tamaño del antebrazo',
	calfSize: 'Tamaño de la pantorrilla',
	thighSize: 'Tamaño del muslo',
	enterNumber: 'Introduzca un número superior a cero.',
	vitals: 'Partes vitales',
	water: 'Agua',
	bloodGlucose: 'Glucosa en sangre',
	exercise: 'Ejercicio',
	bloodPressure: 'Presión arterial',
	heartRate: 'Ritmo cardiaco',
	ketones: 'Cetonas',
	ketoneLevels: 'Niveles de cetonas',
	potassium: 'Potasio',
	sodium: 'Sodio',
	magnesium: 'Magnesio',
	systolic: 'Sistólico',
	diastolic: 'Diastólico',
	breakfast: 'Desayuno',
	lunch: 'Almuerzo',
	dinner: 'Cena',
	snacks: 'Aperitivos',
	quickAdd: 'Adición rápida',
	quickAddMacros: 'Agregar macros rápido',
	quickAddToMeal: 'Quick Add To Meal',
	copyTo: 'Copiar a',
	saveAsMeal: 'Guardar como comida',
	addEntry: 'Añadir entrada',
	caloriesFromMacros: 'Calorías de macros',
	eaten: 'Comido',
	remaining: 'Restante',
	macroGoalsEaten: 'Macro objetivos comidos',
	per: 'Por',
	syncExercise: 'Ejercicio de sincronización',
	estimateCalories: 'Calorías estimadas',
	selectTime: 'Seleccionar hora',
	duration: 'Duración',
	caloriesBurned: 'Calorías quemadas',
	addExerciseCal: 'Agregar calibración de ejercicio',
	custom: 'Personalizado',
	oneWeek: '1 semana',
	oneMonth: '1 mes',
	threeMonths: '3 meses',
	sixMonths: '6 meses',
	oneYear: '1 año',
	allTime: 'Todo el tiempo',
	to: 'A',
	addTwoEntries: 'Agregar 2 o más entradas',
	netChange: 'Cambio neto',
	noEntriesFound: 'Entradas no encontradas',
	addFoodEntries: 'Agregue entradas de alimentos a su registro para ver datos',
	fitbitDesc:
		'Envía tus datos de macro, agua y peso de MyKeto a Fitbit. Envía tu actividad de ejercicio Fitbit a MyKeto',
	fitbitConnected: 'Fitbit Syncing Connected',
	fitbitRemove: '¿Quieres eliminar el acceso a Fitbit de este dispositivo?',
	fitbitDisconnected: 'Fitbit desconectado',
	healthkit1:
		'Para actualizar sus permisos para Apple Healthkit, abra la aplicación del kit de salud en su teléfono, toque las fuentes y seleccione MyKeto de la lista',
	healthkit2:
		'Envíe sus datos de peso, agua y macros de MyKeto a Apple HealthKit',
	friendRequest: 'Solicitud de amistad',
	tryAddFriend:
		'Intente agregar un amigo por correo electrónico con el botón (+). Una vez aceptado, pueden copiar los alimentos y comidas guardados de los demás',
	alreadyCopied: 'Ya copiado',
	sent: 'Expedido',
	received: 'Recibido',
	alreadyInvited: 'Ya invitado',
	alreadyPendingInvite: 'Este usuario ya tiene una invitación pendiente',
	alreadyFriended: 'Ya es amigo',
	alreadyYourFriend: 'Este usuario ya es tu amigo',
	pendingInvite: 'Invitación pendiente',
	pendingAlready: 'Este usuario ya te envió una invitación',
	friendRequestSent: 'Solicitud de amistad enviada',
	invalidEmail: 'Email inválido',
	cantFriendYourself: 'No puedes hacerte amigo de ti mismo',
	friendRequestCanceled: 'Solicitud de amistad cancelada',
	friendRequestAccepted: 'Solicitud de amistad aceptada',
	friendRequestDeclined: 'Solicitud de amistad rechazada',
	unfriended: 'Hostil',
	allFoodsCopied: 'Todos los alimentos copiados',
	exerciseLabel1: 'Danza aeróbica (informal)',
	exerciseLabel2: 'Danza aeróbica (moderada)',
	exerciseLabel3: 'Danza aeróbica (intensa)',
	exerciseLabel4: 'Baloncesto (cancha completa)',
	exerciseLabel5: 'Baloncesto (Media cancha)',
	exerciseLabel6: 'Calistenia',
	exerciseLabel7: 'Ciclismo (5,5 mph, 9 kmph)',
	exerciseLabel8: 'Ciclismo (10 mph, 16 km / h)',
	exerciseLabel9: 'Ciclismo (13 mph, 21 kmph)',
	exerciseLabel10: 'Bailando (Casual)',
	exerciseLabel12: 'Bailando (Moderado)',
	exerciseLabel13: 'Dancing (Intense)',
	exerciseLabel14: 'Deportes de campo',
	exerciseLabel15: 'Frisbie',
	exerciseLabel16: 'Golf (caminando)',
	exerciseLabel17: 'Golf (con carro)',
	exerciseLabel18: 'Gimnasia',
	exerciseLabel19: 'Tareas del hogar',
	exerciseLabel20: 'Saltar la cuerda',
	exerciseLabel21: 'Artes marciales',
	exerciseLabel22: 'Racquetball',
	exerciseLabel23: 'Patinaje sobre ruedas',
	exerciseLabel24: 'Máquina de remo (intensa)',
	exerciseLabel25: 'Máquina de remo (moderada)',
	exerciseLabel26: 'Corriendo (4 mph, 6.5 kmph)',
	exerciseLabel27: 'Corriendo (5 mph, 8 kmph)',
	exerciseLabel28: 'Corriendo (6 mph, 9,7 km / h)',
	exerciseLabel29: 'Corriendo (7 mph, 11,3 km / h)',
	exerciseLabel30: 'Corriendo (8 mph, 13 kmph)',
	exerciseLabel31: 'Corriendo (10 mph, 16 kmph)',
	exerciseLabel32: 'Corriendo (12 mph, 19,3 km / h)',
	exerciseLabel33: 'Sofbol',
	exerciseLabel34: 'Escalón de escalera (6 ", 15,2 cm)',
	exerciseLabel35: 'Escalón de escalera (8 ", 20,3 cm)',
	exerciseLabel36: 'Escalón de escalera (10 ", 25,4 cm)',
	exerciseLabel37: 'Escalón de escalera (12 ", 30,5 cm)',
	exerciseLabel38: 'Natación (Casual)',
	exerciseLabel39: 'Natación (intensa)',
	exerciseLabel40: 'Tenis',
	exerciseLabel41: 'Vóleibol',
	exerciseLabel42: 'Caminando (2 mph, 3.2 kmph)',
	exerciseLabel43: 'Caminando (3 mph, 4.8 kmph)',
	exerciseLabel44: 'Caminando (4 mph, 6.5 kmph)',
	exerciseLabel45: 'Entrenamiento con pesas (intenso)',
	exerciseLabel46: 'Entrenamiento con pesas (normal)',
	exerciseLabel47: 'Trabajar en el jardín'
};
