//@flow
export const validateAll = (values: Array<mixed>) => {
	let noErrors = true;
	values.forEach((item) => {
		if (!item || isNaN(item) || item < 0) {
			noErrors = false;
		}
	});

	return noErrors;
};

export const validateNumber = (value) => {
	let isValid = true;
	if (!value || isNaN(value) || value < 0) {
		isValid = false;
	}

	return isValid;
};

export const isValidTotalCarb = (value: number, fib: number) => {
	if (!value || isNaN(value) || +value < 0) {
		return false;
	}
	if (+fib > +value) {
		return false;
	} else {
		return true;
	}
};

export const isValidString = (value: string) => {
	if (!value || value.trim().length === 0) {
		return false;
	} else {
		return true;
	}
};
