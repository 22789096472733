//@flow
import React from 'react';
import { ScrollView, Linking } from 'react-native';
import { Paragraph, Heading, Divider, Text, BlockQuote } from '../common';
import styles from './Styles/Styles';
import { i18n } from '../../constants';

export const SideEffects = ({ theme }) => {
	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffects')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp1')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh2')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp2')}</Paragraph>
			<BlockQuote
				ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}
				style={{ paddingTop: 0, alignItems: 'center' }}>
				<Text
					style={styles.linkText}
					onPress={() =>
						Linking.openURL(
							'https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/dash-diet/art-20048456'
						)
					}>
					{'\n'}Source
				</Text>
				<Text
					style={styles.linkText}
					onPress={() =>
						Linking.openURL(
							'https://www.nhlbi.nih.gov/health-topics/dash-eating-plan'
						)
					}>
					{'\n'}Source 2
				</Text>
				<Text
					style={styles.linkText}
					onPress={() =>
						Linking.openURL(
							'https://www.webmd.com/hypertension-high-blood-pressure/guide/dash-diet#1'
						)
					}>
					{'\n'}Source 3
				</Text>
			</BlockQuote>
		</ScrollView>
	);
};
