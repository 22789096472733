import {
	REQUEST_EDAMAM_RECIPES,
	RECEIVE_EDAMAM_RECIPES,
	RECEIVE_LOAD_MORE_EDAMAM_RECIPES,
	CLEAR_EDAMAM_RECIPES,
	REQUEST_EDAMAM_SINGLE,
	RECEIVE_EDAMAM_SINGLE,
	UPDATE_RECIPE_FILTER
} from './types';

const REQUEST_URL = 'https://api.edamam.com/search';
const APP_ID = '30744e15';
const APP_KEY = '1796c8f4a6baa1a86e85647f24eeae6e';

export const requestEdamamRecipes = (searchedText) => {
	return {
		type: REQUEST_EDAMAM_RECIPES,
		payload: searchedText
	};
};

export const receiveEdamamRecipes = (response) => {
	return {
		type: RECEIVE_EDAMAM_RECIPES,
		payload: response
	};
};

export const requestEdamamSingleRecipe = (response) => {
	return {
		type: REQUEST_EDAMAM_SINGLE,
		payload: response
	};
};

export const receiveEdamamSingleRecipe = (response) => {
	return {
		type: RECEIVE_EDAMAM_SINGLE,
		payload: response
	};
};

export const receiveLoadMoreEdamamRecipes = (response) => {
	return {
		type: RECEIVE_LOAD_MORE_EDAMAM_RECIPES,
		payload: response
	};
};

export const clearEdamamRecipes = () => {
	return {
		type: CLEAR_EDAMAM_RECIPES
	};
};

//Special EncodeURIComponent() for the FatSecret API Search Text
const fixedEncodeURIComponent = (str) => {
	return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
		return '%' + c.charCodeAt(0).toString(16);
	});
};

export const fetchEdamamRecipes = (
	searchedText,
	mealType,
	cuisineType,
	startCount = 0,
	endCount = 50,
	loadMore = false
) => {
	const SEARCH_EXPRESSION = fixedEncodeURIComponent(searchedText);
	const START_COUNT = startCount;
	const END_COUNT = endCount;
	// Create a Signature Base String
	let PARAMETERS = 'q=' + SEARCH_EXPRESSION;
	PARAMETERS += '&app_id=' + APP_ID;
	PARAMETERS += '&app_key=' + APP_KEY;
	PARAMETERS += '&from=' + START_COUNT;
	PARAMETERS += '&to=' + END_COUNT;
	PARAMETERS += '&diet=low-sodium';

	if (mealType.key != 'All') {
		PARAMETERS += `&mealType=${mealType.key}`;
	}

	if (cuisineType.key != 'All') {
		PARAMETERS += `&cuisineType=${cuisineType.key}`;
	}

	const URL = REQUEST_URL + '?' + PARAMETERS;
	return (dispatch) => {
		dispatch(requestEdamamRecipes(searchedText));
		return fetch(URL)
			.then((response) => response.json())
			.then((json) =>
				dispatch(
					loadMore
						? receiveLoadMoreEdamamRecipes(json)
						: receiveEdamamRecipes(json)
				)
			)
			.catch((error) => {
				console.warn(error);
			});
	};
};

export const fetchEdamamSingleRecipe = (recipeID) => {
	const SEARCH_EXPRESSION = fixedEncodeURIComponent(recipeID);
	// Create a Signature Base String
	let PARAMETERS = 'r=' + SEARCH_EXPRESSION;
	PARAMETERS += '&app_id=' + APP_ID;
	PARAMETERS += '&app_key=' + APP_KEY;
	const URL = REQUEST_URL + '?' + PARAMETERS;
	return (dispatch) => {
		dispatch(requestEdamamSingleRecipe());
		return fetch(URL)
			.then((response) => response.json())
			.then((json) => dispatch(receiveEdamamSingleRecipe(json)))
			.catch((error) => {
				console.warn(error);
			});
	};
};

export const loadMoreRecipes = () => (dispatch, getState) => {
	const state = getState();
	const searchText = state.edamamRecipeApi.loadMoreSearchText;
	const endCount = +state.edamamRecipeApi.to;
	const { mealType, cuisineType } = state.edamamRecipeApi.filter;

	dispatch(
		fetchEdamamRecipes(
			searchText,
			mealType,
			cuisineType,
			endCount,
			endCount + 50,
			true
		)
	);
};

export const updateRecipeFilter = (mealType, cuisineType) => {
	return {
		type: UPDATE_RECIPE_FILTER,
		mealType,
		cuisineType
	};
};
