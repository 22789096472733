import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
	container: {
		backgroundColor: '#FFF'
	},
	linkText: {
		color: '#008989',
		paddingLeft: 10
	},
	imageStyle: {
		alignSelf: 'center',
		width: width,
		height: width / 2,
		paddingHorizontal: 10
	}
});
