//@flow
import React from 'react';
import { ScrollView, Image } from 'react-native';
import { Heading, Divider } from '../common';
import styles from './Styles/Styles';
import { i18n, Images } from '../../constants';

export const GetStarted = ({ theme }) => {
	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('getstartedh1')}
			</Heading>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('getstartedh2')}
			</Heading>
			<Image
				resizeMode="contain"
				style={styles.imageStyle}
				source={Images.caloricneeds}
			/>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('getstartedh3')}
			</Heading>
			<Image
				resizeMode="contain"
				style={styles.imageStyle}
				source={Images.foodchart}
			/>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('getstartedh4')}
			</Heading>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('getstartedh5')}
			</Heading>
			<Divider />
		</ScrollView>
	);
};
