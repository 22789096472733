import { registerRootComponent } from 'expo';
import App from './App';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';

// For Firebase WEB JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyCRv4-31g6gnIykXArkXGysNgp_QIwgZ7M',
	authDomain: 'mydashdiet.firebaseapp.com',
	databaseURL: 'https://mydashdiet-default-rtdb.firebaseio.com',
	projectId: 'mydashdiet',
	storageBucket: 'mydashdiet.appspot.com',
	messagingSenderId: '301784215346',
	appId: '1:301784215346:web:d3a81cb526af505166712b',
	measurementId: 'G-WRNHJKQYWE'
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
