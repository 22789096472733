//@flow
import Toast from 'react-native-root-toast';
import { Platform } from 'react-native';
import { randomString } from 'Utilities';
import { i18n } from 'Theme';
import { WebCompatAlert } from 'Components/common';
import * as NavigationService from 'Navigation/NavigationService';
import { toggleModal } from './';
import { ahk_updateMacros } from 'Components/tracker/fitnesstrackers/utilities/AppleHealthKit';
import {
	fitbit_saveMacros,
	fitbit_deleteMacros
} from 'Components/tracker/fitnesstrackers/utilities/Fitbit';

import {
	getSpecificTrackerData,
	getSpecificDateTotals
} from 'Components/tracker/log/selectors';
import {
	ADDED_MACROS_TO_LOG,
	DELETED_MACROS_FROM_LOG
} from 'Components/tracker/log/actions/types';
import { incrementTrackerInterAdCount } from 'Redux/actions';
//----------------------------------------------------Log Home Actions --------------------------------------------------------------------------------------

//Save Updated to Clicking On A Log Item Which Brings up th Edit Item Modal (When no saved food ID found, such as a type: macro or a deleted saved meal/ custom food)
export const submitEditLogItem = (values, originalItem) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { editMealTime, date } = state.log;

	firebase
		.update(
			`userdata/${uid}/trackerdate/${date}/${editMealTime}/${originalItem.random}`,
			{
				name: values.name,
				calories: values.calories,
				fats: values.fats,
				proteins: values.proteins,
				carbs: values.carbs,
				totalcarbs: values.totalcarbs,
				fiber: values.fiber,
				sodium: values.sodium,
				nutrientsV2: values.nutrientsV2 ? values.nutrientsV2 : {},
				manualCalories: values.manualCalories,
				id: originalItem.id ? originalItem.id : null,
				type: originalItem.type,
				random: originalItem.random
			}
		)
		.then(() => {
			dispatch(toggleModal('editLogItemModal', false));
			dispatch({ type: ADDED_MACROS_TO_LOG, payload: editMealTime });
			return Toast.show(`${values.name} ${i18n.t('updated')}`, {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

//Save Updated Entry when there is a Food Id found
export const updateLogEntry = (item, date, mealTime, random) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/trackerdate/`);
	let updates = {};
	updates[`${date}/${mealTime}/${random}`] = {
		name: item.servingAmount,
		calories: +item.calories,
		fats: +item.fats,
		carbs: +item.carbs,
		proteins: +item.proteins,
		totalcarbs: +item.totalcarbs,
		fiber: +item.fiber,
		sodium: +item.sodium,
		nutrientsV2: item.nutrientsV2 ? item.nutrientsV2 : {},
		random,
		id: item.id,
		type: item.type
	};
	ref.update(updates).then(() => {
		dispatch({ type: ADDED_MACROS_TO_LOG, payload: mealTime });
		NavigationService.goBack();
		return Toast.show(`${item.servingAmount} ${i18n.t('updated')}`, {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
};

//Quick Delete By Pressing the "X"
export const quickDelete = (item, mealTime) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { date } = state.log;

	firebase
		.remove(`userdata/${uid}/trackerdate/${date}/${mealTime}/${item.random}`)
		.then(() => {
			dispatch({ type: DELETED_MACROS_FROM_LOG, payload: mealTime });
			Toast.show(`${item.name} ${i18n.t('deleted')}`, {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

//..........................Ellipsis Action Menu..............................................//

//Saves the Meal from the Home Page Meal Section "..." options list
export const saveAsMeal = (createMealName: string) => (
	dispatch,
	getState,
	getFirebase
) => {
	if (createMealName === '' || createMealName.trim().length === 0) {
		return WebCompatAlert(i18n.t('nameEmpty'), i18n.t('pleaseEnterName'));
	}
	dispatch(toggleModal('saveMealModal', false));
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const foodID = randomString(14);
	firebase
		.update(`userdata/${uid}/savedmealsv3/${foodID}`, {
			name: createMealName.trim(),
			foods: state.log.copyItems,
			id: foodID,
			type: 'meal'
		})
		.then(() => {
			// Add a Toast on screen.
			return Toast.show(i18n.t('mealSaved'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

//Copy the Meal from the Home Page Meal Section "..." options list
export const copyMeal = () => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { premium } = state.firebase.profile;
	const { copyDate, copyTime, copyItems } = state.log;
	const { ahkWasAuth } = state.fitnessTracker.appleHealthKit; // for ios apple health
	const { auth: fitbitAuth } = state.fitnessTracker.fitbit; // for  Fitbit
	let counter = copyItems.length;
	const ref = firebase
		.database()
		.ref(`userdata/${uid}/trackerdate/${copyDate}/${copyTime}/`);
	let updates = {};

	for (let i = 0; i < copyItems.length; i++) {
		const uniqid = randomString(14);
		updates[`${uniqid}`] = {
			name: copyItems[i].name,
			calories: copyItems[i].calories,
			fats: copyItems[i].fats,
			proteins: copyItems[i].proteins,
			carbs: copyItems[i].carbs,
			totalcarbs: copyItems[i].totalcarbs,
			fiber: copyItems[i].fiber,
			sodium: copyItems[i].sodium,
			nutrientsV2: copyItems[i].nutrientsV2 ? copyItems[i].nutrientsV2 : {},
			manualCalories: copyItems[i].manualCalories,
			random: uniqid,
			id: copyItems[i].id ? copyItems[i].id : null,
			type: copyItems[i].type
		};

		counter -= 1;
		if (counter === 0) {
			//Save to User's Firebase Database
			ref.update(updates).then(() => {
				if (Platform.OS === 'ios' && ahkWasAuth) {
					//if IOS sync with apple HK if previously authorized
					const macroObject = getSpecificDateTotals(getState(), {
						date: copyDate
					});
					dispatch(ahk_updateMacros(copyDate, macroObject.macrosEaten));
				}

				if (fitbitAuth.isAuth) {
					const macroObject = getSpecificDateTotals(getState(), {
						date: copyDate
					});
					const copyDateData = getSpecificTrackerData(getState(), {
						date: copyDate
					});
					if (typeof copyDateData.fitbit[copyTime] === 'undefined') {
						dispatch(
							fitbit_saveMacros(
								fitbitAuth.access_token,
								copyDate,
								macroObject[copyTime],
								copyTime
							)
						);
					} else if (typeof copyDateData.fitbit[copyTime] !== 'undefined') {
						fitbit_deleteMacros(
							fitbitAuth.access_token,
							copyDateData.fitbit[copyTime].toString()
						);
						dispatch(
							fitbit_saveMacros(
								fitbitAuth.access_token,
								copyDate,
								macroObject[copyTime],
								copyTime
							)
						);
					}
				}
			});
		}
	}
	if (!premium)
		setTimeout(() => dispatch(incrementTrackerInterAdCount()), 1500);
	dispatch(toggleModal('copyMealModal', false));
	// Add a Toast on screen.
	return Toast.show(i18n.t('mealCopied'), {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});
};

//Adds Quick Macros from the Modal via the Log Page Meal Section "..." options list
type quickMacroProps = {
	name: string,
	calories: number,
	fats: number,
	proteins: number,
	carbs: number,
	totalcarbs: number,
	fiber: number,
	manualCalories: number,
	nutrientsV2: Array<Object>
};

export const submitQuickMacros = (values: quickMacroProps) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { premium } = state.firebase.profile;
	const { date, copyTime } = state.log;
	const uniqid = randomString(14);
	firebase
		.set(`userdata/${uid}/trackerdate/${date}/${copyTime}/${uniqid}`, {
			name: values.name,
			calories: values.calories,
			fats: values.fats,
			proteins: values.proteins,
			carbs: values.carbs,
			totalcarbs: values.totalcarbs,
			sodium: values.sodium,
			fiber: values.fiber,
			manualCalories: values.manualCalories,
			nutrientsV2: values.nutrientsV2,
			random: uniqid,
			type: 'macro'
		})
		.then(() => {
			dispatch({ type: ADDED_MACROS_TO_LOG, payload: copyTime });
			if (!premium)
				setTimeout(() => dispatch(incrementTrackerInterAdCount()), 1500);
		});

	Toast.show(`${values.name} ${i18n.t('added')}`, {
		duration: Toast.durations.LONG,
		position: Toast.positions.BOTTOM,
		shadow: true,
		animation: true
	});

	return dispatch(toggleModal('quickAddModal', false));
};
