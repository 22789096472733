//@flow
import React, { useState, useEffect, useRef } from 'react';
import { View, Platform, SafeAreaView, StyleSheet } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import NavigationBar from 'react-native-navbar';
import { validateNumber } from 'Utilities';
import { i18n } from 'Theme';
import { Gram, HeaderTextButton, RenderInputClass } from 'Components/common';
import { updateManualGoals } from './actions';
import { PreviousNextView } from 'react-native-keyboard-manager';
import _ from 'lodash';

type Props = {
	onClosed?: Function,
	theme: Object
};

export const GoalsUpdaterManual = (props: Props) => {
	const {
		goals: { cal, sodium },
		carbgrams,
		fatgrams,
		proteingrams
	} = useSelector((state) => state.firebase.profile.settings);
	const [percents, changePercents] = useState([0, 0, 0]);
	const [calories, updateCalories] = useState(cal);
	const dispatch = useDispatch();
	const { control, handleSubmit, errors, watch, setValue } = useForm();

	const fatInput = useRef(null);
	const proteinInput = useRef(null);
	const carbInput = useRef(null);
	const sodiumInput = useRef(null);

	const { onClosed, theme } = props;

	const { fats, carbs, proteins } = watch();

	useEffect(() => {
		const autoCal = +fats * 9 + +carbs * 4 + +proteins * 4;
		const validateCal = _.isEmpty(errors) ? autoCal : 0;

		changePercents(
			_.isEmpty(errors)
				? [
						((+fats * 9) / +validateCal) * 100,
						((+proteins * 4) / +validateCal) * 100,
						((+carbs * 4) / +validateCal) * 100
				  ]
				: [0, 0, 0]
		);
		updateCalories(validateCal);
	}, [fats, proteins, carbs, errors]);

	const renderForm = () => {
		return (
			<View style={[styles.inputsContainer, { backgroundColor: theme.baseBG }]}>
				<RenderInputClass
					onChange={() => null}
					value={calories}
					labelText={i18n.t('calories')}
					editable={false}
					gram={true}
					gramText={'kCal'}
					containerStyle={{ borderColor: theme.border }}
					style={{
						color: theme.darkFont
					}}
				/>
				<Controller
					control={control}
					render={({ onChange, value }) => (
						<RenderInputClass
							onChange={onChange}
							value={value}
							isError={errors.fats}
							customComponent={
								<Gram customText={`(${percents[0].toFixed(0)}%)`} />
							}
							labelText={i18n.t('fat')}
							refInput={fatInput}
							containerStyle={{ borderColor: theme.border }}
							onSubmitEditing={() => {
								proteinInput.current.focus();
							}}
							style={{
								color: theme.themeAccent,
								borderColor: theme.border
							}}
						/>
					)}
					name="fats"
					rules={{
						required: true,
						validate: (value) => validateNumber(value)
					}}
					defaultValue={fatgrams}
				/>
				<Controller
					control={control}
					render={({ onChange, value }) => (
						<RenderInputClass
							onChange={onChange}
							value={value}
							isError={errors.proteins}
							labelText={i18n.t('protein')}
							customComponent={
								<Gram customText={`(${percents[1].toFixed(0)}%)`} />
							}
							refInput={proteinInput}
							containerStyle={{ borderColor: theme.border }}
							onSubmitEditing={() => {
								carbInput.current.focus();
							}}
							style={{
								color: theme.themeAccent,
								borderColor: theme.border
							}}
						/>
					)}
					name="proteins"
					rules={{
						required: true,
						validate: (value) => validateNumber(value)
					}}
					defaultValue={proteingrams}
				/>
				<Controller
					control={control}
					render={({ onChange, value }) => (
						<RenderInputClass
							onChange={onChange}
							value={value}
							isError={errors.carbs}
							labelText={i18n.t('carbs')}
							customComponent={
								<Gram customText={`(${percents[2].toFixed(0)}%)`} />
							}
							refInput={carbInput}
							containerStyle={{ borderColor: theme.border }}
							onSubmitEditing={() => {
								//fiberInput.current.focus();
							}}
							style={{
								color: theme.themeAccent,
								borderColor: theme.border
							}}
						/>
					)}
					name="carbs"
					rules={{
						required: true,
						validate: (value) => validateNumber(value)
					}}
					defaultValue={carbgrams}
				/>
				<Controller
					control={control}
					render={({ onChange, value }) => (
						<RenderInputClass
							onChange={onChange}
							value={value}
							isError={errors.sodium}
							labelText={i18n.t('Sodium')}
							gramText={"mg"}
							refInput={sodiumInput}
							containerStyle={{ borderColor: theme.border }}
							onSubmitEditing={() => {
								//fiberInput.current.focus();
							}}
							style={{
								color: theme.themeAccent,
								borderColor: theme.border
							}}
						/>
					)}
					name="sodium"
					rules={{
						required: true,
						validate: (value) => validateNumber(value)
					}}
					defaultValue={sodium}
				/>
			</View>
		);
	};

	return (
		<>
			<SafeAreaView
				style={[styles.topCtn, { backgroundColor: theme.navBar }]}
			/>
			<SafeAreaView style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<NavigationBar
					statusBar={
						Platform.OS === 'ios' ? { hidden: false } : { hidden: true }
					}
					title={{
						title: i18n.t('updateMacroGoals'),
						style: {
							color: theme.navText,
							fontWeight: null,
							fontFamily: 'Comfortaa',
							fontSize: 14
						}
					}}
					containerStyle={{
						backgroundColor: theme.navBar,
						paddingHorizontal: 10
					}}
					leftButton={
						<HeaderTextButton title={i18n.t('cancel')} onPress={onClosed} />
					}
					rightButton={
						<HeaderTextButton
							title={i18n.t('save')}
							onPress={handleSubmit((values) =>
								dispatch(
									updateManualGoals(
										{
											calories: calories,
											carbs: values.carbs,
											fats: values.fats,
											proteins: values.proteins,
											sodium: values.sodium
										},
										onClosed
									)
								)
							)}
						/>
					}
				/>

				<PreviousNextView style={{ flex: 1, justifyContent: 'center' }}>
					{renderForm()}
				</PreviousNextView>
			</SafeAreaView>
		</>
	);
};

const styles = StyleSheet.create({
	inputsContainer: {
		justifyContent: 'center',
		flex: 1
	}
});
