import { createSelector } from 'reselect';
import { i18n } from 'Theme';
import _ from 'lodash';
//Single Foods
const getRecipe = (state) => state.edamamRecipeApi.singleRequest;
//const getQty = state => state.singleItem.recipe.activeServing;

export const formatMacros = createSelector(
	[getRecipe],
	(recipeData, qty = 1) => {
		if (typeof recipeData === 'undefined' || recipeData.length === 0) {
			return {
				fats: 0,
				carbs: 0,
				fiber: 0,
				totalcarbs: 0,
				proteins: 0,
				calories: 0,
				sodium: 0
			};
		}

		const {
			CHOCDF = { quantity: 0 },
			ENERC_KCAL,
			FAT,
			PROCNT,
			FIBTG = { quantity: 0 },
			NA
		} = recipeData.totalNutrients;
		let netCarb = CHOCDF.quantity - FIBTG.quantity;
		return {
			fats: +((FAT.quantity / recipeData.yield) * qty).toFixed(2),
			carbs: +((netCarb / recipeData.yield) * qty).toFixed(2),
			fiber: +((FIBTG.quantity / recipeData.yield) * qty).toFixed(2),
			totalcarbs: +((CHOCDF.quantity / recipeData.yield) * qty).toFixed(2),
			proteins: +((PROCNT.quantity / recipeData.yield) * qty).toFixed(2),
			calories: +((ENERC_KCAL.quantity / recipeData.yield) * qty).toFixed(0),
			sodium: +((NA.quantity / recipeData.yield) * qty).toFixed(0),
			name: recipeData.label,
			servingAmount: `${recipeData.label} x ${qty} ${i18n.t('servings')}`
		};
	}
);

export const formatNutrients = createSelector(
	[getRecipe],
	(recipeData, qty = 1) => {
		if (typeof recipeData === 'undefined' || recipeData.length === 0) {
			return [];
		}

		const result = recipeData.digest.filter((nutrient) => {
			if (
				nutrient.label === 'Fat' ||
				nutrient.label === 'Carbs' ||
				nutrient.label === 'Protein' ||
				nutrient.label === 'Water' ||
				nutrient.label === 'Sodium' ||
				nutrient.label === 'Folic acid' ||
				nutrient.label === 'Folate equivalent (total)' ||
				nutrient.label === 'Sugar alcohols'
			) {
				return false;
			} else {
				return true;
			}
		});
		const arr = result.map((item) => {
			if (item.label === 'Folate (food)') {
				return {
					name: 'Folate',
					amount: ((+item.total / recipeData.yield) * qty).toFixed(1)
				};
			}
			return {
				name: item.label,
				amount: ((+item.total / recipeData.yield) * qty).toFixed(1)
			};
		});

		return _.mapValues(_.keyBy(arr, 'name'), 'amount');
	}
);

export const formatIngredients = createSelector(getRecipe, (recipeData) => {
	if (typeof recipeData === 'undefined' || recipeData.length === 0) {
		return [];
	}

	return recipeData.ingredientLines.map((item) => {
		return { key: randomString(3), text: item };
	});
});

export const randomString = (length: number) => {
	return Math.round(
		Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
	)
		.toString(36)
		.slice(1);
};
