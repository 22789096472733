//@flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet, TouchableOpacity, FlatList } from 'react-native';
import { Text, Gram } from 'Components/common';
import { DateHeader } from 'Components/tracker/log/components';
import { Colors, i18n } from 'Theme';
import { deleteVital } from 'Redux/actions';
import { getDatesVitals, defaultVitalsUnits } from 'Redux/selectors';
import {
	returnCorrectUnitsString,
	returnCorrectUnitsValue,
	totalAllArrValues
} from 'Utilities';
import { VitalsEditModal } from './VitalsEditModal';
import _ from 'lodash';

//"Vitals" Screen for logging data such as Ketones, Blood Glucose, Electrolytes (Sodium, Potassium, Magnesium), etc
export const VitalsScreen = ({ navgiation, route, unitsArray }) => {
	const dispatch = useDispatch();
	const { theme } = useSelector((state) => state.startup);
	const { premium } = useSelector((state) => state.firebase.profile);
	const [isModalVisible, toggleModal] = useState(false);
	const [editingName, setEditingName] = useState('Blood Glucose');
	const [editingArr, setEditingArr] = useState([]);
	const { units } = useSelector((state) => state.firebase.profile.settings);
	const { date } = useSelector((state) => state.log);
	const activeDateVitals = useSelector((state) => getDatesVitals(state));

	//Preset the editing modal to have the correct items name, text (value)
	const editItem = (name, unitType, value) => {
		setEditingArr(value);
		setEditingName(name);
		toggleModal(true);
	};

	//Deletes vital entry from Firebase
	const _deleteVital = (item, name) => {
		//Find and remove entry to delete from the day's array of vital entries
		const deletedArr = editingArr.filter((obj) => {
			return obj.id !== item.id;
		});

		//Delete vital entry using entry ID from firebase, update local view with callback
		dispatch(deleteVital(item, name, () => setEditingArr(deletedArr)));
	};

	const renderItem = ({ item, index }) => {
		const name = Object.keys(item)[0]; // get string name of vital
		const unitType = unitsArray[name]; // use name to get unitType (ex: ketones, glucose, bpm, mg)
		const value = item[name]; // get number value of item from firebase

		if (!premium && name !== 'Blood Pressure' && name !== 'Blood Glucose') {
			return;
		}

		//unitString is the units preference string from the settings (ie metric vs imperial, mmol/L vs mg/dL)
		const unitString =
			typeof units[`${unitType}Units`] !== 'undefined'
				? units[`${unitType}Units`]
				: '';

		//Sort arr in order from earliest to most recent entries of the current states log date to show user the latest entry
		const sortedArr = _.values(value)
			.slice()
			.sort((a, b) => {
				return a.time < b.time ? -1 : a.time > b.time ? 1 : 0;
			})
			.reverse();
		let valueText;

		//mmHg  is for blood pressure and requires slightly different text formatting
		if (unitType === 'mmHg') {
			valueText = (
				<Text style={[styles.listLabel, { color: theme.themeAccent }]}>
					{sortedArr.length > 0
						? `${sortedArr[0].value.toFixed(1)}/${sortedArr[0].valueTwo.toFixed(
								1
						  )}`
						: '0/0'}
				</Text>
			);
		} else if (unitType === 'mg' || unitType === 'exercise') {
			//Exercise and mg (sodium, potassium, magnesium) need to be added together and seen as a total sum rather than the latest entry.
			valueText = (
				<Text style={[styles.listLabel, { color: theme.themeAccent }]}>
					{sortedArr.length > 0
						? returnCorrectUnitsValue(
								unitType,
								unitString,
								totalAllArrValues(sortedArr)
						  ).toFixed(1)
						: '0'}
				</Text>
			);
		} else {
			//all other vitals just show the latest entry
			valueText = (
				<Text style={[styles.listLabel, { color: theme.themeAccent }]}>
					{sortedArr.length > 0
						? returnCorrectUnitsValue(
								unitType,
								unitString,
								sortedArr[0].value
						  ).toFixed(1)
						: '0'}
				</Text>
			);
		}

		return (
			<TouchableOpacity
				key={name}
				style={[styles.listItemContainer, { borderBottomColor: theme.border }]}
				onPress={() => editItem(name, unitType, sortedArr, index)}>
				<View style={styles.labelCtn}>
					<Text style={[styles.listLabel, { color: theme.themeAccent }]}>
						{i18n.t(name.replace(/\s+/g, ''))}
					</Text>
				</View>
				<View style={styles.valueCtn}>
					{valueText}
					<Gram
						style={{ fontSize: 9, color: theme.darkFont }}
						customText={returnCorrectUnitsString(unitType, unitString)}
					/>
				</View>
			</TouchableOpacity>
		);
	};

	return (
		<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
			<DateHeader theme={theme} />
			<FlatList
				data={activeDateVitals}
				renderItem={renderItem}
				style={styles.flatlistCtn}
				keyExtractor={(item) => Object.keys(item)[0]}
			/>
			<VitalsEditModal
				isModalVisible={isModalVisible}
				onClose={() => toggleModal(false)}
				onDelete={(item, name) => _deleteVital(item, name)}
				valueArr={editingArr}
				name={editingName}
				date={date}
				unitsArray={unitsArray}
				theme={theme}
			/>
		</View>
	);
};

//Default unit types to use for formatting correct value based on user's unit preference (mmol/L vs mg/dL, etc)
VitalsScreen.defaultProps = { unitsArray: defaultVitalsUnits };

const styles = StyleSheet.create({
	flatlistCtn: {
		paddingHorizontal: 25
	},
	listItemContainer: {
		flex: 1,
		flexDirection: 'row',
		borderBottomWidth: 1,
		paddingVertical: 10,
		paddingLeft: 5,
		alignItems: 'center'
	},
	labelCtn: {
		flex: 4,
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row'
	},
	listLabel: {
		alignSelf: 'center',
		paddingRight: 5,
		fontSize: 12
	},
	valueCtn: {
		alignItems: 'center',
		flexDirection: 'row'
	}
});
