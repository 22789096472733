//@flow
import React, { useState } from 'react';
import { ScrollView, View, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomFood, multiAddFoods } from './actions';
import { HeaderActionButtons, SearchFilter } from './components';
import { FlatListMultiAdd, FlatListSingle } from './components/listview';
import { EditingItemModal as CreateItemModal } from 'Components/modal/EditingItemModal';
import { createFilter } from 'react-native-search-filter';
import _ from 'lodash';
import { i18n } from 'Theme';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

export const CustomFoodTab = ({ route, navigation }) => {
	const dispatch = useDispatch();
	const {
		settings: {
			units: { carbUnits }
		},
		customfoodsv3
	} = useSelector((state) => state.firebase.profile);

	const { mealTime, date } = useSelector((state) => state.log);
	const { theme } = useSelector((state) => state.startup);

	const [multiSelect, setMultiSelect] = useState(false);
	const [selected, setSelected] = useState([]);
	const [modalVisible, toggleModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	//Submit Multiple Log Adds, Loops through each selected and makes a Firebase Set Call, then Navigates back to log.
	const multiAdd = (selected) => {
		dispatch(
			multiAddFoods(selected, date, mealTime, () => setMultiSelect(false))
		);
	};

	const createSubmit = (item) => {
		dispatch(createCustomFood(item));
		toggleModal(false);
	};

	const searchUpdated = (term) => {
		setSearchTerm(term);
	};

	const arr = _.values(customfoodsv3);

	const sortedArr = arr.slice().sort((a, b) => {
		var textA = a.name.toUpperCase();
		var textB = b.name.toUpperCase();
		return textA < textB ? -1 : textA > textB ? 1 : 0;
	});

	const determineRoute = (currentRoute) => {
		let nextRoute = '';
		if (currentRoute === 'ManageFood') {
			// Add Food Stack -> Search Tab -> Single Search
			nextRoute = 'ManageFoodSingle';
		} else if (currentRoute === 'MealEditingFood') {
			// Add Food Stack -> Meal Tab -> Editing Meal -> Single Search
			nextRoute = 'MealEditingSingleFood';
		} else if (currentRoute === 'ManageMealEditingFood') {
			//Manage -> Meal Tab -> Editing Meal -> Search Single
			nextRoute = 'ManageMealEditingSingleFood';
		} else {
			//Net Carb Look-Up Search Single
			nextRoute = 'CustomFoodSingle';
		}
		return nextRoute;
	};

	const searchFilter = sortedArr.filter(createFilter(searchTerm, 'name'));
	return (
		<View style={{ flex: 1, backgroundColor: theme.base }}>
			<ScrollView keyboardShouldPersistTaps="handled">
				<HeaderActionButtons
					onCreate={() => toggleModal(true)}
					enableMulti={() => setMultiSelect(true)}
					disableMulti={() => setMultiSelect(false)}
					onMulti={() => multiAdd(selected)}
					isMulti={multiSelect}
					isManage={route.params.manage}
					theme={theme}
				/>
				<SearchFilter
					onChangeText={searchUpdated}
					ctnStyle={{
						backgroundColor: theme.baseBG,
						borderBottomColor: theme.border
					}}
					inputStyle={{ backgroundColor: theme.grey, color: theme.darkFont }}
				/>
				{!multiSelect ? (
					<FlatListSingle
						data={searchFilter}
						type="food"
						routeName={determineRoute(route.name)}
						carbUnits={carbUnits}
						mealTime={mealTime}
						manage={route.params.manage}
						logDate={date}
						theme={theme}
					/>
				) : (
					<FlatListMultiAdd
						listArray={searchFilter}
						onSelectionChange={(selectedRow, allSelectedRows) =>
							setSelected(allSelectedRows)
						}
						type="food"
						carbUnits={carbUnits}
						mealTime={mealTime}
						logDate={date}
						theme={theme}
					/>
				)}
			</ScrollView>
			<Modal.ReactNativeModal
				isVisible={modalVisible}
				style={{
					padding: 0,
					margin: 0
				}}
				animationIn={'slideInUp'}
				animationOut={'slideOutDown'}
				onClosed={() => toggleModal(false)}>
				<CreateItemModal
					closeModal={() => toggleModal(false)}
					onSubmit={createSubmit}
					modalTitle={i18n.t('createFood')}
					item={{
						name: '',
						calories: '0',
						fats: '0',
						proteins: '0',
						carbs: '0',
						totalcarbs: '0',
						fiber: '0',
						sodium: '0',
						manualCalories: false,
						nutrientsV2: {}
					}}
				/>
			</Modal.ReactNativeModal>
		</View>
	);
};
