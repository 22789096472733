//@flow
import React from 'react';
import {
	View,
	TouchableOpacity,
	Platform,
	StyleSheet,
	SafeAreaView,
	Image,
	FlatList
} from 'react-native';
import { Images, i18n } from 'Theme';
import { SideMenuItem, SideMenuHeader } from './';
import { Text } from 'Components/common';
import Communications from 'react-native-communications';
import { useSelector } from 'react-redux';
import DeviceInfo from 'react-native-device-info';

export const SideBar = (props) => {
	const { index, routes } = props.state;
	const currentRoute = routes[index].name;
	const { theme } = useSelector((state) => state.startup);

	const onMenuPress = async (itemUrl) => {
		props.navigation.navigate(itemUrl);
	};

	const feedbackSubject =
		Platform.OS === 'ios'
			? `${i18n.t('appName')} Feedback - IOS - ${DeviceInfo.getVersion()}`
			: `${i18n.t('appName')} Feedback - Android - ${DeviceInfo.getVersion()}`;

	const onFeedback = () => {
		Communications.email(
			['prestigeworldwide.app@gmail.com'],
			null,
			null,
			feedbackSubject,
			''
		);
	};

	const renderHeader = () => (
		<View style={[styles.header, { backgroundColor: theme.themeAccent }]}>
			<Image style={styles.headerIcon} source={Images.logoTrans} />
			<Text style={[styles.headerTitle, { color: theme.navText }]}>
				{i18n.t('appName')}
			</Text>
		</View>
	);

	const renderFooter = () => (
		<View style={[styles.footer, { borderTopColor: theme.border }]}>
			<TouchableOpacity style={styles.footerBtn} onPress={() => onFeedback()}>
				<SideMenuItem name={'ios-mail'} color={theme.lightFont}>
					{i18n.t('feedback')}
				</SideMenuItem>
			</TouchableOpacity>
		</View>
	);

	const createNavigationItemForRoute = (route) => {
		let highlighted = false;

		if (route.name === currentRoute) {
			highlighted = true;
		}

		const { options } = props.descriptors[route.key];
		if (route.name === 'Calculator') {
			return (
				<View>
					<SideMenuHeader title="" bgColor={theme.border} />
					<TouchableOpacity
						key={route.key}
						style={[
							styles.menuRow,
							highlighted ? { backgroundColor: theme.baseAlt } : {}
						]}
						onPress={() => onMenuPress(route.name)}>
						<SideMenuItem name={options.drawerIcon} color={theme.lightFont}>
							{options.title}
						</SideMenuItem>
					</TouchableOpacity>
				</View>
			);
		}
		return (
			<TouchableOpacity
				key={route.key}
				style={[
					styles.menuRow,
					highlighted ? { backgroundColor: theme.baseAlt } : {}
				]}
				onPress={() => onMenuPress(route.name)}>
				<SideMenuItem color={theme.lightFont} name={options.drawerIcon}>
					{options.title}
				</SideMenuItem>
			</TouchableOpacity>
		);
	};

	return (
		<SafeAreaView style={{ flex: 1, backgroundColor: theme.baseBG }}>
			{renderHeader()}
			<FlatList
				data={props.state.routes}
				renderItem={({ item }) => createNavigationItemForRoute(item)}
				keyExtractor={(item) => item.key}
				extraData={currentRoute}
			/>
			{renderFooter()}
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	safeArea: {
		flex: 1
	},
	header: {
		height: 55,
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row',
		paddingLeft: 8
	},
	headerTitle: {
		fontWeight: 'bold'
	},
	headerIcon: {
		height: 40,
		width: 40,
		marginRight: 10
	},
	footer: {
		height: 50,
		justifyContent: 'center',
		alignItems: 'flex-start',
		borderTopWidth: 1
	},
	footerBtn: {},
	menuRow: {
		paddingTop: 15,
		paddingBottom: 15
	}
});
