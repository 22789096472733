//@flow
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { LogViewMacroGrid, LogViewBarGraph, LogViewPieChart } from './';
import { customDateMacroGoals } from '../../utilities/LogHelper';
import { changeTab } from '../../actions';
import { getTrackerData, getDatesTotals } from '../../selectors';
import Elevations from 'react-native-elevation';
import { Nutrients } from 'Components/common';

type Props = {
	theme: Object
};

export const ViewTabBar = (props: Props) => {
	const { theme } = props;
	const dispatch = useDispatch();
	const { activeTab } = useSelector((state) => state.log);
	const { settings, premium } = useSelector((state) => state.firebase.profile);
	const data = useSelector((state) => getTrackerData(state));
	const { dailygoals } = data;
	const macroTotalsObject = useSelector((state) => getDatesTotals(state));

	const goals =
		typeof dailygoals !== 'undefined'
			? customDateMacroGoals(dailygoals)
			: {
					cal: settings.goals.cal,
					fatgrams: settings.fatgrams,
					carbgrams: settings.carbgrams,
					proteingrams: settings.proteingrams,
					sodium: settings.goals.sodium
			  };

	return (
		<View style={[styles.tabViewContainer, { backgroundColor: theme.baseBG }]}>
			<View
				style={[
					styles.topTabBar,
					{ backgroundColor: theme.grey, borderBottomColor: theme.border }
				]}>
				<TouchableOpacity
					onPress={() => dispatch(changeTab(0))}
					style={[
						styles.tab,
						activeTab === 0
							? { borderBottomColor: theme.tabActive, borderBottomWidth: 1 }
							: null
					]}>
					<FontAwesome
						name="th"
						size={24}
						color={activeTab === 0 ? theme.tabActive : theme.tabInactive}
					/>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => dispatch(changeTab(1))}
					style={[
						styles.tab,
						styles.middleTab,
						activeTab === 1
							? { borderBottomColor: theme.tabActive, borderBottomWidth: 1 }
							: null,
						{ borderRightColor: theme.border, borderLeftColor: theme.border }
					]}>
					<FontAwesome
						name="bar-chart"
						size={24}
						color={activeTab === 1 ? theme.tabActive : theme.tabInactive}
					/>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => dispatch(changeTab(2))}
					style={[
						styles.tab,
						activeTab === 2
							? { borderBottomColor: theme.tabActive, borderBottomWidth: 1 }
							: null
					]}>
					<FontAwesome
						name="pie-chart"
						size={24}
						color={activeTab === 2 ? theme.tabActive : theme.tabInactive}
					/>
				</TouchableOpacity>
				{premium && (
					<TouchableOpacity
						onPress={() => dispatch(changeTab(3))}
						style={[
							styles.tab,
							{ borderLeftColor: theme.border, borderLeftWidth: 1 },
							activeTab === 3
								? { borderBottomColor: theme.tabActive, borderBottomWidth: 1 }
								: null
						]}>
						<MaterialCommunityIcons
							name="food-apple"
							size={24}
							color={activeTab === 3 ? theme.tabActive : theme.tabInactive}
						/>
					</TouchableOpacity>
				)}
			</View>
			<View style={styles.tabView}>
				{activeTab === 0 && (
					<LogViewMacroGrid
						macrosEaten={macroTotalsObject.macrosEaten}
						macrosRemaining={macroTotalsObject.macrosRemaining}
						macroGoals={goals}
						customDateGoal={typeof dailygoals !== 'undefined' ? true : false}
						carbUnits={settings.units.carbUnits}
						theme={theme}
					/>
				)}
				{activeTab === 1 && (
					<LogViewBarGraph
						macrosEaten={macroTotalsObject.macrosEaten}
						macroGoals={goals}
						carbUnits={settings.units.carbUnits}
						theme={theme}
					/>
				)}
				{activeTab === 2 && (
					<LogViewPieChart
						data={macroTotalsObject}
						carbUnits={settings.units.carbUnits}
						settings={settings}
						theme={theme}
					/>
				)}
				{activeTab === 3 && (
					<Nutrients
						nutrients={macroTotalsObject.macrosEaten.nutrientsV2}
						theme={theme}
					/>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	tabViewContainer: { ...Elevations[2] },
	topTabBar: {
		height: 65,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'stretch',
		...Elevations[1]
	},
	tab: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	middleTab: {
		borderRightWidth: 1,
		borderLeftWidth: 1
	},
	tabView: {}
});
