//@flow
import React, { useEffect } from 'react';
import { ImageBackground, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { addStartUpCounter, updateLocalLanguage } from './redux/actions';
import Navigation from './navigation/DrawerNavigator';
import { Loader, Text } from 'Components/common';
import { Images } from 'Theme';

export const RootContainer = () => {
	const { startCounter, language, theme } = useSelector(
		(state) => state.startup
	);
	const auth = useSelector((state) => state.firebase.auth);
	const profile = useSelector((state) => state.firebase.profile);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(addStartUpCounter(startCounter));
		dispatch(updateLocalLanguage(language));
	}, []);

	if (
		!isLoaded(auth) &&
		isEmpty(auth) &&
		!isLoaded(profile) &&
		isEmpty(profile)
	) {
		return (
			<ImageBackground
				source={Images.login}
				style={{ width: '100%', height: '100%' }}>
				<Loader
					full={true}
					loaderBG={theme.baseBG}
					loaderColor={theme.darkFont}
				/>
			</ImageBackground>
		);
	} else if (profile.premium === false) {
		return (
			<View
				style={{
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0,0,0,0.75)',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
				<Text style={{color: '#fff'}}>Web portal only available for premium users</Text>
			</View>
		);
	} else {
		return <Navigation />;
	}
};
