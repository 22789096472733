//@flow
import React from 'react';
import { View, ScrollView, StyleSheet, FlatList, Image } from 'react-native';
import { Heading, Divider, Paragraph, Text } from '../common';
import { i18n, Images } from 'Theme';
import { useSelector } from 'react-redux';

//Food Filters
const FOOD_CATEGORIES = [
	{ key: 0, label: i18n.t('all') },
	{ key: 1, label: 'Grains (~ 6-8 servings/day)' },
	{ key: 2, label: i18n.t('beverages') },
	{ key: 3, label: i18n.t('cheeses') },
	{ key: 4, label: i18n.t('dairy') },
	{ key: 5, label: i18n.t('dressings') },
	{ key: 6, label: i18n.t('fatsoils') },
	{ key: 7, label: i18n.t('fish') },
	{ key: 8, label: i18n.t('flours') },
	{ key: 9, label: i18n.t('fowl') },
	{ key: 10, label: i18n.t('fruits') },
	{ key: 11, label: i18n.t('grainsstarch') },
	{ key: 12, label: i18n.t('meats') },
	{ key: 13, label: i18n.t('nutslegumes') },
	{ key: 14, label: i18n.t('seeds') },
	{ key: 15, label: i18n.t('shellfish') },
	{ key: 16, label: i18n.t('sugarssweetener') },
	{ key: 17, label: i18n.t('vegetables') }
];

const grains = [
	'bread',
	'rolls',
	'pasta',
	'emuffin',
	'pita',
	'bagel',
	'cereal',
	'grits',
	'oatmeal',
	'brownrice',
	'popcorn',
	'pretzel',
	'corntortilla',
	'wheattorilla',
	'barley',
	'bulgur',
	'buckwheat',
	'quinoa',
	'spelt',
	'wildrice'
];

const veg = [
	'asparagus',
	'artichoke',
	'beetgreens',
	'bellpepper',
	'broccoli',
	'brusselsprouts',
	'cabbage',
	'carrot',
	'cauliflower',
	'celery',
	'chard',
	'corn',
	'cucumber',
	'eggplant',
	'greenbean',
	'jicama',
	'kale',
	'leeks',
	'salad',
	'limabeans',
	'mushrooms',
	'onion',
	'peas',
	'potatoe',
	'radish',
	'spinach',
	'squash',
	'sweetpotatoe',
	'tomatoe'
];
const fruits = [
	'apple',
	'apricot',
	'banana',
	'berries',
	'cherry',
	'dates',
	'fig',
	'grapes',
	'grapefruit',
	'kiwi',
	'lemon',
	'mango',
	'melon',
	'orange',
	'papaya',
	'peach',
	'pear',
	'pineapple',
	'plum',
	'prune',
	'raisin',
	'strawberry',
	'tangerine'
];
const dairy = [
	'buttermilk',
	'hardcheese',
	'softcheese',
	'cottagecheese',
	'milk',
	'kefir',
	'mozzarella',
	'sourcream',
	'yogurt'
];
const meat = [
	'sirloin',
	'chickenb',
	'delimeat',
	'eggs',
	'groundb',
	'wfish',
	'pork',
	'salmon',
	'shrimp'
];
const nuts = [
	'almonds',
	'beans',
	'cashews',
	'hazelnuts',
	'lentils',
	'nutbutter',
	'peanuts',
	'pecans',
	'seeds',
	'soynuts',
	'splitpeas',
	'walnuts'
];
const fats = ['dressing', 'mayo', 'oil'];
const sweets = ['gelatin', 'fruitice', 'candy', 'jelly', 'syrup', 'sorbet'];
const spices = [
	'basil',
	'cayenne',
	'cilantro',
	'coriander',
	'cumin',
	'garlic',
	'garlicpowder',
	'onionpowder',
	'parsley',
	'pepper',
	'rosemary',
	'salt',
	'stevia',
	'thyme',
	'vinegar'
];

export const OfflineScreen = () => {
	const { theme } = useSelector((state) => state.startup);

	//Render Each Food and NetCarb Number
	const renderItem = ({ item }) => {
		return (
			<View style={[styles.row, { borderBottomColor: theme.border }]}>
				<Image style={styles.img} source={Images[`${item}`]} />
				<Text style={styles.title}>{i18n.t(`${item}`)}</Text>
			</View>
		);
	};

	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<View>
				<Heading style={{ backgroundColor: theme.base }}>
					Recommended Foods to Eat
				</Heading>
				<Paragraph>
					This is not a complete list, but more of the basics. For example,
					pretty much all fruits, whole-grains, and vegetables are fair game. As
					long as it conforms to the DASH guidelines, then it is allowed.
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					Grains (~ 6-8 servings/day)
				</Heading>
				<Paragraph style={[styles.subtitle, { backgroundColor: theme.base }]}>
					Major source of energy and fiber
				</Paragraph>
				<FlatList
					data={grains}
					renderItem={renderItem}
					keyExtractor={(item) => item}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					Vegetables (~ 4-5 servings/day)
				</Heading>
				<Paragraph style={[styles.subtitle, { backgroundColor: theme.base }]}>
					Rich sources of potassium, magnesium, and fiber
				</Paragraph>
				<FlatList
					data={veg}
					renderItem={renderItem}
					keyExtractor={(item) => item}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					Fruits (~ 4-5 servings/day)
				</Heading>
				<Paragraph style={[styles.subtitle, { backgroundColor: theme.base }]}>
					Important sources of potassium, magnesium, and fiber
				</Paragraph>
				<FlatList
					data={fruits}
					renderItem={renderItem}
					keyExtractor={(item) => item}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					Fat Free / Low Fat Dairy (~ 2-3 servings/day)
				</Heading>
				<Paragraph style={[styles.subtitle, { backgroundColor: theme.base }]}>
					Major sources of calcium and protein
				</Paragraph>
				<FlatList
					data={dairy}
					renderItem={renderItem}
					keyExtractor={(item) => item}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					Lean Meats, Poultry, Fish ( Max 6 servings/day)
				</Heading>
				<Paragraph style={[styles.subtitle, { backgroundColor: theme.base }]}>
					Rich sources of protein and magnesium
				</Paragraph>
				<FlatList
					data={meat}
					renderItem={renderItem}
					keyExtractor={(item) => item}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					Nuts, seeds, and legumes (~ 4-5 servings/week)
				</Heading>
				<Paragraph style={[styles.subtitle, { backgroundColor: theme.base }]}>
					Rich sources of energy, magnesium, protein, and fiber
				</Paragraph>
				<FlatList
					data={nuts}
					renderItem={renderItem}
					keyExtractor={(item) => item}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					Fats and oils (~ 2-3 servings/day)
				</Heading>
				<Paragraph style={[styles.subtitle, { backgroundColor: theme.base }]}>
					The DASH study had 27 percent of calories as fat, including fat in or
					added to foods
				</Paragraph>
				<FlatList
					data={fats}
					renderItem={renderItem}
					keyExtractor={(item) => item}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					Sweets & added sugars (Max 5 servings/week)
				</Heading>
				<Paragraph style={[styles.subtitle, { backgroundColor: theme.base }]}>
					Sweets should be low in fat
				</Paragraph>
				<FlatList
					data={sweets}
					renderItem={renderItem}
					keyExtractor={(item) => item}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					Spices & Flavorings
				</Heading>
				<Paragraph style={[styles.subtitle, { backgroundColor: theme.base }]}>
					For taste, but also added health benefits.
				</Paragraph>
				<FlatList
					data={spices}
					renderItem={renderItem}
					keyExtractor={(item) => item}
				/>
				<Divider />
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {},
	img: {
		width: 50,
		height: 50,
		borderRadius: 25
	},
	row: {
		flexDirection: 'row',
		flex: 1,
		borderBottomWidth: 1,
		paddingVertical: 8,
		paddingHorizontal: 8,
		alignItems: 'center'
	},
	title: {
		paddingLeft: 30,
		fontFamily: 'Raleway',
		fontSize: 16
	},
	subtitle: {
		fontSize: 12
	}
});
