//@flow
import Toast from 'react-native-root-toast';
//import { AccessToken, LoginManager } from 'react-native-fbsdk';
import { i18n } from 'Theme';
import { WebCompatAlert } from 'Components/common';
import { sendGridEmail } from 'Utilities';
import { anonProfileCreate } from 'Redux/actions';

//Login, Forgot Password, Logout actions----------------------------------
export const signInSubmit = (values: { email: string, password: string }) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	return firebase
		.login({
			email: values.email.trim(),
			password: values.password
		})
		.then(() => {
			Toast.show(i18n.t('signedIn'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		})
		.catch((error) => {
			return WebCompatAlert(i18n.t('error'), `${error.message}`);
		});
};

export const signUpSubmit = (
	values: { email: string, password: string },
	subscribeCheck: boolean
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	return firebase
		.createUser({ email: values.email.trim(), password: values.password })
		.then(() => {
			Toast.show(i18n.t('accountCreated'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		})
		.catch((error) => {
			return WebCompatAlert(i18n.t('error'), `${error.message}`);
		});
};

export const forgotPassword = (value: string) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();

	if (value.trim().length === 0) {
		WebCompatAlert(i18n.t('error'), i18n.t('emailError'));
		return Promise.resolve();
	}

	return firebase
		.auth()
		.sendPasswordResetEmail(value.trim())
		.then(() => {
			Toast.show(i18n.t('resetEmailSent'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
			return Promise.resolve();
		})
		.catch((error) => {
			WebCompatAlert(i18n.t('error'), `${error.message}`);
			return Promise.resolve();
		});
};

export const signUpAnon = () => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	WebCompatAlert(
		i18n.t('skipAccount'),
		i18n.t('createAccountLater'),
		i18n.t('confirm'),
		() =>
			firebase
				.auth()
				.signInAnonymously()
				.then((cred) => {
					dispatch(anonProfileCreate(cred.user.uid));
					Toast.show(i18n.t('signedIn'), {
						duration: Toast.durations.LONG,
						position: Toast.positions.BOTTOM,
						shadow: true,
						animation: true
					});
				})
				.catch((error) => {
					return WebCompatAlert(i18n.t('error'), `${error}`);
				})
	);
};

export const createUserFromAnon = (
	values: { email: string, password: string },
	subscribeCheck: boolean
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();

	// (Anonymous user is signed in at that point.)
	const email = values.email.trim();
	const pw = values.password;
	const { checkedInterests } = state.firebase.profile.onBoard;
	const { language } = state.startup;

	// 1. Create the email and password credential, to upgrade the
	// anonymous user.
	const credential = firebase.auth.EmailAuthProvider.credential(email, pw);

	// 2. Links the credential to the currently signed in user
	// (the anonymous user).
	return firebase
		.auth()
		.currentUser.linkWithCredential(credential)
		.then(
			(userData) => {
				const { user } = userData;
				const ref = firebase.database().ref(`userdata/${user.uid}/`);
				let updates = {};
				updates['email/'] = user.email || user.providerData[0].email;
				updates['onBoard/subscribedEmail'] = subscribeCheck ? true : false;
				return ref.update(updates).then(() => {
					firebase.reloadAuth();
					Toast.show(i18n.t('accountCreated'), {
						duration: Toast.durations.LONG,
						position: Toast.positions.BOTTOM,
						shadow: true,
						animation: true
					});
					subscribeCheck &&
						subscribeEmailUser(email, language, checkedInterests);
					yourSendEmailFunction(email, language);
					return Promise.resolve();
				});
			},
			(error) => {
				WebCompatAlert(i18n.t('error'), `${error}`);
				return Promise.reject(new Error('error'));
			}
		);
};

//Delete User
export const deleteAccount = () => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	var user = firebase.auth().currentUser;
	const { uid } = state.firebase.auth;

	const reauthenticate = (currentPassword: string) => {
		var user = firebase.auth().currentUser;
		var cred = firebase.auth.EmailAuthProvider.credential(
			user.email,
			currentPassword
		);
		return user.reauthenticateWithCredential(cred);
	};

	WebCompatAlert(
		i18n.t('deleteAccount'),
		i18n.t('verifyDeleteMessage'),
		i18n.t('delete'),
		() =>
			user
				.delete()
				.then(() => {
					return Toast.show(i18n.t('accountDeleted'), {
						duration: Toast.durations.LONG,
						position: Toast.positions.BOTTOM,
						shadow: true,
						animation: true
					});
				})
				.catch((error) => {
					/*if(error.code === "auth/requires-recent-login"){

					}else{
						WebCompatAlert(i18n.t('error'), `${error.message}`);
					}*/
					WebCompatAlert(i18n.t('error'), `${error.message}`);
				})
	);
};

export const finishOnboard = (
	checked: boolean,
	language: string,
	formattedInterests: Array<Object>
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid, email } = state.firebase.auth;
	const { age, gender } = state.firebase.profile.profilePage;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};
	const formattedArr = interestFormatter(formattedInterests);
	const formattedAge = ageFormatter(age);
	const formattedGender = {
		name: gender === 'male' ? 'Male' : 'Female',
		status: 'active'
	};

	const finalArr = [...formattedArr, formattedAge, formattedGender];
	updates['onBoard/'] = {
		shown: true,
		subscribedEmail: checked,
		checkedInterests: finalArr
	};
	return ref.update(updates).then(() => {
		checked && subscribeEmailUser(email, language, finalArr);
		email && yourSendEmailFunction(email, language);
	});
};

//Subscribe user to email list
const subscribeEmailUser = (
	email: string,
	language: string,
	checkedInterestArr: Array<Object>
) => {
	return fetch(
		`https://us-central1-mydashdiet.cloudfunctions.net/mailchimp/subscribe?email=${email}&language=${language}`,
		{
			method: 'GET'
		}
	)
		.then((responsej) => responsej.json())
		.then((response) => {
			if (response === true) {
				addEmailTags(email, checkedInterestArr);
				console.log('Success Subscribe');
			}
		})

		.catch((error) => {
			if (error === false) {
				console.log('Success Failed');
			}
		});
};

//Add Signup Interest Tags to Subscribed User
const addEmailTags = (email: string, checkedInterestArr: Array<Object>) => {
	const js = JSON.stringify(checkedInterestArr);
	return fetch(
		`https://us-central1-mydashdiet.cloudfunctions.net/mailchimp/addtag?email=${email}&tags=${js}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			}
		}
	)
		.then((responsej) => responsej.json())
		.then((response) => {
			if (response === true) {
				console.log('Success Tags');
			}
		})

		.catch((error) => {
			if (error === false) {
				console.log('Tags Failed');
			}
		});
};

export const yourSendEmailFunction = (email: string, language: string) => {
	const sendRequest = sendGridEmail(email, language);
	sendRequest
		.then((response) => {
			console.log('sendGridEmail Success');
		})
		.catch((error) => {
			console.log('sendGridEmail', error);
		});
};

const tagFind = (newName: string) => {
	switch (newName) {
		case i18n.t('weightLoss'):
			return 'WeightLoss';
		case i18n.t('diseasePrevention'):
			return 'DiseasePrevention';
		case i18n.t('lowerBloodPressure'):
			return 'LowerBloodPressure';
		case i18n.t('improvedCholesterolProfile'):
			return 'ImprovedCholesterolProfile';
		case i18n.t('heartHealth'):
			return 'HeartHealth';
		case i18n.t('insulinManagement'):
			return 'InsulinManagement';
		default:
			return 'WeightLoss';
	}
};

const interestFormatter = (checkedArr: Array<Object>) => {
	return checkedArr
		.filter((item) => item.checked)
		.map((element) => {
			return { name: tagFind(element.name), status: 'active' };
		});
};

const ageFormatter = (number: number) => {
	switch (true) {
		case number < 25:
			return { name: '18-24', status: 'active' };
		case number > 24 && number < 35:
			return { name: '25-34', status: 'active' };
		case number > 34 && number < 45:
			return { name: '35-44', status: 'active' };
		case number > 44 && number < 55:
			return { name: '45-54', status: 'active' };
		case number > 54:
			return { name: '55+', status: 'active' };
		default:
			return { name: '35-44', status: 'active' };
	}
};

/*
export const signUpFacebook = (callback, errHandler, onBoard) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const onBoarded = state.startup;
	LoginManager.logInWithReadPermissions(['public_profile', 'email'])
		.then((result) => {
			if (result.isCancelled) {
				return Promise.resolve('cancelled');
			}
			return AccessToken.getCurrentAccessToken();
		})
		.then((data) => {
			if (data === 'cancelled') {
				return false;
			}
			callback();
			onBoarded ? null : onBoard();
			const credential = firebase.auth.FacebookAuthProvider.credential(
				data.accessToken
			);
			return firebase.login({ provider: 'facebook', credential });
		})
		.catch((error) => {
			errHandler();
			Alert.alert('Error', `${error}`);
		});
};
*/
