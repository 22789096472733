//@flow
import base64 from 'base-64';
import { FITBIT_AUTH, FITBIT_LOGOUT, FITBIT_SYNCED } from '../reducers/types';
import { Linking } from 'react-native';
import qs from 'qs';
import Toast from 'react-native-root-toast';
import { getTrackerData } from 'Components/tracker/log/selectors';
import { i18n } from 'Theme';
import { WebCompatAlert } from 'Components/common';

export type Dispatch = (action: ThunkAction) => any;
export type GetState = () => Object;
export type ThunkAction = (
	dispatch: Dispatch | Object,
	getState: GetState
) => any;

export const fitbitConfig = {
	client_id: '22CCTT',
	client_secret: '777fe1b5f771cbfa38c61a4ccf2cd782'
};

export const fitbitWebConfig = {
	client_id: '22C3QR',
	client_secret: '459b3dcfb02ced1cbf092b8f6e11658c'
};

export const fitbit_logIn = (callback?: Function) => (dispatch: Dispatch) => {
	const clientId = fitbitConfig.client_id;
	const handleUrl = (event) => {
		//Save fitbit access token for get/post requests after successful auth
		Linking.removeEventListener('url', handleUrl);
		const [, query_string] = event.url.match(/\#(.*)/);
		const query = qs.parse(query_string);
		if (query.access_token) {
			dispatch({ type: FITBIT_AUTH, payload: query });
			if (callback) {
				dispatch(callback(query.access_token));
			}
			Toast.show(i18n.t('fitbitConnected'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		}
	};

	// Listen to redirection
	Linking.addEventListener('url', handleUrl);

	// Call OAuth
	const oauthurl = `https://www.fitbit.com/oauth2/authorize?client_id=${clientId}&response_type=token&scope=profile%20activity%20weight%20nutrition&redirect_uri=mydash%3A%2F%2Ffit&expires_in=31536000`;
	Linking.openURL(oauthurl).catch((err) =>
		console.error('Error processing linking', err)
	);
};

//revoke auth access from Fitbit
export const fitbit_logOut = (access_token: string) => (dispatch: Dispatch) => {
	WebCompatAlert('Fitbit', i18n.t('fitbitRemove'), i18n.t('logOut'), () =>
		fetch(`https://api.fitbit.com/oauth2/revoke?token=${access_token}`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: `Basic ${base64.encode(
					`${fitbitConfig.client_id}:${fitbitConfig.client_secret}`
				)}`
			},
			body: 'token:' + access_token
		})
			.then(() => {
				Toast.show(i18n.t('fitbitDisconnected'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
				return dispatch({
					type: FITBIT_LOGOUT
				});
			})
			.catch((err) => {
				console.error('Error: ', err);
			})
	);
};

export const fitbit_saveWeight = (
	access_token: string,
	date: string,
	weight: number,
	metric: boolean,
	isAuthCallback?: boolean = false
) => (dispatch) => {
	const weightStr = weight.toFixed(1);
	fetch(
		`https://api.fitbit.com/1/user/-/body/log/weight.json?weight=${weightStr}&date=${date}`,
		{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${access_token}`,
				'Accept-Language': `${metric ? '' : 'en_US'}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((res) => {
			//If Token Expired, Re-Auth, Make sure if auth is denied there isn't infinity loop
			if (
				res.success === false &&
				res.errors[0].errorType === 'invalid_token'
			) {
				!isAuthCallback &&
					dispatch(
						fitbit_logIn((token) =>
							fitbit_saveWeight(token, date, weight, metric, true)
						)
					);
				return false;
			}
		})
		.catch((err) => {
			console.error('Error: ', err);
		});
};

//Water not quite working right now, for a later upgrade
/*export const fitbit_saveWater = (
	access_token: string,
	date: string,
	water: number,
	metric: boolean,
	isAuthCallback?: boolean = false
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/trackerdate/`);
	const trackerDateObj = getTrackerData(state);
	let updates = {};
	const waterID =
		trackerDateObj.fitbit && trackerDateObj.fitbit.waterID
			? trackerDateObj.fitbit.waterID.toString()
			: false;
	const waterStr = water.toFixed(1);

	//If there is a waterID (previously saved water log on this date, then update instead of saving a new entry)
	if (typeof waterID === 'string') {
		return fetch(
			`https://api.fitbit.com/1/user/-/foods/log/water/${waterID}.json?amount=${waterStr}&date=${date}`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${access_token}`,
					'Accept-Language': `${metric ? '' : 'en_US'}`
				}
			}
		).catch((err) => {
			console.error('Error: ', err);
		});
	} else {
		//No previous water entry, save a new one
		return fetch(
			`https://api.fitbit.com/1/user/-/foods/log/water.json?amount=${waterStr}&date=${date}`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${access_token}`,
					'Accept-Language': `${metric ? '' : 'en_US'}`
				}
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				//If Token Expired, Re-Auth, Make sure if auth is denied there isn't infinity loop
				if (
					res.success === false &&
					res.errors[0].errorType === 'invalid_token'
				) {
					!isAuthCallback &&
						dispatch(
							fitbit_logIn((token) =>
								fitbit_saveWater(token, date, water, metric, true)
							)
						);
					return false;
				}
				updates[`${date}/fitbit/waterID`] = res.waterLog.logId;
				ref.update(updates);
			})
			.catch((err) => {
				console.error('Error: ', err);
			});
	}
};

export const fitbit_deleteWater = (access_token: string, waterID: string) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const trackerDateObj = getTrackerData(getState());
	const waterID =
		trackerDateObj.fitbit && trackerDateObj.fitbit.waterID
			? trackerDateObj.fitbit.waterID.toString()
			: false;
	return fetch(
		`https://api.fitbit.com/1/user/-/foods/log/water/${waterID}.json?`,
		{
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${access_token}`
			}
		}
	).catch((err) => {
		console.error('Error: ', err);
	});
};*/

export const fitbit_saveMacros = (
	access_token: string,
	date: string,
	macroTotals: Object,
	time: string,
	logID?: string | boolean = false
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	//const { date } = state.log;
	const ref = firebase.database().ref(`userdata/${uid}/trackerdate/`);
	let updates = {};
	const calories = macroTotals.calories.toFixed(0);
	const fat = macroTotals.fats.toFixed(1);
	const totalcarbs = macroTotals.totalcarbs.toFixed(1); //totalcarbs
	const fiber = macroTotals.fiber.toFixed(1);
	const protein = macroTotals.proteins.toFixed(1);
	const getMeal = (mealName) => {
		switch (mealName) {
			case 'breakfast':
				return '1';
			case 'lunch':
				return '3';
			case 'dinner':
				return '5';
			case 'snacks':
				return '7';
			default:
				return '7';
		}
	};
	const mealNumber = getMeal(time);
	const timeFormat = time.charAt(0).toUpperCase() + time.slice(1);
	const foodName = `MyDash%20${timeFormat}`;
	console.log("logID", logID)
	if (typeof logID === 'string') {
		console.log('Delete,', logID);
		fitbit_deleteMacros(access_token, logID);
	}

	//No previous meal time entry, save a new one
	return fetch(
		`https://api.fitbit.com/1/user/-/foods/log.json?foodName=${foodName}&mealTypeId=${mealNumber}&date=${date}&amount=1&unitId=147&calories=${calories}&totalFat=${fat}&totalCarbohydrate=${totalcarbs}&dietaryFiber=${fiber}&protein=${protein}`,
		{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${access_token}`
			}
		}
	)
		.then((res) => res.json())
		.then((res) => {
			//If Token Expired, Re-Auth, Make sure if auth is denied there isn't infinity loop
			console.log("res",res);
			if (
				res.success === false &&
				res.errors[0].errorType === 'invalid_token'
			) {
				dispatch(fitbit_logIn());
				return false;
			}

			dispatch({ type: FITBIT_SYNCED });
			updates[`${date}/fitbit/${time}`] = res.foodLog.logId;
			ref.update(updates);
		})
		.catch((err) => {
			console.error('Error: ', err);
		});
};

export const fitbit_deleteMacros = (
	access_token: string,
	foodLogId: string
) => {
	return fetch(`https://api.fitbit.com/1/user/-/foods/log/${foodLogId}.json`, {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${access_token}`
		}
	}).catch((err) => {
		console.error('Error: ', err);
	});
};

export const fitbit_getExercise = (
	access_token: string,
	date: string,
	formCallback: Function,
	isAuthCallback?: boolean
) => (dispatch) => {
	return fetch(`https://api.fitbit.com/1/user/-/activities/date/${date}.json`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${access_token}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((res) => {
			console.log(res);
			//If Token Expired, Re-Auth, Make sure if auth is denied there isn't infinity loop
			if (
				res.success === false &&
				res.errors[0].errorType === 'invalid_token'
			) {
				!isAuthCallback &&
					dispatch(
						fitbit_logIn((token) => fitbit_getExercise(token, date, true))
					);
				return false;
			}
			//Get Calories Burned
			let exercise = 0;
			if (res.activities.length > 0) {
				for (let i = 0; i < res.activities.length; i++) {
					exercise += res.activities[i].calories;
				}
			}
			Toast.show(`Fitbit ${i18n.t('calories')} ${exercise} kCal`, {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
			return formCallback(exercise.toString());
		})
		.catch((err) => {
			console.error('Error: ', err);
		});
};
