//@flow
// leave off @2x/@3x
const images = {
	logo: require('../../assets/images/icon.png'),
	logoTrans: require('../../assets/images/logo.png'),
	iconCutOut: require('../../assets/images/iconCutOut.png'),
	edamam: require('../../assets/images/edamam.png'),
	logoText: require('../../assets/images/logoText.png'),
	caloricneeds: require('../../assets/images/caloricneeds.png'),
	foodchart: require('../../assets/images/foodchart.png'),

	//foodlist
	bread: require('../../assets/images/foods/bread.jpg'),
	rolls: require('../../assets/images/foods/rolls.jpg'),
	pasta: require('../../assets/images/foods/pasta.jpg'),
	emuffin: require('../../assets/images/foods/emuffin.jpg'),
	pita: require('../../assets/images/foods/pita.jpg'),
	bagel: require('../../assets/images/foods/bagel.jpg'),
	cereal: require('../../assets/images/foods/cereal.jpg'),
	grits: require('../../assets/images/foods/grits.jpg'),
	oatmeal: require('../../assets/images/foods/oatmeal.jpg'),
	brownrice: require('../../assets/images/foods/brownrice.jpg'),
	popcorn: require('../../assets/images/foods/popcorn.jpg'),
	pretzel: require('../../assets/images/foods/pretzel.jpg'),
	corntortilla: require('../../assets/images/foods/corntortilla.jpg'),
	wheattorilla: require('../../assets/images/foods/wheattorilla.jpg'),
	barley: require('../../assets/images/foods/barley.jpg'),
	bulgur: require('../../assets/images/foods/bulgur.jpg'),
	buckwheat: require('../../assets/images/foods/buckwheat.jpg'),
	quinoa: require('../../assets/images/foods/quinoa.jpg'),
	spelt: require('../../assets/images/foods/spelt.jpg'),
	wildrice: require('../../assets/images/foods/wildrice.jpg'),
	asparagus: require('../../assets/images/foods/asparagus.jpg'),
	artichoke: require('../../assets/images/foods/artichoke.jpg'),
	beetgreens: require('../../assets/images/foods/beetgreens.jpg'),
	bellpepper: require('../../assets/images/foods/bellpepper.jpg'),
	broccoli: require('../../assets/images/foods/broccoli.jpg'),
	brusselsprouts: require('../../assets/images/foods/brusselsprouts.jpg'),
	cabbage: require('../../assets/images/foods/cabbage.jpg'),
	carrot: require('../../assets/images/foods/carrot.jpg'),
	cauliflower: require('../../assets/images/foods/cauliflower.jpg'),
	celery: require('../../assets/images/foods/celery.jpg'),
	chard: require('../../assets/images/foods/chard.jpg'),
	corn: require('../../assets/images/foods/corn.jpg'),
	cucumber: require('../../assets/images/foods/cucumber.jpg'),
	eggplant: require('../../assets/images/foods/eggplant.jpg'),
	greenbean: require('../../assets/images/foods/greenbean.jpg'),
	jicama: require('../../assets/images/foods/jicama.jpg'),
	kale: require('../../assets/images/foods/kale.jpg'),
	leeks: require('../../assets/images/foods/leeks.jpg'),
	salad: require('../../assets/images/foods/salad.jpg'),
	limabeans: require('../../assets/images/foods/limabeans.jpg'),
	mushrooms: require('../../assets/images/foods/mushrooms.jpg'),
	onion: require('../../assets/images/foods/onion.jpg'),
	peas: require('../../assets/images/foods/peas.jpg'),
	potatoe: require('../../assets/images/foods/potatoe.jpg'),
	radish: require('../../assets/images/foods/radish.jpg'),
	spinach: require('../../assets/images/foods/spinach.jpg'),
	squash: require('../../assets/images/foods/squash.jpg'),
	sweetpotatoe: require('../../assets/images/foods/sweetpotatoe.jpg'),
	tomatoe: require('../../assets/images/foods/tomatoe.jpg'),
	apple: require('../../assets/images/foods/apple.jpg'),
	apricot: require('../../assets/images/foods/apricot.jpg'),
	banana: require('../../assets/images/foods/banana.jpg'),
	berries: require('../../assets/images/foods/berries.jpg'),
	cherry: require('../../assets/images/foods/cherry.jpg'),
	dates: require('../../assets/images/foods/dates.jpg'),
	fig: require('../../assets/images/foods/fig.jpg'),
	grapes: require('../../assets/images/foods/grapes.jpg'),
	grapefruit: require('../../assets/images/foods/grapefruit.jpg'),
	kiwi: require('../../assets/images/foods/kiwi.jpg'),
	lemon: require('../../assets/images/foods/lemon.jpg'),
	mango: require('../../assets/images/foods/mango.jpg'),
	melon: require('../../assets/images/foods/melon.jpg'),
	orange: require('../../assets/images/foods/orange.jpg'),
	papaya: require('../../assets/images/foods/papaya.jpg'),
	peach: require('../../assets/images/foods/peach.jpg'),
	pear: require('../../assets/images/foods/pear.jpg'),
	pineapple: require('../../assets/images/foods/pineapple.jpg'),
	plum: require('../../assets/images/foods/plum.jpg'),
	prune: require('../../assets/images/foods/prune.jpg'),
	raisin: require('../../assets/images/foods/raisin.jpg'),
	strawberry: require('../../assets/images/foods/strawberry.jpg'),
	tangerine: require('../../assets/images/foods/tangerine.jpg'),
	buttermilk: require('../../assets/images/foods/buttermilk.jpg'),
	hardcheese: require('../../assets/images/foods/hardcheese.jpg'),
	softcheese: require('../../assets/images/foods/softcheese.jpg'),
	cottagecheese: require('../../assets/images/foods/cottagecheese.jpg'),
	milk: require('../../assets/images/foods/milk.jpg'),
	kefir: require('../../assets/images/foods/kefir.jpg'),
	mozzarella: require('../../assets/images/foods/mozzarella.jpg'),
	sourcream: require('../../assets/images/foods/sourcream.jpg'),
	yogurt: require('../../assets/images/foods/yogurt.jpg'),
	sirloin: require('../../assets/images/foods/sirloin.jpg'),
	chickenb: require('../../assets/images/foods/chickenb.jpg'),
	delimeat: require('../../assets/images/foods/delimeat.jpg'),
	eggs: require('../../assets/images/foods/eggs.jpg'),
	groundb: require('../../assets/images/foods/groundb.jpg'),
	wfish: require('../../assets/images/foods/wfish.jpg'),
	pork: require('../../assets/images/foods/pork.jpg'),
	salmon: require('../../assets/images/foods/salmon.jpg'),
	shrimp: require('../../assets/images/foods/shrimp.jpg'),
	almonds: require('../../assets/images/foods/almonds.jpg'),
	beans: require('../../assets/images/foods/beans.jpg'),
	cashews: require('../../assets/images/foods/cashews.jpg'),
	hazelnuts: require('../../assets/images/foods/hazelnuts.jpg'),
	lentils: require('../../assets/images/foods/lentils.jpg'),
	nutbutter: require('../../assets/images/foods/nutbutter.jpg'),
	peanuts: require('../../assets/images/foods/peanuts.jpg'),
	pecans: require('../../assets/images/foods/pecans.jpg'),
	seeds: require('../../assets/images/foods/seeds.jpg'),
	soynuts: require('../../assets/images/foods/soynuts.jpg'),
	splitpeas: require('../../assets/images/foods/splitpeas.jpg'),
	walnuts: require('../../assets/images/foods/walnuts.jpg'),
	dressing: require('../../assets/images/foods/dressing.jpg'),
	mayo: require('../../assets/images/foods/mayo.jpg'),
	oil: require('../../assets/images/foods/oil.jpg'),
	gelatin: require('../../assets/images/foods/gelatin.jpg'),
	fruitice: require('../../assets/images/foods/fruitice.jpg'),
	candy: require('../../assets/images/foods/candy.jpg'),
	jelly: require('../../assets/images/foods/jelly.jpg'),
	syrup: require('../../assets/images/foods/syrup.jpg'),
	sorbet: require('../../assets/images/foods/sorbet.jpg'),
	basil: require('../../assets/images/foods/basil.jpg'),
	cayenne: require('../../assets/images/foods/cayenne.jpg'),
	cilantro: require('../../assets/images/foods/cilantro.jpg'),
	coriander: require('../../assets/images/foods/coriander.jpg'),
	cumin: require('../../assets/images/foods/cumin.jpg'),
	garlic: require('../../assets/images/foods/garlic.jpg'),
	garlicpowder: require('../../assets/images/foods/garlicpowder.jpg'),
	onionpowder: require('../../assets/images/foods/onionpowder.jpg'),
	parsley: require('../../assets/images/foods/parsley.jpg'),
	pepper: require('../../assets/images/foods/pepper.jpg'),
	rosemary: require('../../assets/images/foods/rosemary.jpg'),
	salt: require('../../assets/images/foods/salt.jpg'),
	stevia: require('../../assets/images/foods/stevia.jpg'),
	thyme: require('../../assets/images/foods/thyme.jpg'),
	vinegar: require('../../assets/images/foods/vinegar.jpg'),

	seventeen: require('../../assets/images/OtherDiets/17day.png'),
	myfodmaps: require('../../assets/images/OtherDiets/myfodmaps.jpg'),
	alkaline: require('../../assets/images/OtherDiets/alkaline.png'),
	dash: require('../../assets/images/OtherDiets/dash.png'),
	fertility: require('../../assets/images/OtherDiets/fertility.png'),
	glutenFree: require('../../assets/images/OtherDiets/glutenfree.png'),
	hcg: require('../../assets/images/OtherDiets/hcg.png'),
	hmr: require('../../assets/images/OtherDiets/hmr.png'),
	macrobiotic: require('../../assets/images/OtherDiets/macrobiotic.png'),
	mediterranean: require('../../assets/images/OtherDiets/mediterranean.png'),
	paleo: require('../../assets/images/OtherDiets/paleo.png'),
	rawFood: require('../../assets/images/OtherDiets/rawfood.png'),
	keto: require('../../assets/images/OtherDiets/keto.png'),
	glycemic: require('../../assets/images/OtherDiets/glycemic.png'),
	peanut: require('../../assets/images/OtherDiets/peanut.png'),
	pescatarian: require('../../assets/images/OtherDiets/pescatarian.png'),
	kidney: require('../../assets/images/OtherDiets/kidney.png'),
	kosher: require('../../assets/images/OtherDiets/kosher.png'),
	immune: require('../../assets/images/OtherDiets/immune.png'),
	cocktail: require('../../assets/images/OtherDiets/cocktail.png'),
	redmeat: require('../../assets/images/OtherDiets/redmeat.png'),
	dairy: require('../../assets/images/OtherDiets/dairy.png'),
	caribbean: require('../../assets/images/OtherDiets/caribbean.png'),
	chinese: require('../../assets/images/OtherDiets/chinese.png'),
	easterneurope: require('../../assets/images/OtherDiets/easterneurope.png'),
	french: require('../../assets/images/OtherDiets/french.png'),
	indian: require('../../assets/images/OtherDiets/indian.png'),
	bodyFatEstimate: require('../../assets/images/Calculator/bodyfatestimate.jpg'),
	fatsecret: require('../../assets/images/fatsecret.png'),
	appleHealthKit: require('../../assets/images/fitnesstrackers/appleHealthKit.png'),
	gFit: require('../../assets/images/fitnesstrackers/gFit.png'),
	fitBit: require('../../assets/images/fitnesstrackers/fitBit.png'),
	samsungHealth: require('../../assets/images/fitnesstrackers/samsungHealth.png'),
	login: require('../../assets/images/login.jpg'),
	gplay: require('../../assets/images/gplay.png'),
	ios: require('../../assets/images/ios.png'),
	purchase1a: require('../../assets/images/Purchase/1a.jpg'),
	purchase1b: require('../../assets/images/Purchase/1b.jpg'),
	purchase2a: require('../../assets/images/Purchase/2a.jpg'),
	purchase2b: require('../../assets/images/Purchase/2b.jpg'),
	purchase3a: require('../../assets/images/Purchase/3a.jpg'),
	purchase3b: require('../../assets/images/Purchase/3b.jpg'),
	purchase3c: require('../../assets/images/Purchase/3c.jpg'),
	purchase4: require('../../assets/images/Purchase/4.jpg')
};

export default images;
