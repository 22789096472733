import {
	REQUEST_FATSECRET_FOOD,
	RECEIVE_FATSECRET_FOOD,
	SERVING_WHEEL_CHANGE_FS,
	SERVING_WHEEL_CANCEL_FS,
	SERVING_WHEEL_CONFIRM_FS,
	SERVING_INPUT_QTY_CHANGE_FS,
	SERVING_INPUT_LABEL_CHANGE_FS,
	SWITCH_SERVING_SELECTOR_FS,
	PRELOAD_SERVING_WHEEL
} from './types';
import {
	calculateMacros,
	calculateMultiplier,
	calculatePlaceholder
} from 'Utilities/ServingSelectorLogic';
import jsSHA from 'jssha';
import { Platform } from 'react-native';
const REQUEST_URL = 'http://platform.fatsecret.com/rest/server.api';
const REQUEST_METHOD = 'GET';
const OAUTH_SIGNATURE_METHOD = 'HMAC-SHA1';
const OAUTH_VERSION = '1.0';
const FORMAT = 'json';
const OAUTH_CONSUMER_KEY = '0c349309114f4ee784900151e0f4039b';
const SHARED_SECRET = '785e42225de34903943895bee51b4cc5';

export const servingWheelChange = (currentWheelValue) => {
	const multiplier = calculateMultiplier(currentWheelValue);
	const placeholder = calculatePlaceholder(currentWheelValue, false);

	return {
		type: SERVING_WHEEL_CHANGE_FS,
		payload: multiplier,
		placeholder,
		currentWheelValue
	};
};

export const servingWheelCancel = (prevConfirmWheel) => {
	const multiplier = calculateMultiplier(prevConfirmWheel);
	const placeholder = calculatePlaceholder(prevConfirmWheel, false);

	return {
		type: SERVING_WHEEL_CANCEL_FS,
		payload: multiplier,
		prevConfirmWheel,
		placeholder
	};
};

export const servingWheelConfirm = (confirmedValue) => {
	return {
		type: SERVING_WHEEL_CONFIRM_FS,
		confirmedValue
	};
};

export const servingInputQtyChange = (multiplier) => {
	return {
		type: SERVING_INPUT_QTY_CHANGE_FS,
		payload: multiplier
	};
};

export const servingInputLabelChange = (newLabel) => {
	return {
		type: SERVING_INPUT_LABEL_CHANGE_FS,
		payload: newLabel
	};
};

export const servingSelectTypeChange = (selectType) => {
	return {
		type: SWITCH_SERVING_SELECTOR_FS,
		payload: selectType
	};
};

export const requestFatSecretFood = () => {
	return {
		type: REQUEST_FATSECRET_FOOD
	};
};

export const receiveFatSecretFood = (response, preloadServing = false) => {
	let servingLabels = [];
	let CurrentServingData = [];
	if (response.food.brand_name) {
		const brandServingReplaced =
			response.food.servings.serving.serving_description;
		servingLabels.push(brandServingReplaced);
		if (
			response.food.servings.serving.metric_serving_unit &&
			response.food.servings.serving.metric_serving_unit !== 'ml'
		) {
			servingLabels.push('g', 'oz');
		} else if (
			response.food.servings.serving.metric_serving_unit &&
			response.food.servings.serving.metric_serving_unit === 'ml'
		) {
			servingLabels.push('ml', 'fl oz');
		}
	} else {
		for (let i = 0; i < response.food.servings.serving.length; i++) {
			servingLabels.push(
				response.food.servings.serving[i].measurement_description
			);
		}
	}

	let preloadedServing = preloadServing
		? editApiPreloadServing(preloadServing)
		: null;
	CurrentServingData = preloadServing
		? calculateMacros(response.food, preloadedServing[2], preloadedServing[0])
		: calculateMacros(response.food, servingLabels[0], 1);
	const placeholder = preloadServing
		? calculatePlaceholder(editApiPreloadServing(preloadServing), false)
		: '1 x ' + servingLabels[0];
	const lastValueConfirmed = preloadServing
		? editApiPreloadServing(preloadServing)
		: [1, '-', servingLabels[0]];

	return {
		type: RECEIVE_FATSECRET_FOOD,
		payload: response.food,
		CurrentServingData,
		servingLabels,
		placeholder,
		lastValueConfirmed
	};
};

export const fetchFatSecretFood = (foodID, preloadServing = false) => {
	const METHOD = 'food.get';
	const OAUTH_TIMESTAMP = new Date().getTime();
	const OAUTH_NONCE = '' + new Date().getTime();
	const FOOD_ID = foodID;

	// Create a Signature Base String
	const REQUEST_URL_ENCODED = encodeURIComponent(REQUEST_URL);
	let NORMALISED_PARAMETERS = 'food_id=' + FOOD_ID;
	NORMALISED_PARAMETERS += '&format=' + FORMAT;
	NORMALISED_PARAMETERS += '&method=' + METHOD;
	NORMALISED_PARAMETERS += '&oauth_consumer_key=' + OAUTH_CONSUMER_KEY;
	NORMALISED_PARAMETERS += '&oauth_nonce=' + OAUTH_NONCE;
	NORMALISED_PARAMETERS += '&oauth_signature_method=' + OAUTH_SIGNATURE_METHOD;
	NORMALISED_PARAMETERS += '&oauth_timestamp=' + OAUTH_TIMESTAMP;
	NORMALISED_PARAMETERS += '&oauth_version=' + OAUTH_VERSION;

	const NORMALISED_PARAMETERS_ENCODED = encodeURIComponent(
		NORMALISED_PARAMETERS
	);

	const BASE_STRING =
		REQUEST_METHOD +
		'&' +
		REQUEST_URL_ENCODED +
		'&' +
		NORMALISED_PARAMETERS_ENCODED;

	const OAUTH_SIGNATURE = new jsSHA(BASE_STRING, 'TEXT');
	const OAUTH_SIGNATURE_BASE64 = OAUTH_SIGNATURE.getHMAC(
		encodeURIComponent(SHARED_SECRET) + '&',
		'TEXT',
		'SHA-1',
		'B64'
	);
	const OAUTH_SIGNATURE_BASE64_ENCODED = encodeURIComponent(
		OAUTH_SIGNATURE_BASE64
	);
	const OAUTH_REQUEST_URL =
		REQUEST_URL +
		'?' +
		NORMALISED_PARAMETERS +
		'&oauth_signature=' +
		OAUTH_SIGNATURE_BASE64_ENCODED;
	return (dispatch) => {
		dispatch(requestFatSecretFood());
		if (Platform.OS === 'web') {
			var href = new URL(
				'https://us-central1-mydashdiet.cloudfunctions.net/fatsecret/fatsecret'
			);
			href.searchParams.set('url', OAUTH_REQUEST_URL);
			return fetch(`${href.toString()}`, {
				method: 'GET'
			})
				.then((response) => response.json())
				.then((json) => dispatch(receiveFatSecretFood(json)))
				.catch((error) => {
					console.error(error);
				});
		} else {
			return fetch(OAUTH_REQUEST_URL)
				.then((response) => response.json())
				.then((json) => dispatch(receiveFatSecretFood(json, preloadServing)));
		}
	};
};

export const editApiPreloadServing = (itemName) => {
	const wholeDecimal = itemName.match(/([\d.]+) *x/)[1];
	const correctLabel = itemName
		.slice(itemName.indexOf('x') + 'x'.length)
		.trim();
	return [+wholeDecimal, '-', correctLabel];
};
