//@flow
import React from 'react';
import { ScrollView, Linking } from 'react-native';
import { Paragraph, Heading, Divider, Text, BlockQuote } from '../common';
import { i18n } from '../../constants';
import styles from './Styles/Styles';

export const Faq = ({ theme }) => {
	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('faqh2')}
			</Heading>
			<Paragraph>{i18n.t('faqp1')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('faqh3')}
			</Heading>
			<Paragraph>{i18n.t('faqp2')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('faqh4')}
			</Heading>
			<Paragraph>{i18n.t('faqp3')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('faqh5')}
			</Heading>
			<Paragraph>{i18n.t('faqp4')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('faqh6')}
			</Heading>
			<Paragraph>{i18n.t('faqp5')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('faqh7')}
			</Heading>
			<Paragraph>{i18n.t('faqp6')}</Paragraph>
			<Divider />
			<Paragraph>{i18n.t('faqp7')}</Paragraph>
			<Divider />
			<BlockQuote
				ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}
				style={{ paddingTop: 0, alignItems: 'center' }}>
				<Text
					style={styles.linkText}
					onPress={() =>
						Linking.openURL(
							'https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/dash-diet/art-20048456'
						)
					}>
					{'\n'}Source
				</Text>
				<Text
					style={styles.linkText}
					onPress={() =>
						Linking.openURL(
							'https://www.nhlbi.nih.gov/health-topics/dash-eating-plan'
						)
					}>
					{'\n'}Source 2
				</Text>
				<Text
					style={styles.linkText}
					onPress={() =>
						Linking.openURL(
							'https://www.webmd.com/hypertension-high-blood-pressure/guide/dash-diet#1'
						)
					}>
					{'\n'}Source 3
				</Text>
			</BlockQuote>
		</ScrollView>
	);
};
