//@flow
import React from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { i18n } from 'Theme';
import { updateUnitsFormat } from './actions';
import { Text, OptionSelector } from 'Components/common';
import Elevations from 'react-native-elevation';

const glucoseOptions = [
	{ label: 'mmol/L', value: 'mmol/L' },
	{ label: 'mg/dL', value: 'mg/dL' }
];

const waterOptions = [
	{ label: 'oz', value: 'oz' },
	{ label: 'ml', value: 'ml' }
];

const exerciseOptions = [
	{ label: 'kCal', value: 'kCal' },
	{ label: 'kJ', value: 'kJ' }
];
const ketoneOptions = [
	{ label: 'mmol/L', value: 'mmol/L' },
	{ label: 'mg/dL', value: 'mg/dL' }
];

export const PreferencesScreen = ({ route, navigation }) => {
	const dispatch = useDispatch();

	const {
		bodymeasurementsUnits,
		glucoseUnits,
		ketonesUnits,
		exerciseUnits,
		waterUnits,
		carbUnits
	} = useSelector((state) => state.firebase.profile.settings.units);
	const { theme } = useSelector((state) => state.startup);
	const bodymeasurementsOptions = [
		{ label: i18n.t('imperial'), value: 'imperial' },
		{ label: i18n.t('metric'), value: 'metric' }
	];

	/*const carbOptions = [
		{ label: i18n.t('net'), value: 'net' },
		{ label: i18n.t('total'), value: 'total' }
	];*/

	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<View style={styles.otherContainer}>
				{/*<<Text
					style={[
						styles.otherHeader,
						{ borderTopColor: theme.border, backgroundColor: theme.grey}
					]}>
					{i18n.t('carbs')}
				</Text>
				<OptionSelector
					onChange={(value) => dispatch(updateUnitsFormat('carbUnits', value))}
					currentValue={carbUnits}
					placeholder={i18n.t('preferredCarb')}
					noIcon={true}
					containerStyle={[styles.dropdownContainer, {borderBottomWidth: 0}]}
					data={carbOptions}
				/>
				Text style={[styles.otherHeader, {		borderTopColor: theme.border,
		backgroundColor: theme.grey,}]}>{i18n.t('otherTracking')}</Text>
				<OptionSwitch
					switchValue={notes}
					onSwitch={(value) => dispatch(updatePreferences('notes', value))}
					text={i18n.t('notes')}
				/>
				<OptionSwitch
					switchValue={water}
					onSwitch={(value) => dispatch(updatePreferences('water', value))}
					text={i18n.t('water')}
				/>
				<OptionSwitch
					switchValue={exercise}
					onSwitch={(value) => dispatch(updatePreferences('exercise', value))}
					text={i18n.t('exercise')}
				/>
				<OptionSwitch
					switchValue={ketones}
					onSwitch={(value) => dispatch(updatePreferences('ketones', value))}
					text={i18n.t('ketones')}
				/>
				<OptionSwitch
					switchValue={bloodglucose}
					onSwitch={(value) =>
						dispatch(updatePreferences('bloodglucose', value))
					}
					text={i18n.t('bloodGlucose')}
				/>*/}
				<Text
					style={[
						styles.otherHeader,
						{ borderTopColor: theme.border, backgroundColor: theme.grey }
					]}>
					{i18n.t('units')}
				</Text>
				<OptionSelector
					onChange={(value) =>
						dispatch(updateUnitsFormat('bodymeasurementsUnits', value))
					}
					currentValue={bodymeasurementsUnits}
					placeholder={i18n.t('weightUnits')}
					noIcon={true}
					containerStyle={styles.dropdownContainer}
					data={bodymeasurementsOptions}
				/>
				<OptionSelector
					onChange={(value) => dispatch(updateUnitsFormat('waterUnits', value))}
					currentValue={waterUnits}
					placeholder={i18n.t('water')}
					noIcon={true}
					containerStyle={styles.dropdownContainer}
					data={waterOptions}
				/>
				<OptionSelector
					onChange={(value) =>
						dispatch(updateUnitsFormat('exerciseUnits', value))
					}
					currentValue={exerciseUnits}
					placeholder={i18n.t('exercise')}
					noIcon={true}
					containerStyle={styles.dropdownContainer}
					data={exerciseOptions}
				/>
				{/*<OptionSelector
					onChange={(value) =>
						dispatch(updateUnitsFormat('ketonesUnits', value))
					}
					currentValue={ketonesUnits}
					placeholder={i18n.t('ketones')}
					noIcon={true}
					containerStyle={styles.dropdownContainer}
					data={ketoneOptions}
				/>*/}
				<OptionSelector
					onChange={(value) =>
						dispatch(updateUnitsFormat('glucoseUnits', value))
					}
					currentValue={glucoseUnits}
					placeholder={i18n.t('bloodGlucose')}
					noIcon={true}
					containerStyle={styles.dropdownContainer}
					data={glucoseOptions}
				/>

				{/*<OptionSwitch switchValue={morenutrients} onSwitch={value => updatePreferences("morenutrients", value)} text="Track Nutrients" />
					<OptionSwitch switchValue={fiber} onSwitch={value => updatePreferences("fiber", value)} text="Track Fiber" /> */}
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	//Settings Main------------------------
	otherContainer: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'stretch'
	},
	otherHeader: {
		flex: 1,
		fontSize: 15,
		paddingTop: 12,
		paddingBottom: 12,
		paddingLeft: 15,
		...Elevations[2]
	},
	dropdownContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingTop: 7,
		paddingBottom: 7,
		alignItems: 'center',
		paddingLeft: 15
	}
});
