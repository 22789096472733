//@flow
import React, { useState, useEffect } from 'react';
import { ScrollView, View, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
	logEntry,
	deletePreset,
	saveEditSingleFood,
	favoriteAddItem,
	quickRemoveFavorite
} from './actions';
import { resetItem } from 'Redux/actions';
import { SingleViewButtons } from './components';
import {
	Header,
	ServingSelector,
	MacroGrid,
	Nutrients,
	ClickableHeader,
	Collapsible,
	Button
} from 'Components/common';
import { EditingItemModal } from 'Components/modal/EditingItemModal';
import { ErrorText } from 'Components/common';
import Picker from 'react-native-picker';
import { servingFoodSelector } from './selectors';
import { defaultNutrients } from 'Redux/selectors';
import { i18n } from 'Theme';
import { Feature } from 'Components/purchase/components';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

export const CustomFoodTabSingle = ({ route, navigation }) => {
	const dispatch = useDispatch();
	const {
		customfoodsv3 = {},
		settings: { units },
		premium
	} = useSelector((state) => state.firebase.profile);
	const { mealTime, date, editMealTime } = useSelector((state) => state.log);
	const item = customfoodsv3[route.params.foodID];
	const { theme } = useSelector((state) => state.startup);
	const servingData = useSelector((state) => servingFoodSelector(state, route));
	const [isEditing, toggleEditing] = useState(false);
	const [viewNutrients, toggleNutrients] = useState(false);

	useEffect(() => {
		const { params = {} } = route;
		const { editingMeal, manage } = params;
		if (typeof item !== 'undefined') {
			const onFavorite =
				item.favorite === true
					? () => dispatch(quickRemoveFavorite(item))
					: () => dispatch(favoriteAddItem(item));
			const favText =
				item.favorite === true
					? i18n.t('removeFavorite')
					: i18n.t('addFavorite');
			const iconColor = item.favorite === true ? '#f1c40f' : theme.darkFont;
			if (manage || editingMeal) {
				navigation.setParams({
					menuArray: [
						{
							iconText: favText,
							onPress: onFavorite,
							iconName: 'star',
							iconColor
						},
						{
							iconText: i18n.t('editFood'),
							onPress: () => toggleEditing(true),
							iconName: 'edit',
							iconColor: theme.darkFont
						},
						{
							iconText: i18n.t('deleteFood'),
							onPress: () => dispatch(deletePreset(item, true)),
							iconName: 'trash',
							iconColor: theme.darkFont
						}
					]
				});
			} else if (params.editingLogEntry) {
				navigation.setParams({
					menuArray: [
						{
							iconText: favText,
							onPress: onFavorite,
							iconName: 'star',
							iconColor
						}
					]
				});
			} else {
				const addTo = mealTime.charAt(0).toUpperCase() + mealTime.slice(1);
				navigation.setParams({
					menuArray: [
						/*{
							iconText: `${i18n.t('addTo')} ${addTo}`,
							onPress: () =>
								dispatch(logEntry(servingData.servingMacros, date, mealTime)),
							iconName: 'plus',
							iconColor: theme.darkFont
						},*/
						{
							iconText: favText,
							onPress: onFavorite,
							iconName: 'star',
							iconColor
						},
						{
							iconText: i18n.t('editFood'),
							onPress: () => toggleEditing(true),
							iconName: 'edit',
							iconColor: theme.darkFont
						},
						{
							iconText: i18n.t('deleteFood'),
							onPress: () => dispatch(deletePreset(item, true)),
							iconName: 'trash',
							iconColor: theme.darkFont
						}
					]
				});
			}
		}
		return () => {
			if (Platform.OS !== 'web') {
				Picker.hide();
			}
			dispatch(resetItem('food'));
		};
	}, [customfoodsv3]);

	//Save the Edit of the Custom Food, Check if Name Exists and Overwrite it, else the cancel does nothing and does not save the edits.
	const saveEdit = (formValues, originalItem) => {
		dispatch(saveEditSingleFood(formValues, originalItem)).then(() => {
			toggleEditing(false);
		});
	};

	if (!item) {
		return <View />;
	}

	if (item) {
		return (
			<ScrollView
				style={{ flex: 1, backgroundColor: theme.baseBG }}
				keyboardShouldPersistTaps="handled">
				<Header title={item.name} bgColor={theme.base} />
				<ServingSelector
					reducerType={'food'}
					labelArray={[
						item.customServingName ? item.customServingName : i18n.t('servings')
					]}
					theme={theme}
				/>
				<MacroGrid
					{...servingData.servingMacros}
					carbUnits={units.carbUnits}
					theme={theme}
				/>
				<ClickableHeader
					leftText="Nutrients:"
					noMargin
					onPress={() => toggleNutrients(!viewNutrients)}
					rightIconName={!viewNutrients ? 'arrow-right' : 'arrow-down'}
					style={{ backgroundColor: theme.base }}
					iconColor={theme.darkFont}
				/>
				<Collapsible collapsed={!viewNutrients}>
					{!premium ? (
						<View>
							<Feature
								icon="check"
								title="Track Nutrients"
								body="With Premium, follow more than just your macros. Keep track of: Cholesterol, Sodium, Calcium, Magnesium, Potassium, Iron, Zinc, Phosphorus, Folate, Thiamin (B1), Riboflavin (B2), Niacin (B3), Vitamin B6, Vitamin B12, Vitamin A,C,D,E,K, "
							/>
							<Button
								onPress={() => navigation.navigate('Purchase')}
								style={[{ backgroundColor: theme.teal }]}>
								{i18n.t('premium')}
							</Button>
						</View>
					) : (
						<Nutrients
							viewMore={viewNutrients}
							toggle={() => toggleNutrients(!viewNutrients)}
							theme={theme}
							nutrients={{
								...defaultNutrients,
								...servingData.servingMacros.nutrientsV2
							}}
						/>
					)}
				</Collapsible>
				<SingleViewButtons
					servingMacros={servingData.servingMacros}
					item={item}
					mealTime={mealTime}
					editMealTime={editMealTime}
					date={date}
					params={route.params}
					theme={theme}
				/>
				<Modal.ReactNativeModal
					isVisible={isEditing}
					style={{
						padding: 0,
						margin: 0
					}}
					animationIn={'slideInUp'}
					animationOut={'slideOutDown'}
					onClosed={() => toggleEditing(false)}>
					<EditingItemModal
						closeModal={() => toggleEditing(false)}
						onSubmit={(values) => saveEdit(values, item)}
						modalTitle={i18n.t('editingItem')}
						confirmText={i18n.t('save')}
						item={{
							name: item.name,
							calories: item.calories.toFixed(0),
							fats: item.fats.toFixed(0),
							proteins: item.proteins.toFixed(0),
							carbs: item.carbs.toFixed(0),
							totalcarbs: item.totalcarbs.toFixed(0),
							fiber: item.fiber.toFixed(0),
							sodium: item.sodium.toFixed(0),
							manualCalories: item.manualCalories,
							nutrientsV2: item.nutrientsV2 || {}
						}}
					/>
				</Modal.ReactNativeModal>
			</ScrollView>
		);
	}

	return <ErrorText showError={!item}>{i18n.t('foodNotFound')}</ErrorText>;
};
