//@flow
import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { multiAddMeals, createMeal } from './actions';
import { HeaderActionButtons, SearchFilter } from './components';
import { FlatListMultiAdd, FlatListSingle } from './components/listview';
import { Prompt } from 'Components/common';
import { createFilter } from 'react-native-search-filter';
import _ from 'lodash';
import { i18n } from 'Theme';

export const CustomMealTab = ({ route, navigation }) => {
	const dispatch = useDispatch();
	const {
		settings: {
			units: { carbUnits }
		},
		savedmealsv3
	} = useSelector((state) => state.firebase.profile);

	const { mealTime, date } = useSelector((state) => state.log);
	const { theme } = useSelector((state) => state.startup);

	const [multiSelect, setMultiSelect] = useState(false);
	const [selected, setSelected] = useState([]);
	const [modalVisible, toggleModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	//Submit Multiple Log Adds, Loops through each selected and makes a Firebase Set Call, then Navigates back to log.
	const multiAdd = (selected) => {
		dispatch(
			multiAddMeals(selected, date, mealTime, () => setMultiSelect(false))
		);
	};

	const submitMealDialog = (createMealName) => {
		dispatch(
			createMeal(createMealName, date, mealTime, route.params.manage, () =>
				toggleModal(false)
			)
		);
	};

	const searchUpdated = (term) => setSearchTerm(term);

	//Convert Object of Objects Meal Items Into Array of Meal Objects
	const arr = _.values(savedmealsv3);

	//Sort Array Alphabetically By Name
	const sortedArr = arr.slice().sort((a, b) => {
		var textA = a.name.toUpperCase();
		var textB = b.name.toUpperCase();
		return textA < textB ? -1 : textA > textB ? 1 : 0;
	});

	const determineRoute = (currentRoute) => {
		let nextRoute = '';
		if (currentRoute === 'ManageMeal') {
			// Add Food Stack -> Search Tab -> Single Search
			nextRoute = 'ManageMealSingle';
		} else if (currentRoute === 'MealEditingMeal') {
			// Add Food Stack -> Meal Tab -> Editing Meal -> Single Search
			nextRoute = 'MealEditingSingleMeal';
		} else if (currentRoute === 'ManageMealEditingMeal') {
			//Manage -> Meal Tab -> Editing Meal -> Search Single
			nextRoute = 'ManageMealEditingSingleMeal';
		} else {
			//Net Carb Look-Up Search Single
			nextRoute = 'CustomMealSingle';
		}
		return nextRoute;
	};

	const searchFilter = sortedArr.filter(createFilter(searchTerm, 'name'));

	return (
		<View style={{ flex: 1, backgroundColor: theme.base }}>
			<ScrollView keyboardShouldPersistTaps="handled">
				<HeaderActionButtons
					onCreate={() => toggleModal(true)}
					enableMulti={() => setMultiSelect(true)}
					disableMulti={() => setMultiSelect(false)}
					onMulti={() => multiAdd(selected)}
					isMulti={multiSelect}
					isManage={route.params.manage}
					theme={theme}
				/>
				<SearchFilter
					onChangeText={searchUpdated}
					ctnStyle={{
						backgroundColor: theme.baseBG,
						borderBottomColor: theme.border
					}}
					inputStyle={{ backgroundColor: theme.grey, color: theme.darkFont }}
				/>
				{!multiSelect ? (
					<FlatListSingle
						data={searchFilter}
						type="meal"
						routeName={determineRoute(route.name)}
						carbUnits={carbUnits}
						mealTime={mealTime}
						manage={route.params.manage}
						logDate={date}
						theme={theme}
					/>
				) : (
					<FlatListMultiAdd
						listArray={searchFilter}
						onSelectionChange={(selectedRow, allSelectedRows) =>
							setSelected(allSelectedRows)
						}
						type="meal"
						carbUnits={carbUnits}
						mealTime={mealTime}
						logDate={date}
						theme={theme}
					/>
				)}
			</ScrollView>
			<Prompt
				title={i18n.t('createMeal')}
				placeholder={i18n.t('name')}
				defaultValue=""
				visible={modalVisible}
				onCancel={() => toggleModal(false)}
				onSubmit={(value) => submitMealDialog(value)}
				onClosed={() => toggleModal(false)}
				submitText={i18n.t('create')}
				doneSubmit={true}
				theme={theme}
			/>
		</View>
	);
};
