//@flow

//Strings used throughout the app translated into English, French, Spanish, Portuguese, German

export const ptStrings = {
	appName: 'MyKeto',
	log: 'Registro',
	login: 'Conecte-se',
	learn: 'Aprender',
	foodlist: 'Lista de Alimentos',
	recipes: 'Receitas',
	mealplan: 'Plano de refeição',
	otherdiet: 'Outras dietas',
	moreapps: 'Mais aplicativos',
	macrocalculator: 'Calculadora Macro',
	purchase: 'Compra',
	tracker: 'Tracker',
	progress: 'Progresso',
	manage: 'Gerir',
	settings: 'Definições',
	preferences: 'Preferências',
	customgoals: 'Metas personalizadas',
	othertracking: 'Outro rastreamento',
	social: 'Social',
	fitnessTracker: 'Fitness Trackers',
	feedback: 'Feedback / Suporte',
	addFood: 'Adicionar comida',
	addIngredient: 'Adicionar Ingrediente',
	mealsRecipes: 'Refeições / Receitas',
	editLogEntry: 'Editar entrada de registro',
	editMeal: 'Editar Refeição',
	editFood: 'Editar comida',
	deleteFood: 'Excluir comida',
	deleteMeal: 'Apagar Refeição',
	friends: 'Amigos',
	copyMeal: 'Copiar Refeição',
	mealCopied: 'Refeição copiada',
	copyFood: 'Copiar Alimentos',
	copyItems: 'Copiar Itens',
	copyPlus: 'Copiar +',
	copyAll: 'Copiar tudo',
	copied: 'Copiado',
	pending: 'Pendente',
	manageFoods: 'Gerenciar Alimentos',
	manageMeals: 'Gerenciar Refeições',
	macros: 'Macros',
	ingredients: 'Ingredientes',
	directions: 'Instruções',
	nutrition: 'Nutrição',
	nutrients: 'Nutrientes',
	save: 'Salve ',
	cancel: 'Cancelar',
	goback: 'Volte',
	close: 'Fechar',
	delete: 'Excluir',
	saveas: 'Salvar como',
	add: 'Adicionar',
	confirm: 'Confirme',
	addfavorite: 'Adicionar aos favoritos',
	removeFavorite: 'Remover favorito',
	remove: 'Remover',
	removed: 'Removido',
	added: 'Adicionado',
	clear: 'Claro',
	send: 'Enviar',
	required: 'Requeridos',
	macrosAddedToMeal: 'Macros Adicionadas à Refeição',
	ingredientUpdated: 'Ingrediente atualizado',
	addtoMeal: 'Adicionar à refeição',
	saveMeal: 'Salvar refeição',
	addedToMeal: 'Adicionado à refeição',
	mealSaved: 'Refeição salva',
	mealsAdded: 'Refeições Adicionadas',
	addTo: 'Adicionar à',
	addedToLog: 'Adicionado ao registro',
	saveAsFood: 'Salvar como comida',
	updateEntry: 'Atualizar entrada',
	updateIngredient: 'Atualizar Ingrediente',
	otherTrackingUpdated: 'Outro rastreamento atualizado',
	nameEmpty: 'Nome vazio',
	pleaseEnterName: 'Digite um nome',
	selectDate: 'Selecione a data',
	foodNotFound: 'Alimentos não encontrados',
	deleted: 'Excluído',
	deleteConfirm: 'Tem certeza de que deseja excluir?',
	addedToFavorites: 'adicionado aos favoritos',
	favoritesCleared: 'Favoritos limpos',
	removedFromFavorites: 'Removido dos Favoritos',
	savedToFoods: 'Saved To Foods',
	updated: 'Atualizada',
	multiAdd: 'Multi-Add',
	noThanks: 'Não, obrigado',
	giveFeedback: 'Dê retorno',
	rateAppName: 'Avalie MyKeto',
	never: 'Nunca',
	later: 'Mais tarde',
	rate: 'Taxa',
	rateDesc:
		'Se você gosta de usar o MyKeto, se importaria de reservar um segundo para avaliá-lo? Não vai demorar mais do que um minuto. Obrigado pelo seu apoio!',
	rateFeed:
		'Você se importaria de nos fornecer feedback sobre o que gostaria de ver em nosso aplicativo? Obrigado!',
	basics: 'Fundamentos',
	benefits: 'Benefícios',
	sideeffects: 'Efeitos colaterais',
	myths: 'Mitos',
	faq: 'PERGUNTAS FREQUENTES',
	faq1: 'Perguntas frequentes',
	getstarted: 'Iniciar',
	fiber: 'Fibra',
	tips: 'Dicas',
	products: 'Produtos recomendados',
	basicsh1: 'O que é a Dieta Keto?',
	basicsh2: 'Como funciona?',
	basicsh3: 'Por que Keto?',
	basicsp1:
		'A dieta cetogênica ou ceto é muito semelhante a uma dieta pobre em carboidratos, como o Atkins. A principal diferença entre um plano regular de dieta baixa em carboidratos e um plano de dieta cetogênica é a quantidade de carboidratos e proteínas permitidas diariamente.  N  nEm geral, as dietas baixas em carboidratos reduzem a ingestão de carboidratos para menos de 100 gramas; dietas cetogênicas estritas são um subgrupo de dietas com baixo teor de carboidratos que normalmente permitem apenas até 50g de carboidratos por dia.',
	basicsp2:
		'Além disso, a dieta cetogênica é rica em gorduras, proteínas adequadas e baixo teor de carboidratos. Você está trocando os carboidratos da dieta por uma porção maior de gordura e uma ingestão moderada de proteínas.',
	basicsp3:
		'Esta dieta rica em gordura coloca seu corpo em um estado chamado "cetose", onde o corpo produz cetonas no fígado para serem usadas como energia.  N  nNormalmente, os carboidratos são quebrados em glicose, que seu corpo carrega no sangue para fornecer a energia de suas células. Por outro lado, a cetose produz cetonas a partir das grandes quantidades de gordura que estão sendo consumidas, e a presença de carboidratos grandes na dieta realmente interrompe a cetose.',
	basicsp4:
		'Em comparação com a dieta ocidental, que consiste em grande parte de carboidratos altamente refinados, o ceto pode oferecer muitas vantagens para a saúde. A maioria das pessoas inicia uma dieta cetogênica porque deseja perder peso. No entanto, pesquisas científicas sugerem que vai além disso, como reduzir o risco de doenças cardíacas, diabetes, câncer, derrame cerebral e muito mais.  N  nHá muito mais na dieta cetônica do que apenas remover carboidratos, é um estilo de vida sobre a saúde geral. Promove longos e intensos períodos de energia, um aumento de alimentos saudáveis ​​e deliciosos e uma visão geral melhor de sua vida. É facilmente mantido com um excesso de opções e muitas vezes é uma resposta para melhorar o bem-estar que muitas pessoas lutam para compreender no início.  N  nO Keto é difícil e testará sua força de vontade, mas muda a maneira como você vê e pensa sobre a si mesmo, alimentação e saúde em geral.',
	basicsbq:
		'A ingestão de nutrientes em uma dieta cetogênica normalmente resulta em cerca de 70-75% das calorias da gordura, 20-25% da proteína e 5-10% dos carboidratos diariamente.',
	benefitsh0: 'Vantagens',
	benefitsp0:
		'As dietas cetogênicas têm uma longa lista de benefícios e podem ser vistas em uma semana, enquanto se desenvolvem gradualmente ao longo de um período de 3 semanas.',
	benefitsh1: '1. Perda de peso',
	benefitsp1:
		'Quando o corpo está queimando gordura como a principal fonte de energia, ele essencialmente a estará retirando de suas reservas de gordura, já que você está em jejum. Estudos mostram que pessoas em dietas de baixo teor de carboidratos perdem mais peso e mais rápido do que pessoas em dietas de baixa gordura. Isso é verdade mesmo quando as pessoas que fazem dieta com baixo teor de gordura estão restringindo ativamente as calorias.',
	benefitsbq1:
		'Indivíduos gravemente obesos com uma alta prevalência de diabetes ou síndrome metabólica perderam mais peso durante seis meses em uma dieta restrita em carboidratos do que em uma dieta restrita em calorias e gorduras, com uma melhora relativa na sensibilidade à insulina e nos níveis de triglicerídeos, mesmo após ajuste para a quantidade de peso perdido.',
	benefitsbq2:
		'Uma dieta baixa em carboidratos parece ser um método eficaz para perda de peso a curto prazo em adolescentes com excesso de peso e não prejudica o perfil lipídico.',
	benefitsbq3:
		'Um estudo multicêntrico controlado de um ano envolvendo 63 homens e mulheres obesos que foram aleatoriamente designados para uma dieta com baixo teor de carboidratos, alta proteína e alto teor de gordura ou uma dieta de baixa caloria, alto teor de carboidratos e baixo teor de gordura (convencional ) dieta. A dieta pobre em carboidratos produziu uma maior perda de peso (diferença absoluta, aproximadamente 4 por cento) do que a dieta convencional nos primeiros seis meses, mas as diferenças não foram significativas em um ano.',
	benefitsbq4:
		'Uma grande porcentagem da gordura perdida em dietas de baixo teor de carboidratos tende a vir da gordura prejudicial na cavidade abdominal que é conhecida por causar sérios problemas metabólicos.',
	benefitsbq5:
		'Um estudo recente da Escola de Medicina da Universidade Johns Hopkins confirma que uma dieta rica em gordura e baixa em carboidratos não é prejudicial à saúde vascular E resulta em perda de peso mais rápida. Os autores confirmaram que os que faziam dieta no grupo com baixo teor de carboidratos perderam mais peso em um período mais curto do que o grupo com alto teor de carboidratos, e o grupo com baixo teor de carboidratos não teve alterações prejudiciais na saúde vascular.',
	benefitsh2: '2. Baixa pressão arterial',
	benefitsp2:
		'Dietas com baixo teor de carboidratos são muito eficazes na redução da pressão arterial. Fale com o seu médico se estiver a tomar medicamentos para a tensão arterial antes de começar.',
	benefitsbq6:
		'Em uma amostra de pacientes ambulatoriais, uma dieta baixa em carboidratos Keto levou a melhorias semelhantes à terapia medicamentosa com Orlistat combinada com uma dieta baixa em gordura para peso, lipídios séricos e parâmetros glicêmicos e foi mais eficaz para reduzir a pressão arterial.',
	benefitsh3: '3. Perfil de colesterol melhorado',
	benefitsp3:
		'Mostrado para melhorar os níveis de triglicérides e os níveis de colesterol mais associados ao acúmulo arterial.',
	benefitsbq7:
		'As dietas cetogênicas aumentam as concentrações de colesterol HDL saudável para o coração mais do que as dietas com baixo teor de gordura e alto teor de carboidratos.',
	benefitsbq8:
		'Reduzir o consumo de carboidratos em indivíduos saudáveis ​​também leva a níveis mais elevados de colesterol HDL.',
	benefitsbq9:
		'Dietas com baixo teor de carboidratos e alto teor de gordura diminuem a concentração de partículas de LDL (LDL-P) e aumentam o tamanho do colesterol LDL.',
	benefitsbq10:
		'As dietas cetogênicas diminuem a quantidade de colesterol VLDL prejudicial no sangue.',
	benefitsh4: '4. Tratamento estabilizado de açúcar no sangue e diabetes',
	benefitsp4:
		'A melhor maneira de reduzir o açúcar no sangue e os níveis de insulina é reduzir o consumo de carboidratos. Esta também é uma maneira muito eficaz de tratar e possivelmente até mesmo reverter o diabetes tipo II.',
	benefitsbq11:
		'O LCKD melhorou o controle glicêmico em pacientes com diabetes tipo 2, de modo que os medicamentos para diabetes foram descontinuados ou reduzidos na maioria dos participantes. Como o LCKD pode ser muito eficaz na redução da glicose no sangue, os pacientes em uso de medicação para diabetes que usam essa dieta devem estar sob supervisão médica rigorosa ou ser capazes de ajustar sua medicação.',
	benefitsbq12:
		'Alto teor de gordura saturada de muito baixo carboidrato (VLCARB) resulta em perda de gordura semelhante do que dietas com baixo teor de gordura saturada, mas são mais eficazes em melhorar as concentrações de triacilgliceróis, HDL-C, jejum e glicose pós-prandial e insulina. VLCARB pode ser útil no manejo de curto prazo de indivíduos com resistência à insulina e hipertriacilglicerolemia.',
	benefitsbq13:
		'Modificações dietéticas levaram a melhorias no controle glicêmico e redução / eliminação de medicamentos em voluntários motivados com diabetes tipo 2. A dieta pobre em carboidratos levou a maiores melhorias no controle glicêmico, e redução / eliminação de medicamentos mais frequente do que a dieta de baixo índice glicêmico. A modificação do estilo de vida usando intervenções com baixo teor de carboidratos é eficaz para melhorar e reverter o diabetes tipo 2.',
	benefitsh5: '5. Falta de fome',
	benefitsp5:
		'Os corpos cetônicos reduzem o apetite, além disso, a gordura é naturalmente mais satisfatória e acaba nos deixando saciados e cheios por mais tempo.',
	benefitsbq14:
		'Os sintomas de afeto negativo e fome melhoraram em maior grau em pacientes que seguiram uma dieta Keto em comparação com aqueles que seguiram uma dieta com baixo teor de gordura.',
	benefitsh6: '6. Anticâncer',
	benefitsp6:
		'O ceto pode ajudar a combater certos tipos de câncer e vários tumores.',
	benefitsbq15:
		'Embora o mecanismo pelo qual as dietas cetogênicas demonstrem efeitos anticâncer quando combinadas com radioterapia padrão não tenha sido totalmente elucidado, os resultados pré-clínicos demonstraram a segurança e a eficácia potencial do uso de dietas cetogênicas em combinação com radioterapia para melhorar as respostas em modelos de câncer murino. Esses estudos pré-clínicos forneceram o ímpeto para estender o uso de dietas cetogênicas em ensaios clínicos de fase I que estão em andamento.',
	benefitsh7: '7. Melhor Pele',
	benefitsp7:
		'Os pesquisadores dizem que os alimentos que aumentam o açúcar no sangue também podem aumentar os hormônios. Os hormônios podem estimular a produção de óleo, que por sua vez, pode desencadear a acne. A dieta Keto evita o aumento do açúcar no sangue ao usar gordura como principal fonte de energia.',
	benefitsbq16:
		'Em um artigo publicado no Journal ofthe Academy of Nutrition and Dietetics, Burris e seus colegas revisam 27 estudos sobre nutrição e acne. Algumas das evidências mais convincentes vêm de alguns estudos recentes com adolescentes e homens jovens (de 15 a 25 anos) que tinham acne. Depois que os participantes seguiram uma dieta de baixa carga glicêmica, os pesquisadores documentaram reduções nas lesões inflamatórias da acne. Os estudos eram pequenos, mas os resultados foram significativos.',
	benefitsh8: '8. Saúde Dentária Melhorada',
	benefitsp8:
		'O açúcar (carboidrato) é o principal contribuinte para doenças e cáries dentárias.',
	benefitsbq17:
		'Os resíduos alimentares, especificamente os carboidratos, têm características promotoras da cárie que permitem que a fermentação e o acúmulo de ácido ocorram na placa bacteriana. Ele aprimora as quatro atividades de biofilme mencionadas anteriormente - implantação, colonização, atividade metabólica e espessura. Quando os carboidratos são restritos na dieta, isso limita um fator essencial para o desenvolvimento de cáries.',
	benefitsh9: '9. Estabilização do humor',
	benefitsp9:
		'Os corpos cetônicos demonstraram ser benéficos na estabilização de neurotransmissores como a serotonina e a dopamina, o que resulta em um melhor controle do humor.',
	benefitsbq18:
		'A dieta cetogênica, originalmente introduzida na década de 1920, tem ressurgido recentemente como um tratamento adjuvante para a epilepsia refratária, particularmente em crianças. Nesta população de difícil tratamento, a dieta exibe notável eficácia, com dois terços mostrando redução significativa na frequência das crises e um terço ficando quase livre de crises. Existem várias razões para suspeitar que a dieta cetogênica também pode ter utilidade como um estabilizador de humor na doença bipolar. Isso inclui a observação de que várias intervenções anticonvulsivantes podem melhorar o desfecho nos transtornos de humor. Além disso, mudanças benéficas no perfil de energia do cérebro são observadas em indivíduos sob dieta cetogênica. Isso é importante, pois o hipometabolismo cerebral global é uma característica do cérebro de indivíduos deprimidos ou maníacos. Finalmente, seria de se esperar que as mudanças extracelulares que ocorrem na cetose diminuíssem as concentrações intracelulares de sódio, uma propriedade comum de todos os estabilizadores de humor eficazes. Testes da dieta cetogênica na prevenção de recaídas de episódios de humor bipolar são necessários.',
	benefitsh10: '10. Possível aumento de energia',
	benefitsp10:
		'As gorduras são comprovadamente a molécula mais eficaz para queimar como combustível.',
	benefitsbq19:
		'Durante o estresse metabólico, as cetonas servem como fonte alternativa de energia para manter o metabolismo normal das células cerebrais. Na verdade, a BHB (uma das principais cetonas) pode ser um combustível ainda mais eficiente do que a glicose, fornecendo mais energia por unidade de oxigênio utilizada. Uma dieta cetogênica também aumenta o número de mitocôndrias, as chamadas “fábricas de energia” nas células cerebrais. Um estudo recente descobriu uma expressão aumentada de genes que codificam para enzimas mitocondriais e metabolismo de energia no hipocampo, uma parte do cérebro importante para o aprendizado e a memória. As células do hipocampo freqüentemente degeneram em doenças cerebrais relacionadas à idade, levando à disfunção cognitiva e perda de memória. Com o aumento da reserva de energia, os neurônios podem ser capazes de afastar os estressores de doenças que normalmente exaurem e matam a célula.',
	sideeffectsh0: 'Perigos',
	sideeffectsp0:
		'Como precaução, você deve sempre consultar seu médico de família antes de iniciar uma dieta cetogênica. Você deve ficar especialmente cansado se você ou alguém da sua família tiver problemas renais, cardíacos ou diabéticos pré-existentes.',
	sideeffectsh1: 'Osteoporose e pedras nos rins',
	sideeffectsp1:
		'As dietas ricas em proteínas também demonstraram que as pessoas excretam uma grande quantidade de cálcio na urina. Durante um período de tempo prolongado, isso pode aumentar o risco de osteoporose e pedras nos rins. Uma dieta que aumenta as proteínas às custas de uma ingestão muito restritiva de carboidratos vegetais pode ser ruim para os ossos, mas não necessariamente uma ingestão de alto teor de proteína por si só. ',
	sideeffectsh2: 'Falência renal',
	sideeffectsp2:
		'Consumir muita proteína sobrecarrega os rins, o que pode tornar uma pessoa suscetível a doenças renais.',
	sideeffectsh3: 'Tireóide, Coração, Pressão Arterial',
	sideeffectsp3:
		'Existem evidências baseadas em pesquisas limitadas que podem sugerir que a cetose pode causar um aumento de curta duração nos níveis corporais de adrenalina e cortisol para um pequeno subgrupo de pessoas. Por sua vez, esses níveis aumentados de hormônios podem causar problemas de tireóide, irregularidades cardíacas e pressão arterial elevada para alguns. ',
	sideeffectsh4: 'Efeitos colaterais',
	sideeffectsp4:
		'À medida que seu corpo é induzido a um estado cetogênico, ele naturalmente usa o que sobrou de sua glicose. Assim, o corpo se exaurirá de todo o armazenamento de glicose nos músculos, o que pode causar falta de energia e letargia geral. Durante as primeiras semanas, as pessoas relatam: ',
	sideeffectsbq1:
		'"Durante os primeiros dias a poucas semanas de adaptação com baixo teor de carboidratos, seu corpo está dispensando as enzimas que trabalham com carboidratos e construindo novas enzimas que trabalham com gordura. Uma vez que a força de trabalho em seu corpo é mudada, você começa a funcionar adequadamente com seu novo baixo -carb, dieta rica em gordura. Os carboidratos que você costumava queimar para obter energia agora são substituídos em grande parte pelas cetonas ... "- Mike Eades MD',
	sideeffectsh5: 'Keto Flu',
	sideeffectsp5: 'Sintomas como os da gripe',
	sideeffectsh6: 'Dores de cabeça',
	sideeffectsp6:
		'A cetose pode causar dores de cabeça em algumas pessoas e você pode se sentir um pouco tonto.',
	sideeffectsh7: 'Fadiga e tontura',
	sideeffectsp7:
		'Ao começar a despejar água, você perderá minerais como sal, potássio e magnésio. Ter níveis mais baixos desses minerais fará com que você fique muito, muito cansado, com vertigens ou tonturas, além de cãibras musculares e dores de cabeça. Este é um dos o mais comum dos efeitos colaterais da dieta baixa em carboidratos e pode ser evitado em sua maior parte certificando-se de que obtém minerais de reposição suficientes. ',
	sideeffectsh8: 'Episódios de hipoglicemia',
	sideeffectsp8:
		'O corpo está acostumado a liberar uma certa quantidade de insulina para cuidar da glicose que é criada a partir de todos os carboidratos que você ingere. Quando você diminui sua ingestão de carboidratos, inicialmente pode haver episódios ocasionais de baixa de açúcar no sangue',
	sideeffectsh9: 'Névoa mental',
	sideeffectsp9:
		'Não é incomum ter falta de clareza mental no início. Na maioria dos casos, a névoa do cérebro dura apenas alguns dias.',
	sideeffectsh10: 'Urinação frequente',
	sideeffectsp10:
		'Depois de começar, você notará que vai ao banheiro com mais frequência. O corpo está queimando a glicose restante (do armazenamento do glicogênio) no fígado e nos músculos. A decomposição do glicogênio libera muita água e seus rins começará a despejá-lo. Além disso, conforme os níveis de insulina circulante caem, os rins começam a excretar o excesso de sódio, o que também causa micção mais frequente. ',
	sideeffectsh11: 'Constipação',
	sideeffectsp11:
		'Este é um dos efeitos colaterais mais comuns e geralmente é resultado de desidratação, perda de sal e falta de magnésio. Mantenha-se hidratado e beba muita água ao longo do dia. Lembre-se também de comer fibras vegetais suficientes e você pode até tomar uma colher de chá ou duas de fibra de casca de psyllium diariamente.',
	sideeffectsh12: 'Cravings Carb',
	sideeffectsp12:
		'O corpo ainda está se adaptando à vida sem carboidratos como o açúcar. Os desejos intensos por alimentos ricos em amido, como pão, estarão presentes por uma semana ou mais.',
	sideeffectsh13: 'Diarréia',
	sideeffectsp13:
		'Isso geralmente acontece com pessoas que decidem imprudentemente também limitar a ingestão de gordura em uma dieta baixa em carboidratos, o que resulta em comer muita proteína.',
	sideeffectsh14: 'Cãibras musculares',
	sideeffectsp14:
		'A perda de minerais, especificamente magnésio, é o contribuinte mais provável para cólicas.',
	sideeffectsh15: 'Níveis de ginástica e exercícios',
	sideeffectsp15:
		'No início, é normal perder alguma força e resistência. Não se preocupe, assim que seu corpo se adaptar à cetose, você será capaz de utilizar completamente as gorduras como sua principal fonte de energia. Numerosos estudos mostram que aí não é uma queda de longo prazo no desempenho dos atletas. ',
	sideeffectsh16: 'Eletrólitos',
	sideeffectsp16:
		'Como a cetose tem um efeito diurético no corpo, na maioria das vezes os efeitos colaterais são o resultado da descarga de eletrólitos. Certifique-se de beber bastante água e manter a ingestão de sal / sódio alta. Você deve estar ficando louco de sal com todos os seus alimentos. Isso ajuda na retenção de água e ajuda a repor eletrólitos.',
	sideeffectsh17: 'Conclusão',
	sideeffectsp17:
		'Esses efeitos colaterais cetogênicos são, na verdade, apenas problemas menores e ocorrem apenas durante um período das primeiras semanas. Eles vão melhorar e finalmente diminuir, e então você experimentará todos os benefícios da dieta ceto.',
	mythsh0: 'The Fattest Myth',
	mythsp0:
		'A maioria das pessoas que desistem da dieta cetônica falha porque não se sentem confortáveis ​​aumentando maciçamente a ingestão de gordura, especialmente gordura saturada.',
	mythsh1: 'Alto teor de carboidratos + alto teor de gordura = Ruim',
	mythsp1:
		'Dietas ricas em carboidratos aumentam os níveis de açúcar no sangue e de insulina. Altos níveis de açúcar e insulina estão relacionados a alta inflamação. A maioria das dietas americanas é rica em açúcar e gordura saturada. Quando estudados, esses dois fatores foram combinados e não estudados separadamente.  N  nA gordura saturada recebeu a culpa pela inflamação que causa doenças cardíacas, porque foi estudada em combinação com uma dieta rica em carboidratos. As dietas cetogênicas, que são ricas em gordura saturada e muito pobres em carboidratos, minimizarão a inflamação.',
	mythsbq1:
		'Um estudo da John Hopkins confirmou que a gordura saturada não é prejudicial no contexto de uma dieta baixa em carboidratos e a causa das doenças cardíacas é o consumo cronicamente alto de carboidratos, e não uma ingestão elevada de gordura saturada e colesterol. Em outras palavras, a gordura nos torna gordos quando combinada com altos níveis de carboidratos.',
	mythsh2: 'E quanto à proteína?',
	mythsp2:
		'É vital para a cetose que os seus níveis de proteína não excedam os da gordura em calorias, não em peso. Isso ocorre porque a proteína em níveis excessivos agirá de forma muito semelhante aos carboidratos. Eles são eventualmente quebrados em glicose, o que aumenta o açúcar no sangue e causa um pico de insulina.',
	mythsh3: 'A cetose é perigosa e prejudicial',
	mythsp3:
		'A cetose, que é um estado metabólico benéfico, costuma ser confundida com cetoacidose, que é algo completamente diferente e uma condição médica séria. A cetoacidose é causada pela falha do corpo em regular adequadamente a produção de cetonas. É mais comumente sofrida por alcoólatras e tipos 1 diabético. ',
	mythsh4: '"Não é sustentável"',
	mythsp4:
		'Seguir a dieta pode ser mais fácil do que você pensa. Se você comer muito fora, especialmente fast food, haverá a tentação da conveniência e você poderá ter algumas dificuldades. O mesmo é verdade se você mantiver muito amido e açúcar comida na sua despensa. No entanto, se você ficar longe e não se expor a esses alimentos, dificilmente notará. Os desejos irão diminuir após uma ou duas semanas, se não antes ',
	mythsh5: 'Você não pode obter todas as vitaminas',
	mythsp5:
		'Conseguirá-se muito mais da maioria das vitaminas e minerais em uma dieta cetogênica adequada do que em uma dieta normal. A principal exceção é a vitamina C, que só é necessária em mais do que pequenas quantidades se você estiver em uma dieta rica em carboidratos. as folhas verdes terão vitamina C mais do que suficiente. ',
	mythsh6: 'Ceto vai lhe causar danos renais',
	mythsp6:
		'Pessoas com doença renal muito avançada têm problemas para lidar com grandes quantidades de proteína na dieta. No entanto, não há pesquisas em que uma dieta rica em proteínas cause doença renal. Pode haver um risco aumentado de pedras nos rins devido ao aumento dos níveis de ácido úrico no sangue sangue, mas isso é facilmente evitado ao beber muitos líquidos e, se você quiser ser extremamente cuidadoso, tome suplementos de citrato de potássio. ',
	mythsh7: 'Eu não quero passar fome',
	mythsp7:
		'Gordura e proteína são digeridas muito mais lentamente do que carboidratos, então você notará que passa mais tempo sem sentir fome.',
	mythsh8: 'Ceto te dá mau hálito',
	mythsp8:
		'Quando você está em cetose, é certamente detectável em sua respiração. O que você está sentindo são as cetonas, moléculas com cheiro de fruta convertidas da gordura para uso como combustível pelas células. Se você está com cetose, é bastante É gratificante saber que você está exalando calorias! Apenas mastigue um chiclete sem açúcar se isso incomodar você. ',
	getstartedh0: 'Como começar',
	getstartedp0:
		'No geral, comer uma quantidade elevada de gordura, proteína moderada e quantidade limitada de carboidratos terá um impacto enorme na sua saúde - reduzindo o colesterol, o peso corporal, o açúcar no sangue e aumentando os níveis de energia e humor.  n  nUma vez que você está pensando em começar uma abordagem mais realista e saudável para perder gordura corporal - um estilo de vida com baixo teor de carboidratos não parece tão ruim. Espere algumas semanas para superar a corcunda inicial, e esses efeitos colaterais desagradáveis ​​irão diminuir.  n  nUm ponto importante em uma dieta cetônica: esteja preparado para passar mais tempo na cozinha. Um menu cetônico envolve cozinhar e comer alimentos reais. Se você ainda não sabe cozinhar, esse será um bom aprendizado para você.  n  nVocê deve usar a seção de calculadora deste aplicativo para calcular suas macros específicas mencionadas abaixo',
	getstartedh1:
		'1. Reabasteça sua cozinha para que não haja tentações para você.',
	getstartedh2:
		'2. Reduza o consumo diário de carboidratos para cerca de 20g, no entanto, qualquer coisa abaixo de 50g é aceitável.',
	getstartedh3: '3. Aumente amplamente a proporção de gordura em sua dieta.',
	getstartedh4:
		'4. Traga a ingestão de proteína para 0,6 a 1g por quilo de massa corporal magra. (1,3 a 2,2g por kg).',
	getstartedh5: '5. Aumente amplamente a quantidade de água que você consome.',
	getstartedh6:
		'6. Aumente a ingestão de eletrólitos, como sal, potássio e magnésio.',
	fiberh0: 'Fibra - Obtenha um pouco!',
	fiberh1: 'As fontes ceto de fibra incluem:',
	fiberh2: 'Linhaça',
	fiberh3: 'Sementes de Chia',
	fiberh4: 'Vegetais que são quase todas as fibras',
	fiberh5: 'Suplementos de fibra',
	fiberh6: 'Mais fibra do que carboidrato utilizável:',
	fiberh7: 'Farelo de trigo',
	fiberh8: 'Coco sem açúcar e farinha de coco',
	fiberh9: 'Cereais com alto teor de fibras',
	fiberh10: 'Collard Greens',
	fiberh11: 'Abacate, Hass',
	fiberh12: 'Espinafre e acelga',
	fiberh13: 'Brócolis',
	fiberh14: 'Couve-flor',
	fiberh15: 'Amora silvestre',
	fiberh16: 'Tanto Carboidratos Usáveis ​​quanto Fibras:',
	fiberh17: 'Espargos',
	fiberh18: 'Salsão',
	fiberh19: 'Berinjela',
	fiberh20: 'Alface, Romaine',
	fiberh21: 'Cogumelos',
	fiberh22: 'Rabanetes',
	fiberh23: 'Framboesas vermelhas',
	fiberh24: 'Alta fibra, mas não tanta fibra quanto Carb utilizável:',
	fiberh25: 'Farelo de arroz',
	fiberh26: 'Repolho',
	fiberh27: 'Pimentões',
	fiberh28: 'Ervilhas (vagem comestível)',
	fiberh29: 'Abobrinha',
	fiberh30: 'Morangos',
	fiberp0:
		'A fibra é uma forma de açúcar chamada celulose. Não temos as enzimas necessárias para quebrar a celulose, então ela não ativa a glicólise e não afeta os níveis de insulina. Alimentos ricos em fibras são seguros para comer com ceto e devem ser consumidos ao longo do dia. É importante observar que as fibras NÃO contam para a ingestão de carboidratos.  N  nPor exemplo, se sua refeição tem 10g de carboidratos no total e 5g deles são fibras, a ingestão real de carboidratos para a refeição é 5g (10g -5g) . Embora a fibra seja um carboidrato, ela não é digerida como um carboidrato simples e, portanto, não é incluída em sua contagem diária de carboidratos.',
	fiberp1:
		'É importante enfatizar que a fibra não NEGAVA os carboidratos - ela simplesmente não é contada; portanto, misturar um punhado de farinha de linhaça em uma tigela de sorvete não funcionará!',
	fiberp2:
		'A maioria das pessoas obtém suas fibras de grãos e eliminar este grupo de alimentos para adotar sua dieta cetogênica pode resultar em uma diminuição considerável em sua ingestão diária de fibras.',
	fiberp3:
		'As Diretrizes Dietéticas para Americanos de 2010 recomendam que você consuma entre 25g e 38g de fibra por dia, & nbsp; mas alguém em uma dieta cetônica pode precisar de mais.',
	fiberp4:
		'Quase não há carboidratos utilizáveis ​​nas sementes de linhaça. É muito rico em fibras solúveis e insolúveis (cerca de um terço da fibra é solúvel) e, além disso, possui uma pilha de nutrientes. O linho é uma das melhores fontes de fibra para implementar na sua dieta.',
	fiberp5:
		'Ter um perfil de fibra e carboidrato semelhante ao das sementes de linho.',
	fiberp6: 'Mostarda Verdes, Chicória, Endívia',
	fiberp7: 'Tal como pó de casca de psyllium',
	fiberp8:
		'½ xícara crua, 3 gramas de carboidratos utilizáveis, 6 gramas de fibra',
	fiberp9: '1 onça, 2 gramas de carboidratos utilizáveis, 5 gramas de fibra',
	fiberp10:
		'Verifique os rótulos com cuidado, mas alguns cereais ricos em fibras também têm baixo ou bastante baixo teor de carboidratos. Exemplos - Todo Farelo com Fibra Extra; Fiber One',
	fiberp11:
		'1 xícara picada, cozida, 4 gramas de carboidratos utilizáveis, 5 gramas de fibra',
	fiberp12:
		'1 abacate médio, 3 gramas de carboidratos utilizáveis, 12 gramas de fibra',
	fiberp13:
		'Congelado 1 pacote de 10 onças, 10g de carboidrato total, 6g de fibra. 6 xícaras de espinafre cru ou acelga = cerca de 1 xícara cozida',
	fiberp14:
		'1/2 xícara picada, cozida, 1 grama de carboidrato utilizável, 3 gramas de fibra. 1 xícara picada, crua, 4 gramas de carboidratos utilizáveis, 2 gramas de fibra',
	fiberp15:
		'1/2 xícara de pedaços, cozidos, 1 grama de carboidrato utilizável, 2 gramas de fibra. 1 xícara crua, 2 gramas de carboidratos utilizáveis, 2,5 gramas de fibra',
	fiberp16:
		'1 xícara, cru, 6 gramas de carboidratos utilizáveis, 8 gramas de fibra',
	fiberp17:
		'1/2 xícara de pedaços, 2 gramas de carboidratos utilizáveis, 2 gramas de fibra',
	fiberp18:
		'1 xícara picada, 1,5 gramas de carboidratos utilizáveis, 1,5 gramas de fibra',
	fiberp19:
		'1 xícara crua, em cubos, 2 gramas de fibra utilizável, 3 gramas de fibra. 1 xícara em cubos, cozida, 5 gramas de carboidratos utilizáveis, 3 gramas de fibra',
	fiberp20:
		'1 xícara triturada, 0,5 grama de carboidrato utilizável, 1 g de fibra',
	fiberp21:
		'1 xícara, fatiada, crua, 1 grama de carboidrato utilizável, 1 grama de fibra',
	fiberp22:
		'1 xícara cru, fatiado, 2 gramas de carboidratos utilizáveis, 2 gramas de fibra',
	fiberp23:
		'1 xícara, cru, 7 gramas de carboidratos utilizáveis, 8 gramas de fibra',
	fiberp24:
		'1/4 xícara 8 gramas de carboidratos utilizáveis, 6 gramas de fibra',
	fiberp25:
		'1 xícara crua, picada, 3 gramas de carboidratos utilizáveis, 2 gramas de fibra. 1/2 xícara cozida, picada, 2 gramas de carboidratos utilizáveis ​​e 1 grama de fibra',
	fiberp26:
		'1 xícara picada, crua, 4 gramas de carboidratos utilizáveis, 3 gramas de fibra',
	fiberp27:
		'1 xícara inteira, crua, 3 gramas de carboidratos utilizáveis, 2 gramas de fibra',
	fiberp28:
		'1 xícara cozida, fatiada, 4 gramas de carboidratos utilizáveis, 3 gramas de fibra',
	fiberp29:
		'1/2 xícara fatiada, 5 gramas de carboidratos utilizáveis, 2 gramas de fibra',
	tipsh1: 'Faça uma varredura de carboidratos.',
	tipsp1:
		'Inspecione os armários da cozinha e a geladeira e remova todos os alimentos ricos em carboidratos. Isso evitará tentações no futuro.',
	tipsh2:
		'Faça um check-up de saúde e faça um exame de sangue antes de iniciar a dieta.',
	tipsp2:
		'Planeje ter mais quatro a oito semanas após o início. Dessa forma, você pode acompanhar o efeito da mudança em seus hábitos alimentares sobre o colesterol, a pressão arterial e outros indicadores de saúde.',
	tipsh3: 'Compre Ketostix.',
	tipsp3:
		'Para verificar se você está dentro ou fora de cetose. Os Ketostix foram concebidos para medir a quantidade de cetonas na sua urina. Recentemente, um medidor de cetonas no sangue foi disponibilizado para monitorar a cetose em casa. Esta é uma maneira muito mais precisa de rastrear seus níveis individuais de cetonas.',
	tipsh4: 'Pense sobre suas refeições e como você as planejará.',
	tipsp4:
		'Isso ajudará a comprar os alimentos certos no supermercado e fornecerá uma estrutura a seguir quando chegar a hora da refeição. Se você sabe que deve comer salmão e brócolis no jantar esta noite, fica muito mais fácil evitar a escolha os velhos alimentos ricos em carboidratos que você costumava comer. ',
	tipsh5: 'Fique hidratado.',
	tipsp5:
		'À medida que a ingestão de carboidratos diminui, os rins começam a despejar o excesso de água que o corpo retém devido à alta ingestão de carboidratos. Certifique-se de beber água suficiente para repor o que se perde.',
	tipsh6: 'Pense em qualquer situação social que será encontrada.',
	tipsp6:
		"Pense em maneiras de lidar com os momentos em que as tentações de comer da' velha 'maneira serão altas. Você não quer ficar cego quando alguém no escritório traz uma caixa de seus chocolates favoritos e os coloca bem debaixo do seu nariz. Da mesma forma, uma cerveja com os amigos costuma se transformar em um encontro com cascas de batata e nachos. Pense em salada e bife.",
	tipsh7: 'Não se concentre no seu peso.',
	tipsp7:
		'Não se pese todos os dias. Seu peso pode variar entre 2-4 libras por dia por causa das mudanças na ingestão e absorção de água. Você não será capaz de controlar qualquer perda de gordura com precisão diariamente, e as flutuações irão deixa você louco se você se concentrar neles. Em vez disso, pese-se uma vez por semana ou acompanhe as medidas do seu corpo para acompanhar o seu progresso. ',
	makes: 'Faz',
	servings: 'Porções',
	fat: 'Gordura',
	carbs: 'Carboidratos',
	totalcarb: 'Carboidratos totais',
	netcarb: 'Net Carbs',
	protein: 'Proteína',
	calories: 'Calorias',
	zeroResults: '0 resultados',
	loadMore: 'Carregue mais',
	likes: 'Gosta',
	comments: 'Comentários',
	netcarbFinder: 'Net Carb Finder',
	search: 'Pesquisa',
	searchFilter: 'Filtro de Pesquisa',
	barcode: 'Barcode',
	offline: 'Desligada',
	results: 'Resultados',
	barcodeNotRecognized: 'Código de barras não reconhecido',
	barcodeNotRecognizedDetail:
		'Tente pesquisar o banco de dados ou adicioná-lo como um alimento personalizado.',
	clearAllRecentlyScanned: 'Limpar todos os digitalizados recentemente?',
	clearAllConfirm:
		'Isso irá limpar seus códigos de barras recentemente lidos em todos os dispositivos sincronizados',
	requestCamera: 'Solicitando permissão de câmera',
	noAccessCamera: 'Sem acesso à câmera',
	scanBarcode: 'Scan Barcode',
	itemFound: 'Item encontrado',
	itemNotFoundFS: 'Item não encontrado em segredo de gordura',
	ItemNotFoundFSdesc:
		'Tente pesquisar o banco de dados ou adicioná-lo como um alimento personalizado.',
	generic: 'Genérico',
	basedDiet: '% com base em uma dieta de 2.000 calorias',
	all: 'Todos',
	alcohol: 'Álcool',
	beverages: 'Bebidas',
	cheeses: 'Queijos',
	dairy: 'Laticínios',
	dressings: 'Curativos (Verificar Etiquetas)',
	fatsoils: 'Gorduras e óleos',
	fish: 'Peixe',
	flours: 'Farinhas ou Refeições',
	fowl: 'Galinha',
	fruits: 'Frutas',
	grainsstarch: 'Grãos e amido',
	meats: 'Carnes',
	nutslegumes: 'Nozes e Legumes',
	seeds: 'Sementes',
	shellfish: 'Marisco',
	sugarssweetener: 'Açúcares e adoçante',
	vegetables: 'Legumes',

	calculatorDetails:
		'Precisamos de alguns detalhes para formar o seu plano cetônico personalizado',
	height: 'Altura',
	weight: 'Peso',
	age: 'Era',
	gender: 'Gênero',
	male: 'Masculino',
	female: 'Fêmea',
	exerciseLevel: 'Nível de exercício',
	littleToNone: 'Pouco a Nenhum',
	exerciseOne: '1-3 vezes / semana',
	exerciseTwo: '3-5 vezes / semana',
	exerciseThree: '6-7 vezes / semana',
	exerciseFour: '2 vezes / dia',
	goalWeight: 'Peso objetivo',
	faster: 'Mais rápido',
	slower: 'Mais devagar',
	weightPlan: 'Seu plano de peso',
	maintain: 'Manter',
	easier: 'Mais fácil',
	mild: 'Leve',
	moderate: 'Moderado',
	difficult: 'Difícil',
	customize: 'Customizar',
	dailyCalories: 'Calorias Diárias',
	week: 'Semana',
	lose: 'Perder',
	by: 'por',
	bodyFat: 'Corpo gordo',
	bodyFatDetails:
		'Sua% de gordura corporal será usada para calcular a quantidade ideal de proteína com base em sua massa muscular total.',
	proteinDetail:
		'Muita proteína pode quebrar a cetose, então você precisa apenas o suficiente para manter os músculos e um pouco mais para construí-los.',
	dailyProtein: 'Proteína Diária',
	idealRange: 'Seu alcance ideal é',
	maintainLower: 'manter',
	build: 'Construir',
	maintainMuscle: 'Manter o músculo',
	buildMuscle: 'Construir músculos',
	netcarbDescription:
		'Comer mais de 50g de carboidratos líquidos por dia é o suficiente para quebrar a cetose. A maioria das pessoas que fazem dieta ceto consome cerca de 20g-30g. Quanto menos carboidratos você come, menos chance você tem de quebrar a cetose.',
	language: 'Língua',
	languageUpdated: 'Idioma atualizado',
	english: 'Inglês',
	french: 'Francês',
	spanish: 'Espanhol',
	portuguese: 'Português',
	german: 'Alemão',
	logout: 'Sair',
	loggedOut: 'Desconectado',
	unitsUpdated: 'Unidades atualizadas',
	featureRequest: 'Solicitação de recurso',
	deleteAccount: 'Deletar conta',
	verifyDeleteMessage: 'Isso excluirá sua conta e todos os dados, tem certeza?',
	accountDeleted: 'Conta excluída',
	net: 'Internet',
	total: 'Total',
	preferredCarb: 'Carb preferencial',
	preferredCarbDesc:
		'Os carboidratos totais incluem fibras e álcoois de açúcar, enquanto os carboidratos líquidos incluem apenas carboidratos contáveis ​​que afetam a cetose. A maioria das pessoas que fazem dieta ceto rastreia apenas carboidratos líquidos.',
	updateMacroGoals: 'Atualizar metas macro',
	defaultMacroGoalsUpdated: 'Metas de macro padrão atualizadas',
	customGoalsApplied: 'Metas personalizadas aplicadas a datas',
	tracking: 'Rastreamento',
	recalculateMacroGoals: 'Recalcular metas macro',
	manuallyChangeMacroGoals: 'Alterar manualmente as metas de macro',
	changePreferences: 'Alterar preferências',
	otherTracking: 'Outro rastreamento',
	other: 'De outros',
	notes: 'Notas',
	invalidCode: 'Código inválido',
	purchaseRestored: 'Compra restaurada',
	userNotFound: 'Usuário não encontrado',
	error: 'Erro',
	errorProducts:
		'Ocorreu um erro ao tentar carregar produtos. Verifique se você tem uma conexão válida com a Internet.',
	trackerEnabledAdsRemoved: 'Tracker Enabled, Ads Removed',
	purchaseTracking: 'Acompanhamento de compras',
	gettingProductDetails: 'Obtendo detalhes do produto ...',
	noPurchaseFound: 'Nenhuma compra encontrada',
	noPurchaseFoundMore:
		'Nenhuma compra foi encontrada nesta conta. Envie-nos um e-mail se achar que isso está incorreto.',
	account: 'Conta',
	signIn: 'Assinar em',
	logIn: 'Conecte-se',
	signedIn: 'Assinado',
	accountCreated: 'Conta criada',
	signUp: 'Inscrever-se',
	email: 'O email',
	password: 'Senha',
	forgotPassword: 'Esqueceu a senha?',
	resetEmailSent: 'Redefinir e-mail enviado',
	newUser: 'Novo usuário?',
	emailError: 'Entre com um email válido',
	passwordError: 'Insira uma senha',
	emailSubscribe: 'Receba atualizações e ofertas especiais do MyKeto',
	enterYourEmail: 'Digite seu e-mail',
	medicalDisclaimer: 'Isenção de responsabilidade médica',
	disclaim:
		'Isenção de responsabilidade médica - MyKeto é oferecido apenas para fins educacionais e de entretenimento, e de forma alguma pretende diagnosticar, curar ou tratar qualquer condição médica ou outra. Sempre procure o conselho de seu médico ou outro profissional de saúde qualificado antes de mudar sua dieta ou plano de exercícios e pergunte a seu médico qualquer dúvida que possa ter sobre uma condição médica. Embora façamos o nosso melhor para verificar a precisão das informações aqui contidas, não podemos garantir a sua precisão.',
	skipAccount: 'Pular conta',
	registerAccount: 'Registar Conta',
	convertAccount: 'Converter conta para login',
	createAccountLater:
		'Você pode criar uma conta mais tarde com seus dados, indo para o menu Configurações',
	whyLogin: 'Por que entrar?',
	usingAccount:
		'O uso de uma conta fará o backup e sincronizará seus dados em todos os seus outros dispositivos. Leva apenas um segundo para se registrar e não compartilhamos ou armazenamos quaisquer dados pessoais.',
	totalcarbError: 'O total não pode ser inferior a fibra',
	auto: 'Auto',
	manual: 'Manual',
	name: 'Nome',
	editingItem: 'Editando Item',
	foods: 'Alimentos',
	meals: 'Refeições',
	meal: 'Refeição',
	favorites: 'Favoritos',
	recent: 'Recentes',
	recentlyused: 'Usado recentemente',
	saveToFoods: 'Save To Foods',
	searched: 'Pesquisado',
	myfoods: 'Meus Alimentos',
	mymeals: 'Minhas refeições',
	quickmacro: 'Macros Rápidas',
	create: 'Crio',
	created: 'Criado',
	createFood: 'Criar comida',
	createMeal: 'Criar Refeição',
	ingredientNotFound: 'Ingrediente não encontrado, tente adicioná-lo novamente',
	totalServings: 'Porções totais',
	totalServingsDesc: 'Digite um número para o total de porções',
	clearAllFavorites: 'Limpar todos os favoritos?',
	clearAllFavoritesDesc:
		'Isso irá limpar todos os seus favoritos em todos os dispositivos sincronizados',
	clearAllRecentlyUsed: 'Limpar todos os itens usados ​​recentemente?',
	clearAllRecentlyUsedConfirm:
		'Isso irá limpar seus itens registrados recentemente em todos os dispositivos sincronizados',
	removedFromRecentlyUsed: 'Removido de usados ​​recentemente',
	itemsAdded: 'Itens Adicionados',
	mealName: 'Nome da refeição',
	servingName: 'Nome do serviço',
	wholeRecipe: 'Receita Inteira',
	clickStart: 'Está tudo pronto, clique no botão abaixo para começar',
	privacyPolicy: 'Política de Privacidade',
	termsConditions: 'Termos e Condições',
	policyAgree: 'Ao clicar abaixo, você concorda com nossos',
	unitsOnboard:
		'Escolha suas unidades preferidas para medidas corporais (peso, tamanho do peito, etc) e água',
	clockOnboard:
		'Escolha como deseja que a exibição do cronômetro de início e fim seja formatada',
	scheduleOnboard:
		'Escolha se deseja receber notificações sempre que mudar de janela e se deseja um lembrete antes que o período termine.',
	manualOnboardSettings:
		'Escolha sua meta rápida padrão (você pode alterar isso rapidamente na página inicial) e se deseja escolher sua meta em horas totais ou se deseja selecionar uma data e hora para sua meta.',
	manualOnboardNotifications:
		'Escolha se você deseja receber notificações, como um lembrete específico para jejuar todos os dias em um determinado horário e lembretes quando o jejum terminar ou estiver prestes a terminar.',
	weightLoss: 'Perda de peso',
	diseasePrevention: 'Prevenção de doença',
	lowerBloodPressure: 'Baixa pressão arterial',
	improvedCholesterolProfile: 'Perfil de colesterol melhorado',
	antiAging: 'Antienvelhecimento e longevidade',
	insulinManagement: 'Controle de Insulina e Diabetes',
	antiCancer: 'Anticâncer',
	heartHealth: 'Saúde do coração',
	improvedSkin: 'Pele melhorada',
	dentalHealth: 'Saúde dental',
	whyKeto: 'Por que você está interessado na dieta cetogênica?',
	betterTailor:
		'Vamos adaptar melhor o MyKeto para ajudá-lo com seus objetivos',
	goals: 'Metas',
	goalsExplanation:
		'Usaremos essas informações para ajudá-lo a prever seu progresso',
	finished: 'Acabado',
	startTracking: 'Iniciar Rastreamento',
	weightUnits: 'Peso e Medidas Corporais',
	units: 'Unidades',
	metric: 'Métrica',
	imperial: 'Imperial',
	ftcm: 'ft, lbs',
	bodyMeasurements: 'Medidas',
	bmi: 'IMC',
	leanBodyMass: 'Massa Corporal Enxuta',
	waistSize: 'Tamanho da cintura',
	hipSize: 'Tamanho do quadril',
	chestSize: 'Tamanho do peito',
	neckSize: 'Tamanho do pescoço',
	armSize: 'Tamanho do braço',
	forearmSize: 'Tamanho do antebraço',
	calfSize: 'Tamanho da panturrilha',
	thighSize: 'Tamanho da coxa',
	enterNumber: 'Por favor, insira um número acima de zero.',
	vitals: 'Vitais',
	water: 'Água',
	bloodGlucose: 'Glicose no sangue',
	exercise: 'Exercício',
	bloodPressure: 'Pressão arterial',
	heartRate: 'Frequência cardíaca',
	ketones: 'Cetonas',
	ketoneLevels: 'Níveis de cetona',
	potassium: 'Potássio',
	sodium: 'Sódio',
	magnesium: 'Magnésio',
	systolic: 'Sistólica',
	diastolic: 'Diastólica',
	breakfast: 'Café da manhã',
	lunch: 'Almoço',
	dinner: 'Jantar',
	snacks: 'Snacks',
	quickAdd: 'Quick Add',
	quickAddMacros: 'Quick Add Macros',
	quickAddToMeal: 'Quick Add To Meal',
	copyTo: 'Copiar para',
	saveAsMeal: 'Salvar como refeição',
	addEntry: 'Adicionar entrada',
	caloriesFromMacros: 'Calorias de macros',
	eaten: 'Comido',
	remaining: 'Restante',
	macroGoalsEaten: 'Macro metas consumidas',
	per: 'Por',
	syncExercise: 'Sincronizar exercício',
	estimateCalories: 'Estimar calorias',
	selectTime: 'Selecione o tempo',
	duration: 'Duração',
	caloriesBurned: 'Calorias queimadas',
	addExerciseCal: 'Adicionar Exercício Cal',
	custom: 'Personalizadas',
	oneWeek: '1 semana',
	oneMonth: '1 mês',
	threeMonths: '3 meses',
	sixMonths: '6 meses',
	oneYear: '1 ano',
	allTime: 'Tempo todo',
	to: 'Para',
	addTwoEntries: 'Adicionar 2 ou mais entradas',
	netChange: 'Mudança de rede',
	noEntriesFound: 'Nenhuma entrada encontrada',
	addFoodEntries:
		'Adicione entradas de alimentos ao seu registro para ver os dados',
	fitbitDesc:
		'Envie seus dados de peso, água e macro do MyKeto para o Fitbit. Envie sua atividade de exercícios Fitbit para MyKeto',
	fitbitConnected: 'Fitbit Syncing Connected',
	fitbitRemove: 'Deseja remover o acesso ao Fitbit deste dispositivo?',
	fitbitDisconnected: 'Fitbit desconectado',
	healthkit1:
		'Para atualizar suas permissões para o Apple Healthkit, abra o aplicativo do kit de saúde em seu telefone, toque em fontes e selecione MyKeto na lista',
	healthkit2:
		'Envie seus dados de peso, água e macros do MyKeto para o Apple HealthKit',
	friendRequest: 'Pedido de amizade',
	tryAddFriend:
		'Experimente adicionar um amigo por e-mail com o botão (+). Depois de aceitos, vocês podem copiar os alimentos e refeições salvos uns dos outros.',
	alreadyCopied: 'Já copiado',
	sent: 'Enviei',
	received: 'Recebido',
	alreadyInvited: 'Já Convidado',
	alreadyPendingInvite: 'Este usuário já tem um convite pendente',
	alreadyFriended: 'Já amigo',
	alreadyYourFriend: 'Este usuário já é seu amigo',
	pendingInvite: 'Convite pendente',
	pendingAlready: 'Este usuário já lhe enviou um convite',
	friendRequestSent: 'Pedido de amizade enviado',
	invalidEmail: 'E-mail inválido',
	cantFriendYourself: 'Você não pode se tornar amigo',
	friendRequestCanceled: 'Pedido de amizade cancelado',
	friendRequestAccepted: 'Pedido de amizade aceito',
	friendRequestDeclined: 'Pedido de Amizade recusado',
	unfriended: 'Unfriended',
	allFoodsCopied: 'Todos os alimentos copiados',
	exerciseLabel1: 'Dança Aeróbica (Casual)',
	exerciseLabel2: 'Dança Aeróbica (Moderada)',
	exerciseLabel3: 'Dança Aeróbica (Intensa)',
	exerciseLabel4: 'Basquete (quadra completa)',
	exerciseLabel5: 'Basquete (meia quadra)',
	exerciseLabel6: 'Calistenia',
	exerciseLabel7: 'Ciclismo (5,5 mph, 9 kmph)',
	exerciseLabel8: 'Ciclismo (10 mph, 16 kmph)',
	exerciseLabel9: 'Ciclismo (13 mph, 21 kmph)',
	exerciseLabel10: 'Dança (casual)',
	exerciseLabel12: 'Dança (moderada)',
	exerciseLabel13: 'Dança (intensa)',
	exerciseLabel14: 'Field Sports',
	exerciseLabel15: 'Frisbie',
	exerciseLabel16: 'Golfe (caminhada)',
	exerciseLabel17: 'Golfe (com carrinho)',
	exerciseLabel18: 'Ginástica',
	exerciseLabel19: 'Tarefas domésticas',
	exerciseLabel20: 'Jump Rope',
	exerciseLabel21: 'Artes marciais',
	exerciseLabel22: 'Racquetball',
	exerciseLabel23: 'Rollerblading',
	exerciseLabel24: 'Máquina de Remo (Intensa)',
	exerciseLabel25: 'Máquina de Remo (Moderado)',
	exerciseLabel26: 'Running (4 mph, 6,5 kmph)',
	exerciseLabel27: 'Running (5 mph, 8 kmph)',
	exerciseLabel28: 'Running (6 mph, 9,7 kmph)',
	exerciseLabel29: 'Running (7 mph, 11,3 kmph)',
	exerciseLabel30: 'Running (8 mph, 13 kmph)',
	exerciseLabel31: 'Running (10 mph, 16 kmph)',
	exerciseLabel32: 'Running (12 mph, 19,3 kmph)',
	exerciseLabel33: 'Softball',
	exerciseLabel34: 'Degrau de escada (6 ", 15,2 cm)',
	exerciseLabel35: 'Escada (8 ", 20,3 cm)',
	exerciseLabel36: 'Escada (10 ", 25,4 cm)',
	exerciseLabel37: 'Degrau de escada (12 ", 30,5 cm)',
	exerciseLabel38: 'Natação (casual)',
	exerciseLabel39: 'Natação (intensa)',
	exerciseLabel40: 'Tênis',
	exerciseLabel41: 'Vôlei',
	exerciseLabel42: 'Caminhando (2 mph, 3,2 kmph)',
	exerciseLabel43: 'Caminhando (3 mph, 4,8 kmph)',
	exerciseLabel44: 'Caminhando (4 mph, 6,5 kmph)',
	exerciseLabel45: 'Treinamento com pesos (intenso)',
	exerciseLabel46: 'Treinamento de peso (normal)',
	exerciseLabel47: 'Jardinagem'
};
