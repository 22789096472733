//@flow
import { StyleSheet } from 'react-native';
import { Colors } from 'Theme';

export default StyleSheet.create({
	loginScreenContainer: {
		flex: 1
	},
	loginScreenScrollContainer: {
		flex: 1
	},
	logoContainer: {
		justifyContent: 'center',
		alignItems: 'center'
	},
	logoStyle: {
		marginBottom: 10,
		width: 150,
		height: 150
	},
	formContainer: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-start'
	},
	btnContainer: {
		flex: 1
	},
	signUpBtn: {
		marginHorizontal: 30
	},
	textLinksContainer: {
		flex: 0.5,
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'flex-end',
		paddingVertical: 25
	},
	newUser: {
		textAlign: 'center',
		color: "#FFF",
		fontSize: 10
	},
	newUserCtn: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	signUpLink: {
		color: Colors.teal,
		fontSize: 13
	},
	anonyLink: {
		color: Colors.blue,
		fontSize: 13
	},
	textLink: {
		color: '#FFF',
		fontSize: 10
	},

	goBackTextLink: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	backArrow: {
		paddingRight: 5
	},
	trialCtn: {
		marginHorizontal: 25,
		flexDirection: 'row'
	},
	trialBtn: {
		flex: 1,
		marginRight: 5
	},
	trialHelp: {
		width: 50,
		marginLeft: 5,
		backgroundColor: '#FFF'
	},
	emailSubscribeCtn: {
		alignItems: 'center',
		marginVertical: 25,
		marginLeft: -10
	}
});
