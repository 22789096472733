//@flow
import React from 'react';
import { ScrollView, View, Linking } from 'react-native';
import { Paragraph, Heading, Divider, Text, BlockQuote } from '../common';
import { i18n } from '../../constants';
import styles from "./Styles/Styles";

export const Basics = ({ theme }) => {
	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<View>
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('basicsh1')}
				</Heading>
				<Paragraph>{i18n.t('basicsp1')}</Paragraph>
				<Divider />
				<Paragraph>{i18n.t('basicsp2')}</Paragraph>
				<Divider />
				<Paragraph>{i18n.t('basicsp3')}</Paragraph>
				<Divider />
				<Paragraph>{i18n.t('basicsp4')}</Paragraph>
				<BlockQuote
					ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}
					style={{ paddingTop: 0, alignItems: 'center' }}>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/dash-diet/art-20048456'
							)
						}>
						{'\n'}Source
					</Text>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('https://www.nhlbi.nih.gov/health-topics/dash-eating-plan')
						}>
						{'\n'}Source 2
					</Text>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'https://www.webmd.com/hypertension-high-blood-pressure/guide/dash-diet#1'
							)
						}>
						{'\n'}Source 3
					</Text>
				</BlockQuote>
			</View>
		</ScrollView>
	);
};
