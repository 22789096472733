import { Platform } from "react-native";

const shareUrl = Platform.select({
	ios: "https://apps.apple.com/us/app/id1032110739",
	android: "https://play.google.com/store/apps/details?id=com.prestigeworldwide.dashdiet",
});

const rateURL = Platform.select({
	ios: "https://apps.apple.com/us/app/id1032110739",
	android: "https://play.google.com/store/apps/details?id=com.prestigeworldwide.dashdiet",
});

const admobBannerID = Platform.select({
	ios: "ca-app-pub-5202729129404099/7449248969",
	android: "ca-app-pub-5202729129404099/4495782566",
});

const admobInterID = Platform.select({
	ios: "ca-app-pub-5202729129404099/8925982163",
	android: "ca-app-pub-5202729129404099/5972515761",
});

const testingID = {
	banner: "ca-app-pub-3940256099942544/6300978111",
	inter: "ca-app-pub-3940256099942544/1033173712",
};

const decimalOption = 2;

const Global = {
	shareUrl: shareUrl,
	rateURL: rateURL,
	admobBannerID: global.__DEV__ ? testingID.banner : admobBannerID,
	admobInterID: global.__DEV__ ? testingID.inter : admobInterID,
	decimalOption: decimalOption,
};

export default Global;
