import React, { useState } from 'react';
import { View, Linking } from 'react-native';
import { Nav } from './';
import styles from './styles';
import { Text, Button, Checkbox } from 'Components/common';
import { i18n } from 'Theme';
import * as Animatable from 'react-native-animatable';

export const PageSubmit = (props) => {
	const { next, prev, page, auth, theme } = props;
	const [checked, toggleCheck] = useState(true);
	return (
		<View style={styles.pageContainer}>
			<Nav
				onForward={() => next(checked)}
				onBack={prev}
				page={page}
				finalSubmit={true}
				theme={theme}
			/>
			<Text style={styles.pageTitle}>{i18n.t('finished')}!</Text>
			<View style={styles.inputsContainer}>
				<View style={styles.policyCtn}>
					<Text style={styles.policyText}>
						{i18n.t('policyAgree')}
						<Text
							style={styles.linkText}
							onPress={() =>
								Linking.openURL('http://myketotracker.com/mydashdiet-terms-and-conditions/')
							}>
							{' '}
							Terms & Service
						</Text>{' '}
						,{' '}
						<Text
							style={styles.linkText}
							onPress={() =>
								Linking.openURL('http://myketotracker.com/mydashdiet-privacy-policy/')
							}>
							{' '}
							Privacy Policy
						</Text>
						{', '}& {i18n.t('medicalDisclaimer')}
					</Text>
					{!auth.isAnonymous && (
						<View style={styles.emailSubscribeCtn}>
							<Animatable.View
								animation={'bounce'}
								iterationCount={1}
								direction="alternate">
								<Checkbox
									selected={checked}
									color={theme.blue}
									size={20}
									onPress={() => toggleCheck(!checked)}
									text={i18n.t('emailSubscribe')}
									textStyle={{
										color: theme.darkFont,
										marginLeft: 10,
										fontSize: 10
									}}
									style={{
										marginVertical: 5,
										marginLeft: 0,
										padding: 5
									}}
								/>
							</Animatable.View>
						</View>
					)}
					<Text style={styles.disclaimer}>{i18n.t('disclaim')}</Text>
				</View>
				<Button
					textStyle={{ color: '#FFF' }}
					style={{
						backgroundColor: theme.teal,
						marginHorizontal: 0,
						padding: 0
					}}
					onPress={() => next(checked)}>
					{i18n.t('startTracking')}
				</Button>
			</View>
		</View>
	);
};
