//@flow
import React from 'react';
import {
	View,
	TextInput,
	Platform,
	StyleSheet,
	Picker as PickerA,
	TouchableOpacity
} from 'react-native';
import { Colors, i18n } from 'Theme';
import { Text } from 'Components/common';
import Picker from 'react-native-picker';
import { moderateScale } from 'Utilities';

type Props = {
	onCalculated: Function,
	onChange: Function,
	exerciseEntry: Object,
	theme: Object
};

type State = {
	activityRatio: Object,
	activityLabel: Object
};

const showIOSPicker = (
	items: Array<Object>,
	onConfirm: Function,
	selectedValue: string,
	theme
) => {
	Picker.init({
		pickerTitleText: '',
		pickerConfirmBtnText: i18n.t('confirm'),
		pickerCancelBtnText: i18n.t('cancel'),
		pickerTextEllipsisLen: 12,
		pickerConfirmBtnColor: [255, 255, 255, 1],
		pickerCancelBtnColor: [255, 255, 255, 1],
		pickerTitleColor: [255, 255, 255, 1],
		pickerBg: theme.pickerBase,
		pickerToolBarBg: theme.pickerBg,
		pickerFontColor: theme.pickerBg,
		pickerFontFamily: 'Comfortaa',
		pickerFontSize: 20,
		pickerRowHeight: 30,
		pickerData: createData(items),
		selectedValue: formatSelected(items, selectedValue),
		onPickerConfirm: (data) => {
			onConfirm(saveSelected(items, data[0]));
		}
	});
	Picker.show();
};

const showIOSExecerisePicker = (
	items: Array<Object>,
	onConfirm: Function,
	selectedValue: string,
	theme
) => {
	Picker.init({
		pickerTitleText: '',
		pickerConfirmBtnText: i18n.t('confirm'),
		pickerCancelBtnText: i18n.t('cancel'),
		pickerTextEllipsisLen: 12,
		pickerConfirmBtnColor: [255, 255, 255, 1],
		pickerCancelBtnColor: [255, 255, 255, 1],
		pickerTitleColor: [255, 255, 255, 1],
		pickerBg: theme.pickerBase,
		pickerToolBarBg: theme.pickerBg,
		pickerFontColor: theme.pickerBg,
		pickerFontFamily: 'Comfortaa',
		pickerFontSize: 20,
		pickerRowHeight: 30,
		pickerData: createData(items),
		selectedValue: formatActivitySelected(items, selectedValue),
		onPickerConfirm: (data) => {
			onConfirm(saveActivitySelected(items, data[0]));
		}
	});
	Picker.show();
};

const createData = (items) => {
	const arr = items.map((item) => {
		return item.label;
	});
	return arr;
};

const formatSelected = (items, value) => {
	const fValue = items.filter((obj) => {
		return obj.value === value;
	});
	return [fValue[0].label];
};

const saveSelected = (items, label) => {
	const fValue = items.filter((obj) => {
		return obj.label === label;
	});
	return fValue[0].value;
};

const IOS_WEIGHTS = [
	{ label: 'lbs', value: 'lbs' },
	{ label: 'kg', value: 'kg' }
];
const IOS_TIMES = [
	{ label: 'mins', value: 'mins' },
	{ label: 'hrs', value: 'hrs' }
];

const formatActivitySelected = (items, objOG) => {
	const fValue = items.filter((obj) => {
		return obj.label.trim() === objOG.trim();
	});
	return [fValue[0].label];
};

const saveActivitySelected = (items, label) => {
	const fValue = items.filter((obj) => {
		return obj.label.trim() === label.trim();
	});
	return fValue[0];
};

export default class CalorieBurnedEstimator extends React.Component<
	Props,
	State
> {
	constructor(props) {
		super(props);
		this.state = {
			activityLabel: {
				value: 2.65,
				label: i18n.t('ex1')
			},
			activityRatio: [
				{
					value: 2.65,
					label: i18n.t('ex1')
				},
				{
					value: 3,
					label: i18n.t('ex2')
				},
				{
					value: 3.71,
					label: i18n.t('ex3')
				},
				{
					value: 2.7,
					label: i18n.t('ex4')
				},
				{
					value: 1.67,
					label: i18n.t('ex5')
				},
				{
					value: 2,
					label: i18n.t('ex6')
				},
				{
					value: 1.6,
					label: i18n.t('ex7')
				},
				{
					value: 3,
					label: i18n.t('ex8')
				},
				{
					value: 4.26,
					label: i18n.t('ex9')
				},
				{
					value: 2.62,
					label: i18n.t('ex10')
				},
				{
					value: 2.84,
					label: i18n.t('ex11')
				},
				{
					value: 3.65,
					label: i18n.t('ex12')
				},
				{
					value: 3.7,
					label: i18n.t('ex13')
				},
				{
					value: 2.75,
					label: i18n.t('ex14')
				},
				{
					value: 2.37,
					label: i18n.t('ex15')
				},
				{
					value: 2,
					label: i18n.t('ex16')
				},
				{
					value: 1.96,
					label: i18n.t('ex17')
				},
				{
					value: 1.7,
					label: i18n.t('ex18')
				},
				{
					value: 4,
					label: i18n.t('ex19')
				},
				{
					value: 5.35,
					label: i18n.t('ex20')
				},
				{
					value: 4.88,
					label: i18n.t('ex21')
				},
				{
					value: 3.6,
					label: i18n.t('ex22')
				},
				{
					value: 4.91,
					label: i18n.t('ex23')
				},
				{
					value: 3.27,
					label: i18n.t('ex24')
				},
				{
					value: 2.92,
					label: i18n.t('ex25')
				},
				{
					value: 3.62,
					label: i18n.t('ex26')
				},
				{
					value: 4.8,
					label: i18n.t('ex27')
				},
				{
					value: 5.4,
					label: i18n.t('ex28')
				},
				{
					value: 6.3,
					label: i18n.t('ex29')
				},
				{
					value: 7.5,
					label: i18n.t('ex30')
				},
				{
					value: 7.9,
					label: i18n.t('ex31')
				},
				{
					value: 1.91,
					label: i18n.t('ex32')
				},
				{
					value: 3.71,
					label: i18n.t('ex33')
				},
				{
					value: 4.09,
					label: i18n.t('ex34')
				},
				{
					value: 4.42,
					label: i18n.t('ex35')
				},
				{
					value: 4.91,
					label: i18n.t('ex36')
				},
				{
					value: 3,
					label: i18n.t('ex37')
				},
				{
					value: 10,
					label: i18n.t('ex38')
				},
				{
					value: 2.75,
					label: i18n.t('ex39')
				},
				{
					value: 2.75,
					label: i18n.t('ex40')
				},
				{
					value: 1.4,
					label: i18n.t('ex41')
				},
				{
					value: 2,
					label: i18n.t('ex42')
				},
				{
					value: 2.67,
					label: i18n.t('ex43')
				},
				{
					value: 3.2,
					label: i18n.t('ex44')
				},
				{
					value: 2.35,
					label: i18n.t('ex45')
				},
				{
					value: 2.8,
					label: i18n.t('ex46')
				},
				{
					value: 1.3,
					label: i18n.t('ex47')
				},
				{
					value: 2.1,
					label: i18n.t('ex48')
				},
				{
					value: 3.1,
					label: i18n.t('ex49')
				},
				{
					value: 4.0,
					label: i18n.t('ex50')
				}
			]
		};
	}

	render() {
		const {
			exerciseEntry: { weight, weightUnit, time, timeUnit, activityLabel },
			onChange,
			theme
		} = this.props;

		return (
			<View
				style={{
					paddingHorizontal: 30,
					paddingBottom: 20,
					justifyContent: 'center'
				}}>
				<View
					style={[styles.modalInputHolder, { backgroundColor: theme.baseBG }]}>
					<Text style={[styles.title, { color: theme.darkFont }]}>
						{i18n.t('exerciseCalculatorDescrition')}
					</Text>
					<View style={[styles.inputCtn, { borderColor: theme.border }]}>
						<TextInput
							style={[
								styles.listInput,
								{ color: theme.themeAccent, borderRightColor: theme.border }
							]}
							onChangeText={(value) => onChange('weight', value)}
							keyboardType={Platform.OS === 'ios' ? 'decimal-pad' : 'numeric'}
							placeholder={'0'}
							placeholderTextColor={theme.themeAccent}
							value={weight}
							returnKeyType={'done'}
							underlineColorAndroid="rgba(0,0,0,0)"
							autoCapitalize="words"
						/>
						{Platform.OS !== 'ios' ? (
							<PickerA
								mode="dropdown"
								style={[
									styles.unitPicker,
									{ backgroundColor: theme.baseBG, color: theme.themeAccent }
								]}
								selectedValue={weightUnit}
								onValueChange={(itemValue) =>
									onChange('weightUnit', itemValue)
								}>
								<PickerA.Item label={'lbs'} value={'lbs'} />
								<PickerA.Item label={'kg'} value={'kg'} />
							</PickerA>
						) : (
							<TouchableOpacity
								style={[
									styles.unitPicker,
									{ backgroundColor: theme.baseBG, color: theme.themeAccent }
								]}
								onPress={() =>
									showIOSPicker(
										IOS_WEIGHTS,
										(itemValue) => onChange('weightUnit', itemValue),
										weightUnit,
										theme
									)
								}>
								<Text style={{ color: theme.themeAccent }}>
									{formatSelected(IOS_WEIGHTS, weightUnit)}
								</Text>
							</TouchableOpacity>
						)}
					</View>
					<View style={[styles.inputCtn, { borderColor: theme.border }]}>
						<TextInput
							style={[styles.listInput, { color: theme.themeAccent, borderRightColor: theme.border }]}
							onChangeText={(value) => onChange('time', value)}
							keyboardType={Platform.OS === 'ios' ? 'decimal-pad' : 'numeric'}
							placeholder={'0'}
							placeholderTextColor={theme.placeholderGray}
							value={time}
							returnKeyType={'done'}
							underlineColorAndroid="rgba(0,0,0,0)"
							autoCapitalize="words"
						/>
						{Platform.OS !== 'ios' ? (
							<PickerA
								mode="dropdown"
								style={[
									styles.unitPicker,
									{ backgroundColor: theme.baseBG, color: theme.themeAccent }
								]}
								selectedValue={timeUnit}
								onValueChange={(itemValue) => onChange('timeUnit', itemValue)}>
								<PickerA.Item label={'mins'} value={'mins'} />
								<PickerA.Item label={'hrs'} value={'hrs'} />
							</PickerA>
						) : (
							<TouchableOpacity
								style={styles.unitPicker}
								onPress={() =>
									showIOSPicker(
										IOS_TIMES,
										(itemValue) => onChange('timeUnit', itemValue),
										timeUnit,
										theme
									)
								}>
								<Text
									style={[styles.iosPickerText, { color: theme.themeAccent }]}>
									{formatSelected(IOS_TIMES, timeUnit)}
								</Text>
							</TouchableOpacity>
						)}
					</View>
					<View style={[styles.inputCtn, { borderColor: theme.border }]}>
						{Platform.OS !== 'ios' ? (
							<PickerA
								style={[
									styles.exercisePicker,
									{ backgroundColor: theme.baseBG, color: theme.themeAccent }
								]}
								selectedValue={activityLabel.label}
								onValueChange={(itemValue, itemIndex) =>
									onChange('activityLabel', this.state.activityRatio[itemIndex])
								}>
								{this.state.activityRatio.map((item, index) => (
									<PickerA.Item
										key={index}
										label={item.label}
										value={item.label}
									/>
								))}
							</PickerA>
						) : (
							<TouchableOpacity
								style={styles.unitPicker}
								onPress={() =>
									showIOSExecerisePicker(
										this.state.activityRatio,
										(itemValue) => onChange('activityLabel', itemValue),
										activityLabel.label,
										theme
									)
								}>
								<Text
									style={[styles.iosPickerText, { color: theme.themeAccent }]}>
									{formatActivitySelected(
										this.state.activityRatio,
										activityLabel.label
									)}
								</Text>
							</TouchableOpacity>
						)}
					</View>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	modalInputHolder: {
		paddingBottom: 20,
		justifyContent: 'flex-start'
	},
	title: {
		paddingLeft: 0,
		paddingBottom: 10,
		color: '#333'
	},
	inputCtn: {
		flexDirection: 'row',
		alignItems: 'center',
		borderWidth: 1,
		borderRadius: 3
	},
	listInput: {
		flex: 1,
		fontSize: moderateScale(20),
		fontFamily: 'Comfortaa',
		justifyContent: 'flex-end',
		alignItems: 'center',
		textAlign: 'center',
		paddingRight: 5,
		borderRightWidth: 1
	},
	exercisePicker: {
		flex: 1,
		height: 50,
		alignItems: 'center',
		paddingLeft: 10,
		marginLeft: 10,
		borderWidth: 0
	},
	unitPicker: {
		flex: 0.85,
		height: 50,
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: 10,
		borderWidth: 0
	}
});
